import { Icon } from '@components';
import { ColorPicker } from '@components/Wysiwyg/ColorPicker';
import { Box, Button } from '@material-ui/core';
import { SignalsSearchItem } from '@modules';
import { useEffect, useState } from 'react';
import { DVAttribute } from '../DVAttribute';
import { DVItemProps } from './DVItem.props';
import { useStyles } from './DVItem.styles';

export const DVItem: React.FC<DVItemProps> = ({
  index,
  color,
  label,
  attribute,
  onColorChange,
  onLabelChange,
  onAdd,
  onRemove,
}) => {
  const styles = useStyles();
  const [curColor, setCurColor] = useState<string>(color);
  const [curAttribute, setCurAttribute] = useState<string>(attribute);
  const [curLabel, setCurLabel] = useState<string>(label);

  const handleColorChange = (value: string) => {
    const curValue = value === '#NaNNaNNaN' ? '#ffffff' : value;

    onColorChange(curValue);
    setCurColor(curValue);
  };

  const handleLabelChange = (e: any) => {
    setCurLabel(e.target.value);
    onLabelChange(e.target.value);
  };

  const handleAttributeChange = (value: SignalsSearchItem) => {
    setCurAttribute(value.name);
  };

  const resetValues = () => {
    setCurColor('');
    setCurAttribute('');
    setCurLabel('');
  };

  const handleInputKeyPress = (e: any) => {
    if (e.code === 'Enter' && onAdd) {
      onAdd({ color: curColor, attribute: curAttribute, label: curLabel });
      resetValues();
    }
  };

  useEffect(() => {
    if (attribute !== curAttribute) {
      setCurAttribute(attribute);
    }
  }, [attribute, curAttribute]);

  useEffect(() => {
    if (color !== curColor) {
      setCurColor(color);
    }
  }, [color, curColor]);

  useEffect(() => {
    if (label !== curLabel) {
      setCurLabel(label);
    }
  }, [curLabel, label]);

  return (
    <Box className={styles.DVItem}>
      <Box className={styles.index}>{(index ?? 0) + 1}</Box>
      <ColorPicker width={'112px'} placeholder={'Select'} color={curColor} onColorChange={handleColorChange} />
      {/* <DVAttribute value={curAttribute} onChange={handleAttributeChange} /> */}
      <Box className={styles.label}>{curLabel}</Box>
      {/* <input
        className={styles.label}
        value={curLabel}
        placeholder="Enter a label"
        onChange={handleLabelChange}
        onKeyPress={handleInputKeyPress}
      /> */}
      {onRemove ? (
        <Button className={styles.removeBtn} onClick={onRemove(index)}>
          <Icon.TrashOutline width={'16px'} height={'16px'} />
        </Button>
      ) : (
        <Box className={styles.emptyBox}></Box>
      )}
    </Box>
  );
};
