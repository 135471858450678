import { useCallback, useContext, useEffect, useState } from 'react';
import { Box } from '@material-ui/core';

import { EmptyPlaceholder, Icon } from '@components';
import { content } from '@content';
import { experience } from '@modules';
import { LibraryNavigation, LibraryNavigationCallbacks } from '@views';
import { useAppDispatch } from '@store';
import { useHistory } from '@utils';
import { TopBarContext, TOP_BAR_CTX_VALUE } from '@layouts';

import { useStyles } from './Experiences.styles';
import { ExperienceNameModal, ExperienceNameModalProps } from './ExperienceNameModal';
import { variables } from '@styles';

/**
 Experiences route component.
 @returns {JSX.Element}
 */

export const Experiences = (): JSX.Element => {
  const [, setTopBarCtx] = useContext(TopBarContext);

  const styles = useStyles();

  const [open, setOpen] = useState(false);

  const history = useHistory();

  const { businessUnit: businessUnitId } = history.query;

  const dispatch = useAppDispatch();

  const handleModal = useCallback(() => {
    setOpen(!open);
  }, [open]);

  const handleLibNavExperienceSelect = useCallback<LibraryNavigationCallbacks['onExperienceSelect']>(
    (event) => {
      const id = event?.currentTarget.getAttribute('data-id');

      if (id) {
        history.push('', { id });
      }
    },
    [history],
  );

  const createExperience = useCallback<ExperienceNameModalProps['onSubmit']>(
    async (values) => {
      if (businessUnitId) {
        const payload = {
          mindsets: [],
          businessUnit: { id: businessUnitId },
          ...values,
        };
        const result = await dispatch(experience.thunk.create(payload));

        if (experience.thunk.create.fulfilled.match(result) && result.payload) {
          setOpen(false);

          history.push('', { id: result.payload.id });
        }
      }
    },
    [dispatch, businessUnitId, history],
  );

  useEffect(() => {
    setTopBarCtx((prev) => ({
      ...prev,
      sectionName: content.moduleGroups,
      variant: 'picker',
    }));

    return () => {
      setTopBarCtx(() => TOP_BAR_CTX_VALUE);
    };
  }, [setTopBarCtx]);

  return (
    <Box className={`Experiences ${styles.library}`}>
      <LibraryNavigation createExperience={handleModal} onExperienceSelect={handleLibNavExperienceSelect} />
      <Box className={styles.experiencesRight}>
        <EmptyPlaceholder
          title={content.nothingSelected}
          description={content.selectAnItemOnTheLibraryToGetStarted}
          icon={<Icon.Add fill={variables.color.primary.darkestGray} />}
        />
      </Box>
      <ExperienceNameModal open={open} onClose={handleModal} onSubmit={createExperience} />
    </Box>
  );
};
