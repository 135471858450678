import { useCallback, useContext, useEffect, useRef, useState, useMemo } from 'react';
import { DragDropContext, DragDropContextProps, Draggable, Droppable } from 'react-beautiful-dnd';
import { Box, Button, Checkbox, FormControlLabel, Input, InputBase, Radio, RadioGroup } from '@material-ui/core';

import { Icon, Menu, OptionItem, Typography } from '@components';
import { content, page } from '@content';
import { experience, ExperienceItemMindset, modules } from '@modules';
import {
  DEPLOYMENTS_MINDSET_DND_ID,
  RenameModuleModal,
  RightPanelElems,
  TreatmentBuilderContext,
  EMPTY_MODULE_NOTE,
  TreatmentPanelType,
  ModuleTemplateType,
} from '@routes';
import { useAppDispatch } from '@store';
import { textTemplate, useHistory } from '@utils';
import { broadcaster } from '@utils/network/broadcast';

import { getUniqueStyles, getTemplateDoc, removePrevElemInfo } from '../../SampleTemplate.utils';
import { ElemAttributes } from '../../SampleTemplate.types';

import { TreatmentCard, TreatmentCardProps } from './TreatmentCard';
import { RenameModuleModalFormValues } from './RenameModuleModal/RenameModuleModal.types';

import { TreatmentsProps } from './Treatments.props';
import { useStyles } from './Treatments.styles';
import { MenuKeys } from './Treatments.const';
import { preparePayload, prepareRenamePayload, getExperienceElement } from './Treatments.utils';
import { useOnClickOutside } from './Treatments.hooks';
import { TopBarContext } from '@layouts';
import { variables } from '@styles';

/**
 Treatments component
 @returns {JSX.Element}
 */

export const Treatments = ({
  setDeletedExperience,
  setRightPanelElem,
  setTreatmentPanelType,
}: TreatmentsProps): JSX.Element => {
  const styles = useStyles();
  const dispatch = useAppDispatch();
  const contentRef = useRef<HTMLDivElement | null>(null);
  const history = useHistory();

  const [panelTitle, setPanelTitle] = useState({
    title: '',
    tag: '',
  });

  const [showRenameModuleModal, setShowRenameModuleModal] = useState(false);
  const [showRemoveWithRevisions, setShowRemoveWithRevisions] = useState(false);
  const [targetContent, setTargetContent] = useState<boolean | null>(null);
  const [isDoTargetingShown, setIsDoTargetingShown] = useState(false);
  const [newTreatmentName, setNewTreatmentName] = useState<string>();
  const [isEditTitleModeOn, setIsEditTitleModeOn] = useState(false);
  const [panelTitleValue, setPanelTitleValue] = useState(panelTitle.title);

  const {
    chosenElemId,
    chosenExperienceId,
    defaultChosenTreats,
    showAddTreatmentPanel,
    isTargetContentShown,
    chosenModuleId: chosenTreatmentId,
    setChosenModuleId: setChosenTreatmentId,
    setIsTargetContentShown,
    setChosenModuleInnerHtml,
    setDefaultChosenTreats,
    setChosenElemId,
    setShowAddTreatmentPanel,
    setEditableTreatmentId,
    singleModulesWrapper,
    isTreatmentTouched,
    setIsTreatmentTouched,
    setNewExperienceCloned,
    setDeploymentsInfo,
    setChosenExperienceId,
  } = useContext(TreatmentBuilderContext);

  const { businessUnit: businessUnitId, deployment: deploymentId } = useHistory().query;

  const experienceMindsets = experience.useMindsetsData();

  const mindsetsModule = experienceMindsets.find((item) => {
    if (chosenExperienceId) {
      return item.experienceId === +chosenExperienceId;
    }
  });

  const mindsets = useMemo<ExperienceItemMindset[]>(() => mindsetsModule?.modules || [], [mindsetsModule?.modules]);

  const experienceId = mindsetsModule?.experienceId || 0;

  const experienceData = experience.useItemData();
  const deployments = useMemo(() => experienceData?.deployments?.filter((elem) => elem), [experienceData?.deployments]);

  const universal = mindsets.find((item) => item.isNullSubscriber);
  const groupedTreatments = mindsets.filter((item) => !item.isNullSubscriber);
  const [, setTopBarCtx] = useContext(TopBarContext);

  const allTreats = [universal, ...groupedTreatments];
  const chosenTreatment = allTreats.find((treat) => treat?.id === chosenTreatmentId);
  const [moduleType, setModuleType] = useState<string>();

  const handleTreatmentChange = useCallback<TreatmentCardProps['setChosenTreatmentId']>(
    (nextTreatmentId) => {
      if (nextTreatmentId !== chosenTreatmentId) {
        setChosenTreatmentId(nextTreatmentId);
      }
    },
    [setChosenTreatmentId, chosenTreatmentId],
  );

  const handleShowAddTreatmentPanel = useCallback(
    (type: TreatmentPanelType) => () => {
      setEditableTreatmentId(null);
      setShowAddTreatmentPanel(true);
      setTreatmentPanelType(type);
    },
    [setShowAddTreatmentPanel, setEditableTreatmentId, setTreatmentPanelType],
  );

  const handleRenameModuleModalOpen = useCallback(
    (treatmentName?: string) => {
      setShowRenameModuleModal(true);

      if (treatmentName) {
        setNewTreatmentName(treatmentName);
      }
    },
    [setNewTreatmentName, setShowRenameModuleModal],
  );

  const handleRenameModuleModalClose = useCallback(() => {
    setShowRenameModuleModal(false);
  }, [setShowRenameModuleModal]);

  const handleRemoveTreatment = useCallback(
    async (treatmentId: number) => {
      if (typeof businessUnitId === 'number') {
        const filteredTreatments = groupedTreatments.filter((item) => item.id !== treatmentId);

        const result = await dispatch(
          experience.thunk.update([preparePayload(businessUnitId, experienceData, filteredTreatments), experienceId]),
        );

        if (experience.thunk.update.fulfilled.match(result)) {
          setDefaultChosenTreats((prev) => prev.filter((item) => item.id !== treatmentId));

          handleTreatmentChange(universal?.id || null);

          setChosenModuleInnerHtml(universal?.moduleHtml || EMPTY_MODULE_NOTE);

          const modulesCount = filteredTreatments.length + 1;

          if (modulesCount) {
            const type: ModuleTemplateType = modulesCount === 1 ? 'single' : 'group';
            const elemIdParts = chosenElemId.split('-');
            const index = elemIdParts[elemIdParts.length - 1];
            const divId = `journey-${type}-div-${index}`;

            setChosenElemId(divId);
          }

          setTimeout(() => {
            broadcaster.postMessage('refresh');
          }, 500);
        }
      }
    },
    [
      businessUnitId,
      groupedTreatments,
      dispatch,
      experienceData,
      experienceId,
      setDefaultChosenTreats,
      handleTreatmentChange,
      universal?.id,
      universal?.moduleHtml,
      setChosenModuleInnerHtml,
      chosenElemId,
      setChosenElemId,
    ],
  );

  const handleConfirmRenameModule = useCallback(
    async (data: RenameModuleModalFormValues, isDeleteTreatment) => {
      const treatments: ExperienceItemMindset[] = JSON.parse(JSON.stringify(groupedTreatments));
      const renamedTreatment = treatments.find((treatment) => treatment.id === chosenTreatmentId);

      if (renamedTreatment && newTreatmentName) {
        renamedTreatment.name = newTreatmentName ?? '';
      }

      handleRenameModuleModalClose();

      if (chosenTreatmentId && deploymentId && experienceData && typeof businessUnitId === 'number') {
        if (
          experienceData?.deployments?.length > 1 ||
          experienceData?.type === 'revision' ||
          experienceData?.revisions.length
        ) {
          let newGroupedTreatments = treatments;
          if (isDeleteTreatment) {
            newGroupedTreatments = treatments.filter((treatment) => treatment.id !== chosenTreatmentId);
          }

          const createExperienceResult = await dispatch(
            experience.thunk.create(
              prepareRenamePayload(
                businessUnitId,
                experienceData,
                newGroupedTreatments,
                {
                  id: deploymentId,
                },
                { id: experienceData.id },
                data.name,
              ),
            ),
          );

          if (experience.thunk.create.fulfilled.match(createExperienceResult)) {
            const payload = createExperienceResult.payload;
            const newExperienceId = payload?.id;

            if (newExperienceId) {
              const newMindsets = await dispatch(experience.thunk.getItemMindsets(newExperienceId));

              const splitterId = getExperienceElement(payload, String(chosenExperienceId));

              setNewExperienceCloned({
                cloned: true,
                splitterId: splitterId ? splitterId : null,
                experienceId: newExperienceId,
              });

              setChosenExperienceId(newExperienceId);

              if (experience.thunk.getItemMindsets.fulfilled.match(newMindsets) && newMindsets.payload) {
                const newModuleId = newMindsets.payload[0]?.id;

                if (newModuleId) {
                  setChosenTreatmentId(newModuleId);
                }
              }
            }
          }

          setShowRemoveWithRevisions(false);
        } else {
          await dispatch(
            experience.thunk.update([
              prepareRenamePayload(
                businessUnitId,
                experienceData,
                treatments,
                {
                  id: deploymentId,
                },
                undefined,
                data.name,
              ),
              experienceId,
            ]),
          );
        }
      }
    },
    [
      chosenTreatmentId,
      businessUnitId,
      deploymentId,
      dispatch,
      experienceData,
      newTreatmentName,
      experienceId,
      groupedTreatments,
      handleRenameModuleModalClose,
      chosenExperienceId,
      setNewExperienceCloned,
      setChosenExperienceId,
      setChosenTreatmentId,
    ],
  );

  const handleFinishMailFileUploading = () => {
    dispatch(experience.thunk.getItemMindsets(chosenExperienceId!));
  };

  const saveEditTreatmentName = useCallback(
    async (e) => {
      const payload = preparePayload(businessUnitId!, experienceData, groupedTreatments);
      const curTreatment = payload.mindsets.find((treatment) => treatment.id === chosenTreatmentId);

      if (curTreatment) {
        curTreatment.name = e;
      }

      dispatch(experience.thunk.update([payload, experienceId]));
    },
    [businessUnitId, experienceData, groupedTreatments, experienceId, chosenTreatmentId, dispatch, preparePayload],
  );

  const onMenuClick = useCallback(
    (option: OptionItem) => {
      switch (option.id) {
        case MenuKeys.RENAME:
          handleRenameModuleModalOpen();
          break;
      }
    },
    [handleRenameModuleModalOpen],
  );

  const treatmentMoved = useRef(false);

  const handleDragEnd = useCallback<DragDropContextProps['onDragEnd']>(
    ({ source: src, destination: dst }) => {
      if (dst && src.index !== dst.index) {
        treatmentMoved.current = true;

        dispatch(experience.actions.moveTreatment({ experienceId, from: src.index, to: dst.index }));
      }
    },
    [dispatch, experienceId],
  );

  useEffect(() => {
    (async () => {
      if (treatmentMoved.current && typeof businessUnitId === 'number') {
        treatmentMoved.current = false;

        dispatch(
          experience.thunk.update([preparePayload(businessUnitId, experienceData, groupedTreatments), experienceId]),
        );
      }
    })();
  }, [dispatch, experienceId, groupedTreatments, experienceData, businessUnitId]);

  useEffect(() => {
    if (universal) {
      setPanelTitle({
        title: experienceData?.name || '',
        tag: universal.tag.index,
      });
      setPanelTitleValue(experienceData?.name || '');
    }
  }, [deployments, experienceData?.name, mindsets, universal]);

  useEffect(() => {
    if (isTreatmentTouched && typeof businessUnitId === 'number') {
      dispatch(
        experience.thunk.update([preparePayload(businessUnitId, experienceData, groupedTreatments), experienceId]),
      );

      setIsTreatmentTouched(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTreatmentTouched, mindsets]);

  const handleChangeRightPanelElem = useCallback(() => {
    const templateDoc = getTemplateDoc();
    const chosenElem = templateDoc?.getElementById(chosenElemId);

    const elemAttributes = chosenElem?.attributes;
    const textContent = (elemAttributes as ElemAttributes)?.style.textContent;
    const styleItems = getUniqueStyles(textContent);

    if (templateDoc) {
      removePrevElemInfo(chosenElemId, templateDoc);
      setChosenElemId('');

      chosenElem?.setAttribute('style', `${styleItems} border-color: transparent;`);
    }

    setRightPanelElem(RightPanelElems.DEPLOYMENT_SETTINGS);
  }, [chosenElemId, setChosenElemId, setRightPanelElem]);

  useOnClickOutside(contentRef, singleModulesWrapper, handleChangeRightPanelElem);

  useEffect(() => {
    if (defaultChosenTreats) {
      const currentTreatment = defaultChosenTreats.find((item) => item.experienceId === experienceId);

      if (currentTreatment) {
        handleTreatmentChange(currentTreatment.id);
      }
    }
  }, [handleTreatmentChange, defaultChosenTreats, experienceId]);

  useEffect(() => {
    if (deployments && deployments.length > 0) {
      setDeploymentsInfo(deployments);
    }
  }, [deployments, setDeploymentsInfo]);

  useEffect(() => {
    let curModuleType = '';

    if (chosenElemId.includes('journey-single-div')) {
      curModuleType = content.singleModule;
      setModuleType(content.singleModule);
    }

    if (chosenElemId.includes('journey-group-div')) {
      curModuleType = content.moduleGroup;
      setModuleType(content.moduleGroup);
    }

    const payload = {
      moduleType: curModuleType,
      moduleName: panelTitle.title,
      chosenTreatment: chosenTreatment,
    };

    setTopBarCtx((prev) => ({
      ...prev,
      ...payload,
    }));
  }, [chosenElemId, content, panelTitle, chosenTreatment, setTopBarCtx]);

  useEffect(() => {
    const value = moduleType === content.singleModule && !groupedTreatments.length && !showAddTreatmentPanel;
    setIsTargetContentShown(value);
    setIsDoTargetingShown(false);
    setTargetContent(null);
  }, [
    moduleType,
    groupedTreatments.length,
    chosenElemId,
    // content,
    experienceMindsets,
    showAddTreatmentPanel,
    setIsTargetContentShown,
    setIsDoTargetingShown,
    setTargetContent,
  ]);

  //
  const handleAddUserStory = () => {
    setIsDoTargetingShown(true);
    setIsTargetContentShown(false);
  };

  const handleUseExternalScoring = () => {
    setIsTargetContentShown(false);
    handleShowAddTreatmentPanel('externalScoring')();
    setIsDoTargetingShown(false);
    setTargetContent(null);
  };

  const handleRecommendTreats = () => {
    history.push('dataCenter');
    setIsDoTargetingShown(false);
    setTargetContent(null);
    setIsTargetContentShown(false);
  };

  const handleLogicBuilderClick = () => {
    handleShowAddTreatmentPanel('logicBuilder')();
    setIsDoTargetingShown(false);
    setTargetContent(null);
    setIsTargetContentShown(false);
  };

  const handleTriggerPanelTitle = () => {
    setIsEditTitleModeOn((prev) => !prev);
  };

  const handlePanelTitleValueChange = (e: any) => {
    setPanelTitleValue(e.target.value);
  };

  const handleModuleRename = async () => {
    if (chosenTreatmentId && deploymentId && experienceData && typeof businessUnitId === 'number') {
      if (
        experienceData?.deployments?.length > 1 ||
        experienceData?.type === 'revision' ||
        experienceData?.revisions.length
      ) {
        setShowRenameModuleModal(true);
      } else {
        await dispatch(
          experience.thunk.update([
            prepareRenamePayload(
              businessUnitId,
              experienceData,
              groupedTreatments,
              {
                id: deploymentId,
              },
              undefined,
              panelTitleValue,
            ),
            experienceId,
          ]),
        );
      }

      setPanelTitle({
        ...panelTitle,
        title: panelTitleValue,
      });
      handleTriggerPanelTitle();
    }
  };

  return (
    <Box {...{ ref: contentRef }} className={styles.treatments}>
      <Box className={styles.header}>
        {isEditTitleModeOn ? (
          <>
            <Typography.SuperSmallCaption>{content.userStory}</Typography.SuperSmallCaption>
            <Box className={styles.renameBar}>
              <InputBase
                className={styles.renameField}
                onDoubleClick={handleTriggerPanelTitle}
                value={panelTitleValue}
                onChange={handlePanelTitleValueChange}
              />
              <Box className={styles.renameBarButtons}>
                <Button
                  className={styles.addTreatment}
                  variant="outlined"
                  color="primary"
                  onClick={handleTriggerPanelTitle}
                >
                  {content.cancel}
                </Button>
                <Button
                  className={styles.addTreatment}
                  variant="contained"
                  color="primary"
                  onClick={handleModuleRename}
                >
                  {content.save}
                </Button>
              </Box>
            </Box>
          </>
        ) : (
          <Box onDoubleClick={handleTriggerPanelTitle}>
            <Typography.SuperSmallCaption className={styles.panelTitleHeading}>
              {content.userStory}
            </Typography.SuperSmallCaption>
            <Typography.Title className={styles.panelTitle}>{panelTitle.title}</Typography.Title>
          </Box>
        )}
        <Box>
          {/* <Menu buttonClassName={styles.menu} onClick={onMenuClick} options={MENU_OPTIONS} /> */}
          {showRenameModuleModal && (
            <RenameModuleModal
              title={
                mindsets.length === 1
                  ? textTemplate(content.renameValue, { value: content.contentModule })
                  : textTemplate(content.renameValue, { value: content.moduleGroup })
              }
              alertMessage={
                mindsets.length === 1
                  ? content.itsNotPossibleRenameContentModule
                  : content.itsNotPossibleRenameModuleGroup
              }
              moduleData={{
                moduleTemplateName: panelTitle.title,
                deployments: deployments || [],
                isRenameRequired: experienceData?.type === 'revision' || !!experienceData?.revisions.length,
              }}
              onClose={handleRenameModuleModalClose}
              onSubmit={handleConfirmRenameModule}
            />
          )}
        </Box>
      </Box>
      <Box className={styles.content}>
        {universal && (
          <TreatmentCard
            cardId="universal"
            experienceId={experienceId}
            treatments={mindsets}
            treatment={universal}
            chosenTreatmentId={chosenTreatmentId}
            setChosenTreatmentId={handleTreatmentChange}
            onModuleRename={handleConfirmRenameModule}
            onTreatmentRename={saveEditTreatmentName}
            setTreatmentPanelType={setTreatmentPanelType}
            handleRenameModuleModalOpen={handleRenameModuleModalOpen}
            onFinishMailFileUploading={handleFinishMailFileUploading}
          />
        )}
      </Box>
      {groupedTreatments && !!groupedTreatments.length && (
        <Box className={styles.treatmentCards}>
          <Box>
            <DragDropContext onDragEnd={handleDragEnd}>
              <Droppable
                type={DEPLOYMENTS_MINDSET_DND_ID}
                droppableId={DEPLOYMENTS_MINDSET_DND_ID}
                direction="vertical"
              >
                {(listProvided) => (
                  <Box
                    {...listProvided.droppableProps}
                    {...{ ref: listProvided.innerRef }}
                    className={styles.groupedTreatments}
                  >
                    {groupedTreatments.map((item, index) => (
                      <Draggable
                        // isDragDisabled TODO: Disable drag & drop
                        draggableId={`${DEPLOYMENTS_MINDSET_DND_ID}.${item.name}`}
                        index={index}
                        key={item.name}
                      >
                        {(itemProvided) => (
                          <Box
                            className={styles.treatmentWrapper}
                            {...itemProvided.draggableProps}
                            {...itemProvided.dragHandleProps}
                            {...{ ref: itemProvided.innerRef }}
                          >
                            <TreatmentCard
                              key={item.id}
                              cardId={index}
                              experienceId={experienceId}
                              treatmentId={item.id}
                              treatments={mindsets}
                              treatment={item}
                              type={experienceData?.type}
                              createdWith={experienceData?.createdWith}
                              groupedTreatment
                              chosenTreatmentId={chosenTreatmentId}
                              onRemove={handleRemoveTreatment}
                              setChosenTreatmentId={handleTreatmentChange}
                              setRightPanelElem={setRightPanelElem}
                              onModuleRename={handleConfirmRenameModule}
                              onTreatmentRename={saveEditTreatmentName}
                              showRemoveWithRevisions={showRemoveWithRevisions}
                              setShowRemoveWithRevisions={setShowRemoveWithRevisions}
                              setTreatmentPanelType={setTreatmentPanelType}
                              handleRenameModuleModalOpen={handleRenameModuleModalOpen}
                            />
                          </Box>
                        )}
                      </Draggable>
                    ))}
                    {listProvided.placeholder}
                  </Box>
                )}
              </Droppable>
            </DragDropContext>
          </Box>
        </Box>
      )}
      {isTargetContentShown && !targetContent && !groupedTreatments.length && (
        <Box className={styles.targetTreatmentsOptions}>
          <Button
            className={`${styles.addEditTreatmentButton} ${showAddTreatmentPanel && 'hovered'}`}
            variant="outlined"
            color="primary"
            onClick={handleUseExternalScoring}
            // onClick={handleAddUserStory}
          >
            {/* {content.addUserStory} */}
            <Icon.Add fill={variables.color.primary.darkestGray} height="20" width="20" />
          </Button>
        </Box>
      )}
      {experienceData?.createdWith === 'not-set' && isDoTargetingShown && (
        <Box className={styles.targetTreatments}>
          <Typography.LargeTitle> {content.howWouldYouLikeTargeting}</Typography.LargeTitle>
          <Box className={styles.buttonsWrapper}>
            <Button className={styles.targetingBtn} color="primary" onClick={handleLogicBuilderClick}>
              {content.buildLogic}
            </Button>
            <Button className={styles.targetingBtn} color="primary" onClick={handleUseExternalScoring}>
              {content.useExternalScoring}
            </Button>
            <Button className={styles.targetingBtn} color="primary" onClick={handleRecommendTreats}>
              {content.recommendSegments}
            </Button>
          </Box>
        </Box>
      )}
      {!isTargetContentShown && experienceData?.createdWith !== 'not-set' && (
        <Box className={styles.targetTreatmentsOptions}>
          {experienceData?.createdWith === 'logic-builder' && (
            <Button
              className={`${styles.addEditTreatmentButton} ${showAddTreatmentPanel && 'hovered'}`}
              variant="outlined"
              color="primary"
              onClick={handleShowAddTreatmentPanel('logicBuilder')}
            >
              {/* {content.modifyUserStory} */}
              <Icon.Edit2 fill={variables.color.primary.darkestGray} height="20" width="20" />
            </Button>
          )}
          {experienceData?.createdWith === 'external-score' && (
            <Button
              className={`${styles.addEditTreatmentButton} ${showAddTreatmentPanel && 'hovered'}`}
              variant="outlined"
              color="primary"
              onClick={handleShowAddTreatmentPanel('externalScoring')}
            >
              {/* {content.modifyUserStory} */}
              <Icon.Edit2 fill={variables.color.primary.darkestGray} />
            </Button>
          )}
        </Box>
      )}
    </Box>
  );
};
