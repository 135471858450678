import { SVGProps } from 'react';

export const EditTemplate = ({
  width = 24,
  height = 24,
  fill = '#ffffff',
  className,
}: SVGProps<SVGElement>): JSX.Element => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 1200 1200"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="m1169.9 567v412.97c0 104.91-85.078 189.98-189.98 189.98h-759.94c-104.91 0-189.98-85.078-189.98-189.98v-759.94c0-104.91 85.078-189.98 189.98-189.98h412.97c26.25 0 47.484 21.281 47.484 47.484 0 26.25-21.281 47.484-47.484 47.484h-412.97c-52.453 0-95.016 42.516-95.016 95.016v759.94c0 52.453 42.516 95.016 95.016 95.016h759.94c52.453 0 95.016-42.516 95.016-95.016v-412.97c0-26.25 21.281-47.484 47.484-47.484 26.25 0 47.484 21.234 47.484 47.484zm-142.45-264.32 66.984 66.984 38.484-38.484c49.172-48.75 49.547-128.16 0.79688-177.32l-86.766-86.766c-49.688-47.953-128.44-47.953-178.13 0l-38.484 38.484zm-716.44 404.34 2.8594 132.52c0 26.25 21.281 47.484 47.484 47.484l132.52 2.8594h3.7969c50.438-0.84375 98.438-21.703 133.45-57.938l396.28-394.87-264.56-264.56-394.82 396.32c-36.797 36.516-57.328 86.344-57 138.19z"
      fill={fill}
    />
  </svg>
);
