import { Add } from './Add';
import { AddCircleOutline } from './AddCircleOutline';
import { AddOutline } from './AddOutline';
import { AlertCircle } from './AlertCircle';
import { AlertCircleOutline } from './AlertCircleOutline';
import { AlertCircleOutlineV2 } from './AlertCircleOutlineV2';
import { AlertCircleOutlineV3 } from './AlertCircleOutlineV3';
import { AlignCenter } from './AlignCenter';
import { AlignLeft } from './AlignLeft';
import { AlignRight } from './AlignRight';
import { Apple } from './Apple';
import { Archive } from './Archive';
import { ArrowDown } from './ArrowDown';
import { ArrowLeftOutline } from './ArrowLeftOutline';
import { ArrowNext } from './ArrowNext';
import { ArrowPrev } from './ArrowPrev';
import { ArrowRight } from './ArrowRight';
import { ArrowRightOutline } from './ArrowRightOutline';
import { ArrowUp } from './ArrowUp';
import { BarChartDown } from './BarChartDown';
import { BarChartUp } from './BarChartUp';
import { Bell } from './Bell';
import { Bin } from './Bin';
import { Bold } from './Bold';
import { BoldV2 } from './BoldV2';
import { BusinessOutline } from './BusinessOutline';
import { BusinessOutlineV2 } from './BusinessOutlineV2';
import { Calendar } from './Calendar';
import { Check } from './Check';
import { Checkmark } from './Checkmark';
import { CheckmarkCircleOutline } from './CheckmarkCircleOutline';
import { ChevronDown } from './ChevronDown';
import { ChevronLeft } from './ChevronLeft';
import { ChevronRight } from './ChevronRight';
import { ClipboardOutline } from './ClipboardOutline';
import { Close } from './Close';
import { CloseOutline } from './CloseOutline';
import { CloudDownloadOutline } from './CloudDownloadOutline';
import { CloudOutline } from './CloudOutline';
import { CloudUploadOutline } from './CloudUploadOutline';
import { Cohort } from './Cohort';
import { ContentOutline } from './ContentOutline';
import { Copy } from './Copy';
import { Dart } from './Dart';
import { DataAttributes } from './DataAttributes';
import { DataOutline } from './DataOutline';
import { DataVisualization } from './DataVisualization';
import { DeploymentsOutline } from './DeploymentsOutline';
import { Desktop } from './Desktop';
import { Directory } from './Directory';
import { DocumentOutline } from './DocumentOutline';
import { DocumentsOutline } from './DocumentsOutline';
import { Download } from './Download';
import { Draggable } from './Draggable';
import { DraggableV2 } from './DraggableV2';
import { DraggableVerticalLines } from './DraggableVerticalLines';
import { DraggableVertically } from './DraggableVertically';
import { DuplicateOutline } from './DuplicateOutline';
import { Edit } from './Edit';
import { Edit2 } from './Edit2';
import { EditTemplate } from './EditTemplate';
import { EllipsisHorizontal } from './EllipsisHorizontal';
import { EllipsisVertical } from './EllipsisVertical';
import { ExclamationMark } from './ExclamationMark';
import { ExpandDiagonalOutline } from './ExpandDiagonalOutline';
import { ExpandOutline } from './ExpandOutline';
import { Experience } from './Experience';
import { ExperiencesOutline } from './ExperiencesOutline';
import { Eye } from './Eye';
import { File } from './File';
import { Gear } from './Gear';
import { GlobeOutline } from './GlobeOutline';
import { GolfOutline } from './GolfOutline';
import { GridOutline } from './GridOutline';
import { HomeOutline } from './HomeOutline';
import { ImageOutline } from './ImageOutline';
import { Info } from './Info';
import { InformationCircleOutline } from './InformationCircleOutline';
import { InsightOutline } from './InsightOutline';
import { Italic } from './Italic';
import { ItalicV2 } from './ItalicV2';
import { Link } from './Link';
import { ListOutline } from './ListOutline';
import { LockedOutline } from './LockedOutline';
import { Logout } from './Logout';
import { Magnifier } from './Magnifier';
import { Mindset } from './Mindset';
import { Mobile } from './Mobile';
import { NewWindow } from './NewWindow';
import { NumberOutline } from './NumberOutline';
import { PartlySunny } from './PartlySunny';
import { Pencil } from './Pencil';
import { People } from './People';
import { PeopleCircleOutline } from './PeopleCircleOutline';
import { PeopleOutline } from './PeopleOutline';
import { PersonCircle } from './PersonCircle';
import { PersonCircleOutline } from './PersonCircleOutline';
import { Phone } from './Phone';
import { PieChartOutline } from './PieChartOutline';
import { Preview } from './Preview';
import { Product } from './Product';
import { ProgramsOutline } from './ProgramsOutline';
import { Publish } from './Publish';
import { PublishOutline } from './PublishOutline';
import { RemoveCircleOutline } from './RemoveCircleOutline';
import { Resize } from './Resize';
import { ResultsOutline } from './ResultsOutline';
import { RoundedTick } from './RoundedTick';
import { Saving } from './Saving';
import { SearchOutline } from './SearchOutline';
import { SettingsOutline } from './SettingsOutline';
import { SignalOutline } from './SignalOutline';
import { SixDots } from './SixDots';
import { SmallClose } from './SmallClose';
import { SmallDots } from './SmallDots';
import { SuperScript } from './SuperScript';
import { Tag } from './Tag';
import { ThreeDots } from './ThreeDots';
import { Tick } from './Tick';
import { TrashOutline } from './TrashOutline';
import { TrendDown } from './TrendDown';
import { TrendUp } from './TrendUp';
import { Underline } from './Underline';
import { Upload } from './Upload';

export const Icon = {
  Add,
  AddCircleOutline,
  AddOutline,
  AlertCircle,
  AlertCircleOutline,
  AlertCircleOutlineV2,
  AlertCircleOutlineV3,
  AlignCenter,
  AlignLeft,
  AlignRight,
  Apple,
  Archive,
  ArrowDown,
  ArrowLeftOutline,
  ArrowNext,
  ArrowPrev,
  ArrowRight,
  ArrowRightOutline,
  ArrowUp,
  BarChartDown,
  BarChartUp,
  Bell,
  Bin,
  Bold,
  BoldV2,
  BusinessOutline,
  BusinessOutlineV2,
  Calendar,
  Check,
  Checkmark,
  CheckmarkCircleOutline,
  ChevronDown,
  ChevronLeft,
  ChevronRight,
  ClipboardOutline,
  Close,
  CloseOutline,
  CloudDownloadOutline,
  CloudOutline,
  CloudUploadOutline,
  Cohort,
  ContentOutline,
  Copy,
  Dart,
  DataAttributes,
  DataOutline,
  DataVisualization,
  DeploymentsOutline,
  Desktop,
  Directory,
  DocumentOutline,
  DocumentsOutline,
  Download,
  Draggable,
  DraggableV2,
  DraggableVerticalLines,
  DraggableVertically,
  DuplicateOutline,
  Edit,
  Edit2,
  EditTemplate,
  EllipsisHorizontal,
  EllipsisVertical,
  ExclamationMark,
  ExpandDiagonalOutline,
  ExpandOutline,
  Experience,
  ExperiencesOutline,
  Eye,
  File,
  Gear,
  GlobeOutline,
  GolfOutline,
  GridOutline,
  HomeOutline,
  ImageOutline,
  Info,
  InformationCircleOutline,
  InsightOutline,
  Italic,
  ItalicV2,
  Link,
  ListOutline,
  LockedOutline,
  Logout,
  Magnifier,
  Mindset,
  Mobile,
  NewWindow,
  NumberOutline,
  PartlySunny,
  Pencil,
  People,
  PeopleCircleOutline,
  PeopleOutline,
  PersonCircle,
  PersonCircleOutline,
  Phone,
  PieChartOutline,
  Preview,
  Product,
  ProgramsOutline,
  Publish,
  PublishOutline,
  RemoveCircleOutline,
  Resize,
  ResultsOutline,
  RoundedTick,
  Saving,
  SearchOutline,
  SettingsOutline,
  SignalOutline,
  SixDots,
  SmallClose,
  SmallDots,
  SuperScript,
  Tag,
  ThreeDots,
  Tick,
  TrashOutline,
  TrendDown,
  TrendUp,
  Underline,
  Upload,
};
