import { makeStyles, createStyles } from '@material-ui/core/styles';

import { variables } from '@styles';

/**
 * ModuleSettings component styles
 * @returns {Function}
 */

export const useStyles = makeStyles((theme) =>
  createStyles({
    moduleSettings: {
      height: '100%',
    },

    header: {
      textAlign: 'left',
    },

    titleWrapper: {
      padding: '2rem 2.5rem',
    },

    title: {
      marginBottom: theme.spacing(0.5),
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      fontSize: '16px!important',
      lineHeight: '20px!important',
      color: `${variables.color.primary.darkPurple}!important`,
    },

    content: {
      padding: theme.spacing(2),
      height: '100%',
    },
  }),
);
