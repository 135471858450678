import { useCallback, useState } from 'react';
import { Box, Tab, Tabs } from '@material-ui/core';

import { Icon } from '@components';
import { content } from '@content';

import { Settings } from './Settings';
import { Publish } from './Publish';
import { QATests } from './QATests';

import { useStyles } from './DeploymentSettings.styles';

/**
 DeploymentSettings component
 @returns {JSX.Element}
 */

export const DeploymentSettings = (): JSX.Element => {
  const styles = useStyles();

  const [tab, setTab] = useState(0);

  const handleChangeTab = useCallback((event: React.ChangeEvent<unknown>, value: number) => setTab(value), []);

  return (
    // <>
    //   <Box className={styles.header}>
    //     <Box className={styles.tabsWrapper}>
    //       <Tabs
    //         value={tab}
    //         onChange={handleChangeTab}
    //         classes={{ root: styles.tabs }}
    //         TabIndicatorProps={{
    //           style: {
    //             display: 'none',
    //           },
    //         }}
    //       >
    //         <Tab
    //           icon={<Icon.CloudUploadOutline />}
    //           label={content.publish}
    //           classes={{ root: styles.tab, selected: styles.selectedTab, wrapper: styles.tabWrapper }}
    //         />
    //         <Tab
    //           label={content.qaTests}
    //           classes={{ root: styles.tab, selected: styles.selectedTab, wrapper: styles.tabWrapper }}
    //         />
    //         <Tab
    //           icon={<Icon.SettingsOutline />}
    //           label={content.settings}
    //           classes={{ root: styles.tab, selected: styles.selectedTab, wrapper: styles.tabWrapper }}
    //         />
    //       </Tabs>
    //     </Box>
    //   </Box>
    //   <Box className={styles.content}>
    //     {tab === 0 && <Publish />}
    //     {tab === 1 && <QATests />}
    //     {tab === 2 && <Settings />}
    //   </Box>
    // </>
    <Box className={styles.content}>
      <Publish />
    </Box>
  );
};
