import { makeStyles, createStyles } from '@material-ui/core/styles';

import { variables } from '@styles';

/**
 * SingleModules component styles
 * @returns {Function}
 */

export const useStyles = makeStyles((theme) =>
  createStyles({
    singleModules: {
      background: variables.color.primary.white,
      height: '100%',
    },

    content: {
      display: 'flex',
      flexDirection: 'column',
      maxHeight: 'calc(100% - 1rem)',
      height: '100%',
    },

    searchBar: {
      display: 'flex',
      marginBottom: theme.spacing(1),
    },

    search: {
      '& .MuiInput-root': {
        padding: theme.spacing(0.5, 1.5),
      },
    },

    modulesList: {
      overflowY: 'auto',
      height: 'calc(100% - 4.6rem)',

      '& > div:not(:last-child)': {
        marginBottom: theme.spacing(1),
      },
    },

    draggableCard: {
      background: variables.color.primary.white,
      borderRadius: variables.borderRadius.sm,
      marginBottom: theme.spacing(1),
    },

    loaderBlock: {
      marginBottom: '20px',
    },
  }),
);
