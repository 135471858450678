import { SVGProps } from 'react';

export const Edit2 = ({ width = 24, height = 24, fill = '#ffffff', className }: SVGProps<SVGElement>): JSX.Element => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 1000 1000"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      fillRule="evenodd"
      d="m534.42889,614.28845l446.20844,-446.20834c24.60602,-24.60593 24.60602,-64.70517 0,-89.35463l-60.71216,-60.58257c-24.69299,-23.5209 -64.05444,-22.73952 -88.00854,1.21511l-454.71655,454.67017l-3.51514,7.98502l-21.30826,152.14621c-1.08493,7.63782 5.38123,14.62488 13.88702,13.3667l152.80344,-21.39435c5.77185,-0.82452 10.19861,-6.76996 15.27576,-11.84747l0.086,0.00415zm412.92584,-154.92355l0,376.99551c0,89.57123 -73.16699,162.73737 -162.7373,162.70032l-621.00896,0c-89.57127,0 -162.7003,-73.16705 -162.7003,-162.70032l0,-621.00891c0,-89.57127 73.167,-162.73734 162.7003,-162.73734l376.99547,0l0,71.82181l-376.94919,0c-49.90624,0 -90.91646,40.96671 -90.91646,90.91646l0,621.00883c0,49.94977 40.96671,90.9165 90.91646,90.9165l621.00894,0c49.94971,0 90.91638,-41.01025 90.91638,-90.9165l0,-376.99539l71.77466,-0.00098z"
      fill={fill}
    />
  </svg>
);
