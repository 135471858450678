import { forwardRef, useCallback, useState, ChangeEvent } from 'react';
import { Box, Tab, Tabs } from '@material-ui/core';

import { content } from '@content';

import { SingleModules } from './SingleModules';
import { ModuleGroups } from './ModuleGroups';

import { useStyles } from './ModuleLibrary.styles';
import { ModuleLibraryProps } from './ModuleLibrary.props';

/**
 ModuleLibrary component
 @returns {JSX.Element}
 */

export const ModuleLibrary = forwardRef(
  ({ businessUnitId, onModuleDrag, onModulesDrag, usedExperiences }: ModuleLibraryProps, ref): JSX.Element => {
    const styles = useStyles();

    const [tab, setTab] = useState(0);

    const handleChangeTab = useCallback((event: ChangeEvent<unknown>, value: number) => setTab(value), []);

    return (
      <Box className={styles.moduleLibrary} {...{ ref }}>
        {/* <Box className={styles.tabsWrapper}>
          <Tabs
            value={tab}
            onChange={handleChangeTab}
            classes={{ root: styles.tabs }}
            TabIndicatorProps={{
              style: {
                display: 'none',
              },
            }}
          >
            <Tab
              label={content.modules}
              classes={{ root: styles.tab, selected: styles.selectedTab, wrapper: styles.tabWrapper }}
            />
            <Tab
              label={content.moduleGroups}
              classes={{ root: styles.tab, selected: styles.selectedTab, wrapper: styles.tabWrapper }}
            />
          </Tabs>
        </Box>
        <Box className={styles.content}>
          {tab === 0 && <SingleModules businessUnitId={businessUnitId} onModuleDrag={onModuleDrag} />}
          {tab === 1 && (
            <ModuleGroups
              businessUnitId={businessUnitId}
              onModulesDrag={onModulesDrag}
              usedExperiences={usedExperiences}
            />
          )}
        </Box> */}
        <Box className={styles.content}>
          <SingleModules businessUnitId={businessUnitId} onModuleDrag={onModuleDrag} />
        </Box>
      </Box>
    );
  },
);

ModuleLibrary.displayName = 'ModuleLibrary';
