import { useCallback, useContext, useState, useEffect, DragEvent } from 'react';
import { Box, Button, TextField } from '@material-ui/core';

import { Icon, Menu, OptionItem, Typography } from '@components';
import { variables } from '@styles';
import { format, useHistory } from '@utils';
import { deployment, experience, ExperienceItemMindset, modules, TExperienceType } from '@modules';
import { content } from '@content';
import { TreatmentBuilderContext, EMPTY_MODULE_NOTE, RenameModuleModal, HTMLElementWithDocument } from '@routes';
import { useAppDispatch } from '@store';
import { useDetectClickOutside } from 'react-detect-click-outside';

import { RemoveExperienceModal } from '../RemoveExperienceModal';

import { TreatmentCardProps } from './TreatmentCard.props';
import { useStyles } from './TreatmentCard.styles';
import { MenuKeys, MENU_OPTIONS_UNIVERSAL } from './TreatmentCard.const';
import { number } from '@utils/format/format';
import { OptionsTypes } from '@views/LibraryNavigation/LibraryNavigation.const';
import { TopBarContext } from '@layouts';
import { broadcaster } from '@utils/network/broadcast';

/**
 TreatmentCard component
 @returns {JSX.Element}
 */

export const TreatmentCard = ({
  cardId,
  experienceId,
  treatmentId,
  treatment,
  treatments,
  groupedTreatment = false,
  showRemoveWithRevisions = false,
  chosenTreatmentId,
  type,
  createdWith,
  onRemove,
  setChosenTreatmentId,
  setRightPanelElem,
  onModuleRename,
  onTreatmentRename,
  setShowRemoveWithRevisions,
  setTreatmentPanelType,
  handleRenameModuleModalOpen,
  onFinishMailFileUploading,
  ...rest
}: TreatmentCardProps): JSX.Element => {
  const styles = useStyles();
  const dispatch = useAppDispatch();

  const { businessUnit: businessUnitId } = useHistory().query;
  const [showRemoveTreatmentModal, setShowRemoveTreatmentModal] = useState(false);
  const [showEditTreatmentName, setShowEditTreatmentName] = useState(false);
  const [treatmentName, setTreatmentName] = useState(treatment.name);
  const [moduleOver, setModuleOver] = useState(false);
  const experienceData = experience.useItemData();
  const [topBarCtx, setTopBarCtx] = useContext(TopBarContext);
  const deploymentData = deployment.useDeploymentData();
  const [subscriberMatchCountInterval, setSubscriberMatchCountInterval] = useState<NodeJS.Timer>();
  const [subscriberMatchCount, setSubscriberMatchCount] = useState<{ match: number; percent: number }>();
  const formattedTemplateName =
    treatment.moduleTemplateName.length > 46
      ? `...${treatment.moduleTemplateName.slice(
          treatment.moduleTemplateName.length - 46,
          treatment.moduleTemplateName.length,
        )}`
      : treatment.moduleTemplateName;

  const wrapperRef = useDetectClickOutside({
    onTriggered: () => {
      setShowEditTreatmentName(false);
    },
  });

  const {
    setDefaultChosenTreats,
    setChosenModuleInnerHtml,
    setShowModuleEditor,
    setEditableTreatmentId,
    setShowAddTreatmentPanel,
    setCurrentExperienceId,
    chosenElemId,
    currentExperienceId,
    deploymentsInfo: deployments,
  } = useContext(TreatmentBuilderContext);

  const audienceShare = experience.utils.calcAudienceShare(treatment, treatments);

  const universalTreatment = treatments.find((item) => item.isNullSubscriber);

  const selectTreatment = useCallback(
    (nextTreatment: typeof treatment = treatment) => {
      setChosenTreatmentId(nextTreatment.id);

      setDefaultChosenTreats((prevState) => {
        const chosenTreatment = {
          id: nextTreatment.id,
          experienceId,
        };

        return [...prevState.filter((item) => item.experienceId !== experienceId), chosenTreatment];
      });

      setChosenModuleInnerHtml(nextTreatment.moduleHtml || EMPTY_MODULE_NOTE);
    },
    [experienceId, setChosenModuleInnerHtml, setChosenTreatmentId, setDefaultChosenTreats, treatment],
  );

  const handleClick = useCallback(() => selectTreatment(), [selectTreatment]);

  const handleRemoveTreatmentModalOpen = useCallback(() => {
    if (((deployments && deployments.length > 1) || type === 'revision') && setShowRemoveWithRevisions) {
      setShowRemoveWithRevisions(true);
    } else {
      setShowRemoveTreatmentModal(true);
    }
  }, [deployments, type, setShowRemoveTreatmentModal, setShowRemoveWithRevisions]);

  const handleRemoveTreatmentModalClose = useCallback(() => {
    if (((deployments && deployments.length > 1) || type === 'revision') && setShowRemoveWithRevisions) {
      setShowRemoveWithRevisions(false);
    } else {
      setShowRemoveTreatmentModal(false);
    }
  }, [deployments, type, setShowRemoveTreatmentModal, setShowRemoveWithRevisions]);

  const handleRemoveTreatmentConfirm = useCallback(() => {
    if (onRemove && treatmentId) {
      onRemove(treatmentId);
    }

    handleRemoveTreatmentModalClose();
  }, [treatmentId, handleRemoveTreatmentModalClose, onRemove]);

  const getMenuOptions = (experienceType: TExperienceType): OptionItem[] => {
    let options: OptionItem[] = [
      {
        id: MenuKeys.EDIT_TREATMENT_NAME,
        label: content.editTreatmentName,
        type: OptionsTypes.LAST_USED,
      },
    ];

    if (experienceType === 'logic-builder') {
      options = options.concat([
        {
          id: MenuKeys.EDIT_TREATMENT,
          label: content.editTreatment,
          type: OptionsTypes.LAST_USED,
        },
        {
          id: MenuKeys.REMOVE_TREATMENT,
          label: content.removeTreatment,
          type: OptionsTypes.LAST_USED,
          icon: 'TrashOutline',
        },
        {
          id: '3',
          type: OptionsTypes.DIVIDER,
        },
      ]);
    }

    options = options.concat([
      {
        id: MenuKeys.EDIT_MODULE,
        label: content.editModule,
        type: OptionsTypes.LAST_USED,
      },
    ]);

    return options;
  };

  const onMenuClick = useCallback(
    (option: OptionItem) => {
      const scrollingArea = document.getElementById('sample-template');
      const { contentDocument } = document.getElementById('template') as HTMLElementWithDocument;
      const currentElem = contentDocument.getElementById(chosenElemId);
      const elementTopPosition = (currentElem?.getBoundingClientRect().top ?? 0) - 180;

      switch (option.id) {
        case MenuKeys.EDIT_TREATMENT_NAME:
          if (chosenTreatmentId) {
            setEditableTreatmentId(chosenTreatmentId);
            setShowEditTreatmentName(true);
          }
          break;
        case MenuKeys.EDIT_TREATMENT:
          if (chosenTreatmentId) {
            setTreatmentPanelType('logicBuilder');
            setEditableTreatmentId(chosenTreatmentId);
            setShowAddTreatmentPanel(true);
          }
          break;
        case MenuKeys.EDIT_MODULE:
          setShowModuleEditor(true);
          setShowAddTreatmentPanel(false);

          if (scrollingArea) {
            scrollingArea.scrollTo(0, elementTopPosition);
            scrollingArea.style.overflow = 'hidden';
          }
          break;
        case MenuKeys.REMOVE_TREATMENT:
          handleRemoveTreatmentModalOpen();
          break;
      }
    },
    [
      handleRemoveTreatmentModalOpen,
      setShowModuleEditor,
      setEditableTreatmentId,
      setShowAddTreatmentPanel,
      setShowEditTreatmentName,
      chosenTreatmentId,
      chosenElemId,
    ],
  );

  const handleSingleModuleDragOver = (event: DragEvent<HTMLElement>) => {
    event.preventDefault();
    setModuleOver(true);
  };

  const handleSingleModuleDragLeave = () => setModuleOver(false);

  const handleSingleModuleDrop = useCallback(
    async (event: DragEvent<HTMLElement>) => {
      setModuleOver(false);

      const data = event.dataTransfer?.getData('application/json');
      const { moduleId } = JSON.parse(data || '{}');
      const originModule = Number(moduleId);
      const recipientModule = groupedTreatment ? treatmentId : universalTreatment?.id;

      if (originModule && recipientModule) {
        const cloneResult = await dispatch(
          modules.thunk.clone({
            recipientModule,
            originModule,
          }),
        );

        if (modules.thunk.clone.fulfilled.match(cloneResult) && experienceId) {
          const moduleInfo = await dispatch(modules.thunk.getModuleInfo({ module: cloneResult.payload?.id ?? 0 }));

          if (moduleInfo.payload) {
            const module = moduleInfo.payload as unknown as ExperienceItemMindset;

            dispatch(experience.actions.updateModule(module));

            selectTreatment(module);

            setTimeout(() => {
              broadcaster.postMessage('refresh');
            }, 500);
          }
        }
      }
    },
    [dispatch, setModuleOver, selectTreatment, experienceId, groupedTreatment, treatmentId, universalTreatment?.id],
  );

  const handleTreatmentNameChange = (e: any) => {
    setTreatmentName(e.target.value);
  };

  const handleTreatmentSave = useCallback(async () => {
    if (deployments.length > 1 || type === 'revision') {
      handleRenameModuleModalOpen(treatmentName);
    } else {
      await onTreatmentRename(treatmentName);
      setShowEditTreatmentName(false);
      setTreatmentName(treatmentName);
    }
  }, [treatmentName, type, onTreatmentRename, setShowEditTreatmentName]);

  useEffect(() => {
    if (currentExperienceId !== experienceId) {
      setCurrentExperienceId(experienceId);
    }
  }, [currentExperienceId, experienceId, setCurrentExperienceId]);

  useEffect(() => {
    if (deploymentData?.isMailFileUploading) {
      const interval = setInterval(async () => {
        const response = await dispatch(
          modules.thunk.getSubscriberMatchCount({ module: treatment.id, businessUnit: businessUnitId ?? 0 }),
        );
        const curSubMatchCount = response.payload as any;

        setSubscriberMatchCount({
          match: curSubMatchCount.match,
          percent: curSubMatchCount.total ? Math.round((curSubMatchCount.match / curSubMatchCount.total) * 100) : 0,
        });
      }, 3000);

      setSubscriberMatchCountInterval(interval);
    }
  }, [deploymentData, treatment, businessUnitId]);

  useEffect(() => {
    if (!deploymentData?.isMailFileUploading && subscriberMatchCountInterval) {
      clearInterval(subscriberMatchCountInterval);

      if (onFinishMailFileUploading) {
        onFinishMailFileUploading();
      }
    }
  }, [subscriberMatchCountInterval, deploymentData?.isMailFileUploading]);

  useEffect(() => {
    return () => {
      if (subscriberMatchCountInterval) {
        clearInterval(subscriberMatchCountInterval);
      }
    };
  }, [subscriberMatchCountInterval]);

  return (
    <div ref={wrapperRef}>
      <Box
        onClick={handleClick}
        data-grouped={groupedTreatment}
        className={`${styles.treatmentCard} ${moduleOver ? 'dragging-over' : ''}`}
        onDragOver={handleSingleModuleDragOver}
        onDrop={handleSingleModuleDrop}
        onDragLeave={handleSingleModuleDragLeave}
        {...rest}
      >
        <Box className={styles.indicatorWrapper}>
          {chosenTreatmentId === treatment.id && (
            <Box
              data-grouped={groupedTreatment}
              className={styles.indicator}
              style={{
                borderTopLeftRadius: cardId === 0 || cardId === 'universal' ? variables.borderRadius.sm : 'unset',
                borderBottomLeftRadius:
                  cardId === treatments.length - 2 || cardId === 'universal' ? variables.borderRadius.sm : 'unset',
              }}
            />
          )}
        </Box>
        <Box className={styles.cardContent}>
          {groupedTreatment ? (
            <Box className={styles.labelWrapper}>
              <Icon.DraggableVerticalLines fill={variables.color.primary.mediumGray} />
              {showEditTreatmentName ? (
                <TextField
                  placeholder={content.treatmentName}
                  value={treatmentName}
                  onChange={handleTreatmentNameChange}
                />
              ) : (
                <Box className={styles.treatmentLeft}>
                  <Typography.Label className={styles.treatmentName}>{treatment.name}</Typography.Label>
                  <Typography.SmallCaption title={treatment?.tag?.index}>
                    {treatment?.tag?.index}
                  </Typography.SmallCaption>
                </Box>
              )}
            </Box>
          ) : (
            <Box className={styles.treatmentLeft}>
              <Typography.Label className={styles.treatmentName}>
                {treatment.isNullSubscriber ? content.universal : treatment.name}
              </Typography.Label>
              <Typography.SmallCaption title={treatment?.tag?.index}>{treatment?.tag?.index}</Typography.SmallCaption>
            </Box>
          )}

          <Box className={styles.treatmentInfo}>
            <Box className={groupedTreatment ? styles.treatmentRightGroupedTreatment : styles.treatmentRight}>
              <Box
                data-indicated={!!treatment.moduleHtml}
                data-grouped={groupedTreatment}
                className={styles.treatmentValues}
              >
                {!deploymentData?.isMailFileUploading ? (
                  <>
                    <Typography.Caption className={styles.value}>{number(treatment.audienceSize)}</Typography.Caption>
                    <Typography.SmallCaption className={styles.percent}>
                      ({format.percent(audienceShare)})
                    </Typography.SmallCaption>
                  </>
                ) : (
                  <>
                    <Typography.Caption className={styles.value}>
                      {number(subscriberMatchCount?.match)}
                    </Typography.Caption>
                    <Typography.SmallCaption className={styles.percent}>
                      ({format.percent(subscriberMatchCount?.percent)})
                    </Typography.SmallCaption>
                  </>
                )}
              </Box>
            </Box>
            {showEditTreatmentName ? (
              <Button
                color="primary"
                variant="contained"
                className={styles.treatmentBtnSave}
                disabled={treatment.name === treatmentName}
                onClick={handleTreatmentSave}
              >
                <Icon.Checkmark stroke={variables.color.primary.white} style={{ cursor: 'pointer' }} />
              </Button>
            ) : (
              <>
                {/* {groupedTreatment ? (
                  <Menu buttonClassName={styles.menu} onClick={onMenuClick} options={getMenuOptions(createdWith!)} />
                ) : universalTreatment && universalTreatment.moduleHtml ? (
                  <Menu buttonClassName={styles.menu} onClick={onMenuClick} options={MENU_OPTIONS_UNIVERSAL} />
                ) : (
                  ''
                )} */}
              </>
            )}
            {/* {showRemoveWithRevisions && (
              <RenameModuleModal
                title={content.renameModuleGroup}
                alertMessage={content.thisModuleGroupUsedByAnotherDeployment}
                moduleData={{
                  moduleTemplateName: `${topBarCtx.contentModuleName}` ?? '',
                  deployments: deployments || [],
                  isRenameRequired: experienceData?.type === 'revision',
                }}
                submitLabel={content.yesDelete}
                onClose={handleRemoveTreatmentModalClose}
                onSubmit={onModuleRename}
                isDeleteTreatment
              />
            )} */}
            {showRemoveTreatmentModal && (
              <RemoveExperienceModal
                label={content.removeTreatment}
                onClose={handleRemoveTreatmentModalClose}
                onSubmit={handleRemoveTreatmentConfirm}
              />
            )}
          </Box>
        </Box>
      </Box>
    </div>
  );
};
