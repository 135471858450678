import { Box, Checkbox, CheckboxProps, FormControlLabel, FormGroup } from '@material-ui/core';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { content } from '@content';
import { ModuleDisclosureStation, experience } from '@modules';
import { TreatmentBuilderContext } from '@routes';
import { textTemplate } from '@utils';
import JoditEditor from 'jodit-react';

import { useStyles } from './DisclosureText.styles';

/**
 DisclosureText route component
 @returns {JSX.Element}
 */
type TProps = {
  module?: any;
  setDisclosureRequired: (value: boolean) => void;
};
export const DisclosureText = ({ module, setDisclosureRequired }: TProps): JSX.Element => {
  const styles = useStyles();

  const { chosenExperienceId, chosenModuleId, setIsModuleChanged, disclosure, setDisclosure } =
    useContext(TreatmentBuilderContext);

  const experienceMindsets = experience.useMindsetsData();

  const mindsetsModules = experienceMindsets.find((item) => {
    if (chosenExperienceId) {
      return item.experienceId === +chosenExperienceId;
    }
  });

  const chosenModule = module?.moduleDisclosureStation
    ? module
    : mindsetsModules?.modules.find((moduleItem) => moduleItem.id === chosenModuleId);

  const { control, setValue, reset, handleSubmit } = useForm<ModuleDisclosureStation>({
    defaultValues: {
      disclosureCopy: '',
      disclosureHtml: '',
    },
  });
  const station = useMemo(() => chosenModule?.moduleDisclosureStation, [chosenModule?.moduleDisclosureStation]);

  const [enabled, setEnabled] = useState(Array.isArray(station) ? false : !!station);
  const [curValue, setCurValue] = useState('');

  const handleToggle = useCallback<Required<CheckboxProps>['onChange']>(
    (...[, checked]) => {
      setEnabled(checked);
      setDisclosureRequired(checked);

      if (checked) {
        const id = station?.id;

        if (id) {
          setValue('id', id);
        }
      }

      setValue('disclosureHtml', '');
      setValue('disclosureCopy', '');

      setIsModuleChanged(true);
      setCurValue('');

      handleSubmit(setDisclosure)();
    },
    [setDisclosureRequired, setValue, setIsModuleChanged, handleSubmit, setDisclosure, station?.id],
  );

  const handleDisclosureCopyChange = useCallback(
    (copy, isInitialization = false) => {
      const paragraph = document.createElement('p');

      // paragraph.innerHTML = copy.trim().replaceAll('\n', '<br/>');
      paragraph.innerHTML = copy.trim();

      const html = paragraph.outerHTML;

      if (isInitialization?.type === 'blur' && curValue !== copy) {
        setIsModuleChanged(true);
      }

      setValue('disclosureCopy', copy);
      setValue('disclosureHtml', html);

      setCurValue(copy);

      handleSubmit(setDisclosure)();
    },
    [curValue, setIsModuleChanged, setValue, handleSubmit, setDisclosure],
  );

  useEffect(() => {
    if (station) {
      reset(station);
    }
  }, [reset, station]);

  useEffect(() => {
    if (chosenModule && !disclosure) {
      handleDisclosureCopyChange(chosenModule.moduleDisclosureStation?.disclosureCopy ?? '', true);
    }
  }, [chosenModule, disclosure, handleDisclosureCopyChange]);

  useEffect(() => {
    setDisclosureRequired(enabled);
  }, [enabled, setDisclosureRequired]);

  const joditConfig = useMemo(
    () => ({
      allowResizeX: false,
      buttons: [
        'bold',
        'italic',
        'underline',
        'strikethrough',
        'superscript',
        'subscript',
        // 'eraser',
        //'|',
        'ul',
        'ol',
        //'|',
        'outdent',
        'indent',
        '|',
        'font',
        'fontsize',
        'brush',
        // 'paragraph',
        '|',
        // 'image',
        'table',
        'link',
        '|',
        'align',
        // 'undo',
        // 'redo',
        '|',
        'hr',
        // 'copyformat',
        // 'fullsize',
        // 'selectall',
        // 'print',
        '|',
        'source',
      ],
      height: '400',
      showCharsCounter: false,
      showWordsCounter: false,
      toolbarButtonSize: 'small' as const,
      removeButtons: ['eraser', 'paragraph'],
      width: '100%',
    }),
    [],
  );

  return (
    <Box>
      <Box>
        <FormGroup row>
          <FormControlLabel
            name="enabled"
            control={<Checkbox checked={enabled} onChange={handleToggle} />}
            label={content.disclosureRequired}
          />
        </FormGroup>
      </Box>
      <Box className={styles.disclosureText}>
        <div className={styles.editorWrapper}>
          {!enabled && <div className={styles.editorOverlay}></div>}
          <JoditEditor
            className={styles.joditEditor}
            value={curValue}
            onBlur={handleDisclosureCopyChange}
            config={joditConfig}
          />
        </div>
      </Box>
    </Box>
  );
};
