import { Box } from '@material-ui/core';

import { Typography } from '@components';

import { useStyles } from './ModuleCard.styles';
import { ModuleCardProps } from './ModuleCard.types';
import { useCallback, useEffect, useRef, useState } from 'react';

export const ModuleCard = ({
  variant = 'default',
  coverImage,
  name,
  index,
  deploymentName,
  ctir,
  onModuleDrag,
  moduleId,
}: ModuleCardProps) => {
  const styles = useStyles();
  const [tag, setTag] = useState(index && index.length >= 11 ? `${index.substring(0, 10)}...` : index);
  const [imageIsHigher, setImageIsHigher] = useState(false);
  const coverImageRef = useRef(null);

  const handleExpand = useCallback(
    (e: any) => {
      e.stopPropagation();

      setTag((prev) => {
        const newIndex = index ? index.substring(0, 10) : '';
        return prev === index ? `${newIndex}...` : index;
      });
    },
    [index],
  );

  useEffect(() => {
    const image = new Image();

    image.onload = function () {
      setImageIsHigher((coverImageRef.current as any)?.getBoundingClientRect().height > 195);
    };

    image.src = coverImage ?? '';
  }, [coverImageRef, coverImage]);

  return (
    <Box
      className={styles.moduleCard}
      data-variant={variant}
      data-module-id={moduleId || ''}
      draggable={variant === 'draggableV2'}
      onDragStart={variant === 'draggableV2' ? onModuleDrag : undefined}
    >
      <Box className={styles.coverImageWrapper}>
        <img
          ref={coverImageRef}
          src={coverImage}
          alt="cover-image"
          className={styles.coverImage}
          style={{ bottom: imageIsHigher ? 'none' : '0', top: imageIsHigher ? '0' : 'none' }}
        />
      </Box>
      <Box className={styles.content}>
        <Box className={styles.moduleRow}>
          <Typography.Headline className={styles.name}>{name}</Typography.Headline>
          <Typography.SmallCaption>
            CTIR: <span className={styles.contentValue}>{ctir}%</span>
          </Typography.SmallCaption>
        </Box>
        <Box className={styles.moduleRow}>
          <Typography.SmallCaption className={styles.index} onClick={handleExpand}>
            {tag}
          </Typography.SmallCaption>
          <Typography.SmallCaption className={styles.deploymentName}>
            <span className={styles.contentValue}>{deploymentName}</span>
          </Typography.SmallCaption>
        </Box>
      </Box>
    </Box>
  );
};
