import { KeyboardEvent, useCallback, useState, MouseEvent, useContext, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Box, Button } from '@material-ui/core';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import debounce from 'lodash.debounce';

import { Icon, Menu, OptionItem, TextField, TextFieldProps } from '@components';
import { content } from '@content';
import { dataVisualization, imageLibrary, modules, ModulesSearchParams } from '@modules';
import { useAppDispatch } from '@store';
import { searchQuery, textTemplate } from '@utils';

import { useStyles } from './ContentCenterFilters.styles';
import {
  ContentCenterFiltersProps,
  ModuleLibraryFormProps,
  ModuleLibrarySearchType,
  SortOptions,
} from './ContentCenterFilters.props';
import { INITIAL_OPTIONS, OptionsTypes, ITEMS_PER_PAGE_FOR_SUGGESTIONS } from './ContentCenterFilters.const';
import { TOP_BAR_CTX_VALUE, TopBarContext } from '@layouts';
import { variables } from '@styles';

/**
 ContentCenterFilters component.
 @returns {JSX.Element}
 */

export const ContentCenterFilters = ({
  type,
  setIsImageEditorVisible,
  setIsTemplateEditorVisible,
  setIsDVEditorVisible,
  onSearch,
  onUploadModule,
}: ContentCenterFiltersProps): JSX.Element => {
  const styles = useStyles();
  const dispatch = useAppDispatch();
  const [businessUnitId] = searchQuery.useMutualParams('businessUnit');
  const [, setTopBarCtx] = useContext(TopBarContext);

  const { control, setValue, getValues } = useForm<ModuleLibraryFormProps>({
    defaultValues: {
      searchType: 'deployment',
      keyword: '',
      onlyRecent: false,
    },
  });

  const [options, setOptions] = useState<OptionItem[]>(INITIAL_OPTIONS);
  const [nameSuggestions, setNameSuggestions] = useState<TextFieldProps['suggestions']>([]);

  // setting name suggestions by businessUnit id and entered name
  const fetchSuggestions = debounce(
    useCallback(async () => {
      const query = {
        itemsPerPage: ITEMS_PER_PAGE_FOR_SUGGESTIONS,
        ...getValues(),
        businessUnitId,
        page: 1,
      } as ModulesSearchParams;

      let result: any;

      if (query.searchType === 'dataVisual') {
        dispatch(dataVisualization.actions.resetDataVisualizationList());
        result = await dispatch(
          dataVisualization.thunk.getListDataVisualization({
            ...query,
            businessUnit: query.businessUnitId,
            keyword: query.keyword!,
          }),
        );
      } else if (query.searchType === 'deployment') {
        result = await dispatch(modules.thunk.search(query));
      } else {
        result = await dispatch(
          imageLibrary.thunk.search({
            ...query,
            itemsPerPage: query.keyword ? ITEMS_PER_PAGE_FOR_SUGGESTIONS : 20,
            businessUnit: query.businessUnitId,
            keyword: query.keyword!,
            sortBy: 'id',
          } as any),
        );
      }

      setNameSuggestions(
        result.payload?.items.map((item: any) => {
          return item.name;
        }),
      );
    }, [setNameSuggestions, businessUnitId, dispatch, getValues]),
    500,
  );

  const handleChangeKeyword: TextFieldProps['onChange'] = useCallback(
    (name, suggested) => {
      setValue('keyword', name);
      dispatch(imageLibrary.actions.setSearch(getValues().keyword));

      if (suggested || name.length < 2) {
        setNameSuggestions([]);
        fetchSuggestions();
      } else {
        fetchSuggestions();
      }

      if (suggested) {
        onSearch(getValues());
      }
    },
    [setValue, fetchSuggestions, onSearch, getValues],
  );

  const handleSearchEnter = useCallback(
    (event: KeyboardEvent<HTMLInputElement>) => {
      if (event.key === 'Enter') {
        onSearch(getValues());
      }
    },
    [onSearch, getValues],
  );

  const handleSearchByChange = useCallback(
    (item: OptionItem) => {
      const newOptions = options.map((element) => {
        if (element.id === item.id) {
          return { ...element, active: true };
        } else {
          if (element.subOptions?.length) {
            const subOptions = element.subOptions?.map((sub) => {
              if (sub.id === item.id) {
                return { ...sub, active: true };
              } else if (sub.type === item.type) {
                return { ...sub, active: false };
              }
              return sub;
            });
            return { ...element, subOptions };
          }
          if (element.type === item.type) {
            return { ...element, active: false };
          }
          return element;
        }
      });
      setOptions(newOptions);

      if (item.type === OptionsTypes.LAST_USED) {
        const itemValue = item.value as 0 | 1;
        setValue('onlyRecent', itemValue === 1);
      } else if (item.type === OptionsTypes.SORT_BY) {
        setValue('sortBy', item.value as SortOptions);
      }

      onSearch(getValues());
    },
    [options, setValue, onSearch, getValues],
  );

  const handleSearchTypeChange = useCallback(
    (event: MouseEvent<HTMLElement>, newType: ModuleLibrarySearchType) => {
      switch (newType) {
        case 'deployment':
          setTimeout(() => {
            setTopBarCtx((prev) => ({
              ...prev,
              moduleType: 'Tab',
              sectionName: textTemplate(content.entityManager, { entity: content.content }),
              moduleName: content.contentModules,
            }));
          }, 100);

          setIsImageEditorVisible(false);
          setIsTemplateEditorVisible(false);
          setIsDVEditorVisible(false);
          break;
        case 'module':
          setTimeout(() => {
            setTopBarCtx((prev) => ({
              ...prev,
              moduleType: 'Tab',
              sectionName: textTemplate(content.entityManager, { entity: content.content }),
              moduleName: content.moduleTemplates,
            }));
          }, 100);

          setIsImageEditorVisible(false);
          setIsTemplateEditorVisible(false);
          setIsDVEditorVisible(false);
          break;
        case 'library':
          setTimeout(() => {
            setTopBarCtx((prev) => ({
              ...prev,
              moduleType: 'Tab',
              sectionName: textTemplate(content.entityManager, { entity: content.content }),
              moduleName: content.images,
            }));
          }, 100);
          setIsTemplateEditorVisible(false);
          setIsDVEditorVisible(false);
          break;
        case 'dataVisual':
          setTimeout(() => {
            setTopBarCtx((prev) => ({
              ...prev,
              moduleType: 'Tab',
              sectionName: textTemplate(content.entityManager, { entity: content.content }),
              moduleName: content.dataVisualizations,
            }));
          }, 100);
          setIsImageEditorVisible(false);
          setIsTemplateEditorVisible(false);
          break;
        default:
          break;
      }

      setValue('searchType', newType);
      onSearch(getValues());
    },
    [onSearch, getValues, setValue],
  );

  useEffect(() => {
    setTopBarCtx((prev) => ({
      ...prev,
      moduleType: 'Tab',
      moduleName: content.contentModules,
    }));

    return () => {
      setTopBarCtx(() => TOP_BAR_CTX_VALUE);
      dispatch(imageLibrary.actions.setSearch(''));
    };
  }, [setTopBarCtx]);

  return (
    <Box className={styles.libraryFilters}>
      <Box>
        <Controller
          name="searchType"
          control={control}
          render={({ field: { value, ...field } }) => (
            <ToggleButtonGroup
              {...field}
              value={value}
              exclusive
              onChange={handleSearchTypeChange}
              aria-label="Library Type"
            >
              <ToggleButton className={styles.tab} data-left-tab value="deployment" aria-label="Module Instances">
                {content.contentModules}
              </ToggleButton>
              {/* <ToggleButton className={styles.tab} data-center-tab value="module" aria-label="Module Templates">
                  {content.moduleTemplates}
                </ToggleButton> */}
              <ToggleButton className={styles.tab} data-right-tab value="library" aria-label="Images">
                {content.images}
              </ToggleButton>
              <ToggleButton className={styles.tab} data-right-tab value="dataVisual" aria-label="Data Visualizations">
                {content.dataVisualizations}
              </ToggleButton>
            </ToggleButtonGroup>
          )}
        />
      </Box>
      <Box className={styles.rightElements}>
        <Controller
          name="keyword"
          control={control}
          render={({ field: { ref, ...field } }) => (
            <TextField
              {...field}
              className={styles.search}
              size="small"
              inputRef={ref}
              placeholder={content.search}
              suggestions={nameSuggestions}
              onChange={handleChangeKeyword}
              onKeyUp={handleSearchEnter}
              icon="SearchOutline"
            />
          )}
        />
        {['yourLibrary', 'imageLibrary', 'dataVisual'].includes(type) && (
          <Button className={styles.uploadModule} variant="outlined" color="primary" onClick={onUploadModule}>
            <Icon.Upload fill={variables.color.primary.darkestGray} height="20" width="20" />
          </Button>
        )}
        {['yourLibrary', 'templates'].includes(type) && (
          <Box className={styles.menu}>
            <Controller
              name="sortBy"
              control={control}
              render={({ field: { ...field } }) => (
                <Menu
                  {...field}
                  options={options}
                  onClick={handleSearchByChange}
                  icon={<Icon.EllipsisHorizontal height="20" width="20" />}
                />
              )}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
};
