import { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { content } from '@content';
import { TOP_BAR_CTX_VALUE, TopBarContext } from '@layouts';
import { DeploymentStatus, Template, businessUnitDeployments, deployment, template, userClients } from '@modules';
import { TemplateView } from '@routes';
import { useAppDispatch } from '@store';
import { HistoryParams, textTemplate, useHistory, useWebTitle } from '@utils';

import { TemplateListProps } from './TemplateList';
import { TemplateUpload } from './TemplateUpload';

import { updateTemplateExperienceIds } from './Deployments.helpers';

/**
 Deployments route component.
 @returns {JSX.Element}
 */

export const Deployments = (): JSX.Element => {
  const dispatch = useAppDispatch();

  const history = useHistory();

  const {
    businessUnit: businessUnitId,
    deployment: deploymentId,
    mailFile: mailFileId,
    template: templateId,
  } = history.query;

  const [, setTopBarCtx] = useContext(TopBarContext);
  const { lastEmailTemplateId } = {
    ...TOP_BAR_CTX_VALUE,
    ...useContext(TopBarContext)[0],
  };

  const deployments = businessUnitDeployments.useData();
  const {
    data: { deploymentEmailTemplates },
  } = deployment.useInfoEmailTemplate();

  const [templateUpload, setTemplateUpload] = useState(false);

  const currentClientData = userClients.useEntityData();

  useEffect(() => {
    if (!lastEmailTemplateId && history.query.template) {
      setTopBarCtx((prev) => ({
        ...prev,
        lastEmailTemplateId: history.query.template,
        isTemplateIdChanged: !!prev.lastEmailTemplateId,
      }));
    }

    const selectedTmplId = deploymentEmailTemplates?.find((item) => item.isSelected)?.id;

    setTopBarCtx((prev) => ({
      ...prev,
      isTemplateListVisible: prev.isTemplateListVisible || (!history.query.template && !selectedTmplId),
    }));
  }, [lastEmailTemplateId, history.query, deploymentEmailTemplates, setTopBarCtx]);

  useEffect(() => {
    setTopBarCtx((prev) => ({
      ...prev,
      lastEmailTemplateId: undefined,
      isTemplateListVisible: false,
      isTemplateIdChanged: false,
    }));
  }, [setTopBarCtx, deploymentId]);

  const currDpt = useMemo(() => {
    if (currentClientData) {
      const deps = deployments || [];

      if (deploymentId) {
        return deps.find((item) => item.id === deploymentId);
      }

      if (deps.length !== 0) {
        const [firstDep] = deps;

        return firstDep;
      }
    }

    return undefined;
  }, [currentClientData, deployments, deploymentId]);

  const currentDeploymentName = useMemo(() => currDpt?.name || '', [currDpt]);

  useWebTitle(textTemplate(content.entityManager, { entity: content.deployment }), currentDeploymentName);

  useEffect(() => {
    const promises =
      businessUnitId && deploymentId
        ? [
            dispatch(businessUnitDeployments.thunk.getAll(businessUnitId)),
            dispatch(deployment.thunk.getInfoEmailTemplate(deploymentId)),
          ]
        : null;

    return () => {
      if (promises) {
        promises.forEach((promise) => promise.abort());
        setTopBarCtx(() => TOP_BAR_CTX_VALUE);
      }
    };
  }, [dispatch, setTopBarCtx, businessUnitId, deploymentId]);

  // const handleDeploymentNameChange = (name: string) => {
  //   // TODO: make deployment name change function after the back-end is ready
  // };

  useEffect(() => {
    if (currentDeploymentName) {
      setTopBarCtx((prev) => ({
        ...prev,
        variant: 'picker',
        sectionName: textTemplate(content.entityManager, { entity: content.deployment }),
      }));
    }
  }, [setTopBarCtx, currentDeploymentName, history.query.template]);

  useEffect(() => {
    if (deploymentId) {
      const nextParams: HistoryParams = {};

      if (!mailFileId) {
        const [firstMailFile] = currentClientData?.mailFile || [];

        if (firstMailFile) {
          nextParams.mailFile = firstMailFile.id;
        }
      }

      if (!templateId && currDpt?.status === DeploymentStatus.inMarket) {
        const dptTemplate = deploymentEmailTemplates?.find((tpl) => tpl.isSelected);

        if (dptTemplate) {
          nextParams.template = dptTemplate.id;
        }
      }

      if (Object.keys(nextParams).length !== 0) {
        history.push('', nextParams);
      }
    }
  }, [
    currentClientData?.mailFile,
    deploymentEmailTemplates,
    deploymentId,
    history,
    mailFileId,
    templateId,
    currDpt?.status,
  ]);

  const toggleTemplateUpload = useCallback(() => {
    setTemplateUpload((prev) => !prev);
  }, [setTemplateUpload]);

  const handleTemplateListSelect: TemplateListProps['onSelect'] = useCallback(
    async (id) => {
      const firstMailFile = currentClientData?.mailFile[0];
      const selectionResult = await dispatch(template.thunk.selectTemplateToDeployment(id));

      if (template.thunk.selectTemplateToDeployment.fulfilled.match(selectionResult)) {
        const tmplUpdated = await updateTemplateExperienceIds(id, dispatch, deploymentEmailTemplates);

        if (tmplUpdated) {
          history.push('', { template: id });
        }
      }

      if (deploymentId && !mailFileId && firstMailFile) {
        dispatch(deployment.thunk.attachMailFile({ deploymentId, mailFileId: firstMailFile.id }));
      }

      setTopBarCtx((prev) => ({
        ...prev,
        isTemplateIdChanged: true,
      }));
    },
    [
      dispatch,
      // updateTemplateExperienceIds,
      deploymentEmailTemplates,
      setTopBarCtx,
      history,
      deploymentId,
      mailFileId,
      currentClientData?.mailFile,
    ],
  );

  return (
    <>
      <TemplateView
        templateId={templateId!}
        templates={deploymentEmailTemplates as Template[]}
        selectedTemplate={templateId}
        onTemplateSelect={handleTemplateListSelect}
        onTemplateUpload={toggleTemplateUpload}
      />
      {templateUpload && <TemplateUpload onClose={toggleTemplateUpload} />}
    </>
  );
};
