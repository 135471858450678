import { makeStyles, createStyles } from '@material-ui/core/styles';

import { variables } from '@styles';

/**
 * Treatments component styles
 * @returns {Function}
 */

export const useStyles = makeStyles((theme) =>
  createStyles({
    treatments: {
      height: '100%',
    },

    header: {
      // display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      borderBottom: `${variables.borderWidth.md} solid ${variables.color.primary.lightGrayDarker}`,
      padding: theme.spacing(2.5, 3.125),
    },

    panelTitle: {
      fontSize: '16px!important',
      lineHeight: '20px!important',
      color: `${variables.color.primary.darkPurple}!important`,
      marginBottom: theme.spacing(0.5),

      ['@media (min-width: 1024px) and (max-width: 1580px)']: {
        wordBreak: 'break-word',
      },
    },

    addTreatment: {
      // marginRight: theme.spacing(1),

      '&.hovered': {
        color: variables.color.primary.mainPurple,
        border: `0.2rem solid ${variables.color.primary.lightPurple}`,
      },
      '&.hovered svg path': {
        stroke: variables.color.primary.mainPurple,
        fill: variables.color.primary.mainPurple,
      },

      '& svg path': {
        transition: 'fill 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
      },

      '&:hover svg path': {
        stroke: variables.color.primary.mainPurple,
        fill: variables.color.primary.mainPurple,
      },

      ['@media (min-width: 1024px) and (max-width: 1580px)']: {
        // width: '100%',
      },
    },

    addEditTreatmentButton: {
      border: `1px solid ${variables.color.primary.lightGrayDarker}`,
      marginLeft: '0.5rem',
      minWidth: '3.8rem',
      padding: theme.spacing(1, 1),
      width: '3.8rem',

      '&.hovered': {
        color: variables.color.primary.mainPurple,
        border: `0.1rem solid ${variables.color.primary.mainPurple}`,
      },
      '&.hovered svg path': {
        stroke: variables.color.primary.mainPurple,
        fill: variables.color.primary.mainPurple,
      },

      '&:hover': {
        border: `0.1rem solid ${variables.color.primary.mainPurple}`,
      },

      '& svg path': {
        transition: 'fill 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
      },

      '&:hover svg path': {
        stroke: variables.color.primary.mainPurple,
        fill: variables.color.primary.mainPurple,
      },
    },

    panelTitleHeading: {
      marginBottom: theme.spacing(0.5),
    },

    renameBar: {
      display: 'flex',
      // alignItems: 'center',
      flexDirection: 'column',
      justifyContent: 'space-between',
      width: '100%',
    },

    renameField: {
      marginBottom: theme.spacing(1),
      marginTop: theme.spacing(1),
    },

    renameBarButtons: {
      display: 'flex',
      gap: '10px',
    },

    menu: {
      padding: theme.spacing(1),
      border: `${variables.borderWidth.md} solid ${variables.color.primary.lightGrayDarker}`,
      borderRadius: variables.borderRadius.sm,
    },

    treatmentQuestionBody: {
      marginTop: '15px',
    },

    buttonsWrapper: {
      display: 'flex',
      flexDirection: 'column',
      marginTop: '10px',
    },

    targetingBtn: {
      width: 'fit-content',
    },

    targetTreatments: {
      padding: theme.spacing(2),
      // borderBottom: `${variables.borderWidth.md} solid ${variables.color.primary.lightGrayDarker}`,
      // backgroundColor: variables.color.primary.backgroundLightestGray,
    },

    targetTreatmentsOptions: {
      display: 'flex',
      justifyContent: 'left', // 'space-between',
      alignItems: 'center',
      marginTop: '-1px',
      padding: theme.spacing(0, 2, 2, 2),
      // borderBottom: `${variables.borderWidth.md} solid ${variables.color.primary.lightGrayDarker}`,
      // backgroundColor: variables.color.primary.backgroundLightestGray,
    },

    content: {
      // borderBottom: `${variables.borderWidth.md} solid ${variables.color.primary.lightGrayDarker}`,
      padding: theme.spacing(2, 2, 0, 2),
    },

    treatmentCards: {
      // borderBottom: `${variables.borderWidth.md} solid ${variables.color.primary.lightGrayDarker}`,
      padding: theme.spacing(2, 2, 0, 2),
      overflow: 'auto',
      // height: 'calc(100vh - 310px)',
    },

    groupedTreatments: {
      border: `${variables.borderWidth.md} solid ${variables.color.primary.lightGrayDarker}`,
      borderRadius: variables.borderRadius.sm,
    },

    treatmentWrapper: {
      '&:nth-of-type(n)': {
        borderBottom: `${variables.borderWidth.md} solid ${variables.color.primary.lightGrayDarker}`,
      },

      '&:nth-last-of-type(1)': {
        borderBottom: 'none',
      },
    },
  }),
);
