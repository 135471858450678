import { useEffect, useState, useRef, memo, ChangeEvent, KeyboardEvent, useCallback, useContext } from 'react';
import { Box, TextField } from '@material-ui/core';
import InfiniteScroll from 'react-infinite-scroll-component';

import { Icon, ModuleCard, Typography } from '@components';
import { content } from '@content';
import { useAppDispatch } from '@store';
import { modules, ModulesSearchParams, SearchModulesPayload } from '@modules';
import { TreatmentBuilderContext } from '@routes';
import { variables } from '@styles';

import { useStyles } from './SingleModules.styles';
import { INITIAL_QUERY } from './SingleModules.const';
import { SingleModulesProps } from './SingleModules.props';
import { messageReceiver } from '@utils/network/broadcast';
import LoadingBlockPlaceholder from '@components/LoadingBlockPlaceholder/LoadingBlockPlaceholder';

/**
 * SingleModules component
 * @returns {JSX.Element}
 */

export const SingleModules = memo(({ businessUnitId, onModuleDrag }: SingleModulesProps) => {
  const styles = useStyles();
  const wrapperElem = useRef<null | HTMLDivElement>(null);
  const contentContainer = useRef<null | HTMLDivElement>(null);
  const [query, setQuery] = useState<ModulesSearchParams>({
    ...INITIAL_QUERY,
    businessUnitId,
  });
  const [items, setItems] = useState<SearchModulesPayload['items']>([]);
  const data = modules.useLibraryData();
  const { status, error } = modules.useLibraryMeta();
  const dispatch = useAppDispatch();

  const { setSingleModulesWrapper } = useContext(TreatmentBuilderContext);

  useEffect(() => {
    setSingleModulesWrapper(wrapperElem.current);
  }, [wrapperElem.current, setSingleModulesWrapper]); // eslint-disable-line react-hooks/exhaustive-deps

  // search by keyword
  const handleChangeKeyword = (event: ChangeEvent<HTMLInputElement>) => {
    setQuery((prevState) => ({
      ...prevState,
      keyword: event.target.value,
      page: 1,
    }));
  };

  // getting modules by the query
  const getModules = useCallback(async () => {
    await dispatch(modules.thunk.search(query));
  }, [dispatch, query]);

  const handleSearch = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      setItems([]);
      dispatch(modules.actions.resetLibrary());

      getModules();
    }
  };

  const loadMore = () => setQuery((prevState) => ({ ...prevState, page: query.page + 1 }));

  useEffect(() => {
    getModules();
  }, [dispatch, query.onlyRecent, query.page, query.sortBy]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(
    () => {
      messageReceiver.onmessage = async (event) => {
        dispatch(modules.actions.resetLibrary());
        await dispatch(modules.thunk.search({ ...query, page: 1 }));
        setQuery((prevState) => {
          // dispatch(modules.thunk.search({ ...prevState, page: 1 }));
          return { ...prevState, page: 1 };
        });
      };
    },
    //[messageReceiver, setQuery]
    [dispatch, setQuery, query],
  );

  useEffect(() => {
    if (!data.items.length) {
      setItems([]);

      return;
    }
    setItems((prevState) =>
      [
        ...prevState.filter((prevStateItem) => !data.items.find((item) => item.id === prevStateItem.id)),
        ...(data.items || []),
      ].map((item) => {
        // const tagParts = item.tag.index.split('-');

        return {
          ...item,
          formattedName: `${item.templateName} - ${item.name}`,
        };
      }),
    );
  }, [data.items]);

  useEffect(
    () => {
      return () => {
        setItems([]);

        // messageReceiver.close();
        dispatch(modules.actions.resetLibrary());
      };
    },
    // [messageReceiver]
    [dispatch],
  );

  // calculate the number of loader blocks required
  const [loaderRowCount, setLoaderRowCount] = useState(1);

  useEffect(() => {
    if (contentContainer.current) {
      const containerHeight = contentContainer.current.offsetHeight;
      const LOADER_HEIGHT_WITH_MARGIN = 255;
      setLoaderRowCount(() => Math.ceil(containerHeight / LOADER_HEIGHT_WITH_MARGIN));
    }
  }, [contentContainer]);

  return (
    <Box className={styles.singleModules} {...{ ref: wrapperElem }}>
      <Box className={styles.content} {...{ ref: contentContainer }}>
        <Box className={styles.searchBar}>
          <TextField
            classes={{
              root: styles.search,
            }}
            fullWidth
            variant="standard"
            placeholder={content.search}
            value={query.keyword}
            onChange={handleChangeKeyword}
            onKeyPress={handleSearch}
            InputProps={{
              disableUnderline: true,
              endAdornment: <Icon.SearchOutline stroke={variables.color.primary.mediumGray} />,
            }}
          />
        </Box>
        <Box className={styles.modulesList} id="modulesList">
          <InfiniteScroll
            next={loadMore}
            loader=""
            hasMore={data.currentPage < data.pageCount}
            dataLength={data.items.length}
            scrollableTarget="modulesList"
          >
            {items.map((module) => (
              <Box key={module.id} className={styles.draggableCard}>
                <ModuleCard
                  variant="draggableV2"
                  moduleId={module.id}
                  name={(module as any).formattedName}
                  tag={module.tag.index}
                  index={module.tag?.index}
                  deploymentName={module.deployment?.name ?? content.notInUse}
                  instances={module.deploymentsCount}
                  ctir={module.statistics?.ctir ? Math.round(module.statistics?.ctir * 10) / 10 : '--'}
                  coverImage={module.coverImage}
                  publishedAt={module.publishedAt}
                  onModuleDrag={onModuleDrag}
                />
              </Box>
            ))}
          </InfiniteScroll>
        </Box>

        {status === 'loading' &&
          query.page === 1 &&
          [...Array(loaderRowCount)].map((_: number, index: number) => (
            <Box key={`loaderRow-${index}`} className={styles.loaderBlock}>
              <LoadingBlockPlaceholder />
            </Box>
          ))}
        {status === 'error' && (
          <Typography.Body align="center">{error?.message ? error.message : content.failedToLoadItems}</Typography.Body>
        )}
      </Box>
    </Box>
  );
});

SingleModules.displayName = 'SingleModules';
