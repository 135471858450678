import { createContext } from 'react';
import { ViewportSize, ViewportSizeContextType } from './Viewport.types';

const defaultState: ViewportSizeContextType = {
  viewportSize: ViewportSize.Desktop,
  setViewportSize: () => {},
  isViewportSizeEnabled: false,
  setIsViewportSizeEnabled: () => {},
};

export const ViewportSizeContext = createContext<ViewportSizeContextType>(defaultState);
