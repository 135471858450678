import { makeStyles, createStyles } from '@material-ui/core/styles';

/**
 * ImageContainer component styles
 * @returns {Function}
 */

export const useStyles = makeStyles((theme) =>
  createStyles({
    editMode: {
      display: 'flex',
      // gap: '10px',
      width: '100%',
      // justifyContent: 'flex-end',
    },

    radioGroup: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'row',

      ['@media (min-width: 1024px) and (max-width: 1580px)']: {
        alignItems: 'flex-start',
        flexDirection: 'column',
        flexWrap: 'nowrap',
      },
    },
    radioBox: {
      display: 'flex',
    },
    radioLabel: {
      display: 'flex',
      alignItems: 'center',
      // minWidth: '62px',
      ['@media (min-width: 1024px) and (max-width: 1580px)']: {
        alignItems: 'center',
      },
    },
    checkbox: {
      display: 'flex',
      alignItems: 'center',

      ['@media (min-width: 1024px) and (max-width: 1580px)']: {
        alignItems: 'flex-start',
        padding: '0px 9px',
      },
    },
    editModeLabel: {
      ['@media (min-width: 1024px) and (max-width: 1580px)']: {
        alignItems: 'flex-start',
      },
    },
  }),
);
