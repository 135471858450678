import { SVGProps } from 'react';

export const Preview = ({
  width = 24,
  height = 24,
  fill = '#ffffff',
  className,
}: SVGProps<SVGElement>): JSX.Element => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 1200 1200"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="m35.816 578.29c-4.0352 10.473-4.0352 22.109 0 32.582 87.055 226.73 306.95 387.82 564.18 387.82s477.13-161.09 564.18-387.82c4.0352-10.473 4.0352-22.109 0-32.582-87.055-226.73-306.95-387.86-564.18-387.86s-477.13 161.13-564.18 387.86zm91.453 16.289c78.035-184 260.4-313.24 472.73-313.24s394.69 129.24 472.73 313.2c-78.035 184.04-260.4 313.24-472.73 313.24s-394.69-129.2-472.73-313.16zm472.73-211.96c-112.66 0-204.14 91.492-204.14 204.14 0 112.66 91.492 204.14 204.14 204.14 112.66 0 204.14-91.492 204.14-204.14 0-112.66-91.492-204.14-204.14-204.14zm0 90.91c62.508 0 113.24 50.727 113.24 113.24 0 62.508-50.727 113.24-113.24 113.24-62.508 0-113.24-50.727-113.24-113.24 0-62.508 50.727-113.24 113.24-113.24z"
      fill={fill}
      fillRule="evenodd"
    />
  </svg>
);
