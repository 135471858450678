import { Icon } from '@components';
import { Box, IconButton } from '@material-ui/core';

import { getImageLibraryItemContent } from '@modules/ImageLibrary/ImageLibrary.thunk';
import { MODULE_MODAL_Y_OFFSET_CORREC_NUM } from '@routes/TemplateView';
import { useAppDispatch } from '@store';
import { variables } from '@styles';
import { useEffect, useState } from 'react';
import { ModuleModalProps } from './ImageModal.props';
import { useStyles } from './ImageModal.styles';
import { LOADING_IMAGE_PLACEHOLDER } from './ImageModal.const';

/**
 ImageModal component
 @returns {JSX.Element}
 */

export const ImageModal = ({ modalTopPosition, chosenImage, onClose }: ModuleModalProps): JSX.Element => {
  const styles = useStyles();
  const dispatch = useAppDispatch();
  const { id } = chosenImage;
  const [imageBase64, setImageBase64] = useState<string>();

  useEffect(() => {
    (async () => {
      if (id) {
        const response = await dispatch(getImageLibraryItemContent({ imageLibraryItem: id }));

        setImageBase64(`data:image/jpeg;base64, ${response.payload}`);
      }
    })();
  }, [id, setImageBase64, dispatch]);

  return (
    <Box
      style={{
        paddingTop: `calc(13.7rem + ${modalTopPosition - MODULE_MODAL_Y_OFFSET_CORREC_NUM}px)`,
        height: 'calc(150vh + 24.2rem)',
      }}
      className={styles.imageModalWrapper}
    >
      <Box className={styles.moduleContent}>
        <Box className={styles.imageWrapper}>
          <img
            id="editing-image"
            src={imageBase64 ?? LOADING_IMAGE_PLACEHOLDER}
            width={chosenImage.width ?? 0}
            height={chosenImage.height ?? 0}
            alt="image"
          />
        </Box>
        <IconButton id="module-modal-close" className={styles.closeBtn} onClick={onClose}>
          <Icon.CloseOutline stroke={variables.color.primary.white} />
        </IconButton>
      </Box>
    </Box>
  );
};
