import { makeStyles, createStyles } from '@material-ui/core/styles';

import { variables } from '@styles';

/**
 * ImageLibraryPanel component styles
 * @returns {Function}
 */

export const useStyles = makeStyles((theme) =>
  createStyles({
    imageLibraryPanel: {
      padding: theme.spacing(2),
      height: '100%',
    },

    header: {
      marginBottom: theme.spacing(2),
      display: 'flex',
    },

    search: {
      marginRight: theme.spacing(1),
      width: '100%',
      '& input': {
        height: '3.6rem',
      },
    },

    uploadBtn: {
      borderRadius: variables.borderRadius.sm,
      border: `0.1rem solid ${variables.color.primary.lightGrayDarker}`,
      padding: theme.spacing(1, 1),
      '&:hover': {
        border: `1px solid ${variables.color.primary.mainPurple}`,
      },
      '&:hover svg path': {
        fill: variables.color.primary.mainPurple,
      },
    },

    content: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(2),
      height: 'calc(100% - 4.9rem)',
      overflowY: 'auto',
      marginRight: theme.spacing(-1.25),
    },

    imageWrapper: {
      display: 'flex',
      flexDirection: 'column',
      cursor: 'pointer',
      border: `${variables.borderWidth.md} solid ${variables.color.primary.lightGray}`,
      borderRadius: variables.borderRadius.sm,

      '&[data-chosen=true]': {
        border: `2.5px solid ${variables.color.primary.mainPurple} !important`,
      },

      '&:hover': {
        border: `1.5px solid ${variables.color.primary.mainPurple} !important`,
      },
    },

    image: {
      width: '100%',
      height: '16.3rem',
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
      borderBottom: `${variables.borderWidth.md} solid ${variables.color.primary.lightGray}`,
      backgroundColor: variables.color.primary.backgroundLightestGray,
      backgroundPosition: '50%',
    },

    moduleRow: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },

    name: {
      marginBottom: theme.spacing(0.5),
    },

    imageContent: {
      padding: theme.spacing(1, 2, 2, 2),
    },

    index: {
      '&&': {
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        background: '#E8ECF0',
        borderRadius: '2px',
        width: 'fit-content',
        padding: '2px 5px',
        fontWeight: '700',
        fontSize: '9px',
        cursor: 'pointer',
      },
    },

    loaderBlock: {
      marginBottom: '20px',
    },
  }),
);
