import React, { useEffect, useState } from 'react';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import ToggleButton from '@material-ui/lab/ToggleButton';

import { ToggleButtonsProps } from './ToggleButtons.props';
import { useToggleButtonStyles } from './ToggleButtons.styles';

export const ToggleButtons: React.FC<ToggleButtonsProps> = ({
  ariaLabel,
  defaultValue,
  isEnabled,
  onChangeHandler,
  reset = false,
  toggleButtons,
}) => {
  const toggleButtonStyles = useToggleButtonStyles();
  const [value, setValue] = useState<string | null>(defaultValue);

  useEffect(() => {
    if (reset) {
      setValue(defaultValue);
      onChangeHandler(defaultValue);
    }
  }, [defaultValue, onChangeHandler, reset]);

  const onChange = (event: React.MouseEvent<HTMLElement>, newValue: string | null) => {
    if (newValue?.length) {
      setValue(newValue);

      if (newValue) {
        onChangeHandler(newValue);
      }
    }
  };

  return (
    <ToggleButtonGroup value={value} onChange={onChange} exclusive aria-label={ariaLabel ?? ''}>
      {toggleButtons.map((button, index) => {
        return (
          <ToggleButton
            key={button.value + index}
            value={button.value}
            aria-label={button.ariaLabel ?? ''}
            classes={toggleButtonStyles}
            disabled={!isEnabled}
          >
            {button.label && button.label + ' '}
            {button.iconImagePath && <img src={button.iconImagePath} alt={button.altText ?? ''} />}
            {button.iconSVG && button.iconSVG}
          </ToggleButton>
        );
      })}
    </ToggleButtonGroup>
  );
};

export default ToggleButtons;
