import { makeStyles, createStyles } from '@material-ui/core/styles';

import { variables } from '@styles';

/**
 * UniversalContainer component styles
 * @returns {Function}
 */

export const useStyles = makeStyles((theme) =>
  createStyles({
    universalContainer: {
      backgroundColor: variables.color.primary.white,

      '&[data-ui-type=primary]': {
        '& $item': {
          minWidth: '12.7rem',
          width: `calc((100% - ${theme.spacing(4)}px) / 3)`,
          margin: theme.spacing(0, 1.5, 2, 0),

          '&:nth-child(3n)': {
            marginRight: 0,
          },

          ['@media (min-width: 1024px) and (max-width: 1580px)']: {
            width: '100%',

            '& > div > .MuiButton-outlinedPrimary': {
              width: '100%',
            },

            '&:nth-child(4)': {
              width: '114px',
              minWidth: 'auto',
            },

            '&:nth-child(5)': {
              width: '110px',
              minWidth: 'auto',
              marginRight: '0',
            },
          },
        },

        '& $itemSecond': {
          minWidth: '12.7rem',
          width: `calc((100% - ${theme.spacing(3)}px) / 2)`,
          margin: theme.spacing(0, 1.5, 2, 0),

          '&:last-child': {
            marginRight: 0,
          },

          ['@media (min-width: 1024px) and (max-width: 1580px)']: {
            minWidth: 'auto',
          },
        },

        '& $caption': {
          marginBottom: theme.spacing(1),
        },

        '& $section': {
          display: 'flex',
          flexWrap: 'wrap',
        },
      },
    },
    titleWrapper: {
      padding: '2rem 2.5rem',
    },
    title: {
      fontSize: '16px!important',
      lineHeight: '20px!important',
      color: `${variables.color.primary.darkPurple}!important`,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },

    section: {},
    item: {},
    itemSecond: {},

    count: {
      '&&': {
        padding: theme.spacing(1),
        width: '4.5rem',

        '& p': {
          color: variables.color.primary.darkestGray,
        },
      },
    },

    countButton: {
      width: '3rem',
      padding: theme.spacing(1),
      color: variables.color.primary.mediumGray,
      borderColor: variables.color.primary.lightGrayDarker,
      fontSize: variables.font.size.xxl,

      '&.Mui-disabled': {
        backgroundColor: variables.color.primary.lightGrayDarker,
        borderColor: variables.color.primary.lightGrayDarker,
        color: variables.color.primary.mediumGray,
      },

      '&:hover': {
        borderColor: variables.color.primary.mainPurple,
        color: variables.color.primary.mainPurple,
        backgroundColor: 'transparent',
      },
    },

    toggleButton: {
      '&&': {
        width: '3.2rem',
        height: '3.2rem',
        padding: theme.spacing(0.75),
        borderRadius: variables.borderRadius.sm,
        marginRight: theme.spacing(1),
        border: `${variables.borderWidth.md} solid ${variables.color.primary.lightGrayDarker}`,
        transition: 'all 0.3s',

        '&:last-child': {
          marginRight: 0,
        },

        '&.Mui-selected': {
          backgroundColor: variables.color.primary.mainPurple,
          borderColor: variables.color.primary.mainPurple,

          '& svg path': {
            fill: variables.color.primary.white,
          },

          '& svg line': {
            stroke: variables.color.primary.white,
          },

          '&:hover': {
            backgroundColor: variables.color.primary.mainPurple,
            borderColor: variables.color.primary.mainPurple,

            '& svg path': {
              fill: variables.color.primary.white,
            },

            '& svg line': {
              stroke: variables.color.primary.white,
            },
          },
        },

        '&:hover': {
          borderColor: variables.color.primary.mainPurple,
          backgroundColor: 'transparent',

          '& svg path': {
            fill: variables.color.primary.mainPurple,
          },

          '& svg line': {
            stroke: variables.color.primary.mainPurple,
          },
        },
      },
    },

    caption: {
      color: variables.color.primary.darkestGray,
    },
  }),
);
