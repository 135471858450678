import { makeStyles, createStyles } from '@material-ui/core/styles';
import { variables } from '@styles';

/**
 * DVItem component styles
 * @returns {Function}
 */

export const useStyles = makeStyles((theme) =>
  createStyles({
    DVItem: {
      display: 'flex',
      gap: '8px',
      borderBottom: `solid 1px ${variables.color.primary.lightGray}`,
      marginBottom: '8px',
      paddingBottom: '5px',

      '&:last-child': {
        borderBottom: 'none',
      },
    },
    index: {
      display: 'flex',
      fontSize: '10px',
      alignItems: 'center',
      width: '20px',
      color: variables.color.primary.mediumGray,
    },
    label: {
      border: '1px solid #dadee2',
      padding: '0.8rem 0.8rem',
      position: 'relative',
      height: '35px',
      width: '224px', // '112px',
      borderRadius: '0.6rem',
      background: 'none',
      boxSizing: 'border-box',
      outline: 'none',

      '&::-webkit-input-placeholder': {
        color: variables.color.primary.halfMediumGray,
      },
      '&:-moz-placeholder': {
        color: variables.color.primary.halfMediumGray,
        opacity: 1,
      },
      '&::-moz-placeholder': {
        color: variables.color.primary.halfMediumGray,
        opacity: 1,
      },
      '&:-ms-input-placeholder': {
        color: variables.color.primary.halfMediumGray,
      },
      '&::-ms-input-placeholder': {
        color: variables.color.primary.halfMediumGray,
      },
      '&::placeholder': {
        color: variables.color.primary.halfMediumGray,
      },
    },
    removeBtn: {
      border: '1px solid #dadee2',
      padding: '0.85rem 0.8rem',
      position: 'relative',
      height: '35px',
      width: '35px',
      minWidth: '35px',
      borderRadius: '0.6rem',
      background: 'none',
      cursor: 'pointer',
      boxSizing: 'border-box',
    },
    emptyBox: {
      minWidth: '35px',
      height: '35px',
      width: '35px',
    },
  }),
);
