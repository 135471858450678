import { makeStyles, createStyles } from '@material-ui/core/styles';

import { variables, theme } from '@styles';

/**
 * Publish component styles
 * @returns {Function}
 */

export const useStyles = makeStyles(() =>
  createStyles({
    publish: {
      background: variables.color.primary.white,
      flex: '1 1 auto',
    },

    publishButton: {
      minWidth: '3.8rem',
      padding: '.8rem .8rem',

      '&:disabled': {
        background: variables.color.primary.backgroundLighterGray,
        border: `1px solid ${variables.color.primary.lightGrayDarker}`,
        color: variables.color.primary.lightGrayDarker,

        '& svg path': {
          fill: variables.color.primary.lightGrayDarker,
        },
      },
    },

    header: {
      margin: theme.spacing(0, 2),
      padding: theme.spacing(2, 0),
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      borderBottom: `${variables.borderWidth.sm} solid ${variables.color.primary.lightGray}`,
    },

    headerRight: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'self-end',
    },

    row: {
      display: 'flex',
      alignItems: 'center',
    },

    rowDirectionColumn: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },

    grid: {
      padding: theme.spacing(2),
    },

    gridLeft: {
      paddingLeft: theme.spacing(1),
    },

    gridCenter: {
      padding: theme.spacing(0, 1),
    },

    label: {
      padding: theme.spacing(1, 0),
      color: variables.color.primary.mediumGray,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },

    value: {
      padding: theme.spacing(1, 0),
      color: variables.color.primary.darkestGray,
      fontWeight: variables.font.weight.semiBold,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },

    gridRight: {
      textAlign: 'right',
    },

    panelTitleHeading: {
      marginBottom: theme.spacing(0.5),
    },

    downloadPublishContainer: {
      alignItems: 'self-end',
      display: 'flex',
      flexDirection: 'row',
      gap: '10px',
    },

    buttonContainer: {
      padding: theme.spacing(2),
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
    },

    change: {
      marginLeft: theme.spacing(0.5),
      color: variables.color.primary.mainPurple,
      textDecoration: 'underline',
      fontSize: variables.font.size.sm,
      fontWeight: variables.font.weight.semiBold,
      padding: theme.spacing(0.6, 0.5, 1),
      minWidth: 'unset',

      '&:hover': {
        backgroundColor: 'transparent',
      },

      '&:disabled': {
        opacity: 1,
      },
    },

    checkBtn: {
      minWidth: 'unset',
      transition: 'opacity 0.25s',
      padding: theme.spacing(1),
      marginLeft: theme.spacing(1),
      borderRadius: variables.borderRadius.sm,
      border: `${variables.borderWidth.sm} solid ${variables.color.primary.lightGrayDarker}`,

      '&:hover': {
        backgroundColor: 'transparent',
        opacity: '0.6',
      },
    },

    mailSelect: {
      maxWidth: '40rem',
      display: 'flex',
    },

    info: {
      color: variables.color.semantic.highlight,
    },

    warnings: {
      marginRight: theme.spacing(2),
      marginLeft: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },

    warning: {
      display: 'flex',

      '& svg': {
        marginRight: theme.spacing(0.5),
      },
    },

    alert: {
      margin: theme.spacing(2),
    },

    changeLink: {
      padding: theme.spacing(0, 0, 0.25),
      margin: 0,
      fontSize: variables.font.size.md,
      fontWeight: variables.font.weight.medium,
      lineHeight: variables.lineHeight.lg,
      whiteSpace: 'pre-wrap',
      color: variables.color.primary.mainPurple,
      textTransform: 'none',
      transition: 'all 0.3s',

      '&:hover': {
        backgroundColor: 'transparent',
        opacity: variables.opacity.high,
      },
    },

    downloadButton: {
      border: `1px solid ${variables.color.primary.lightGrayDarker}`,
      minWidth: '3.8rem',
      padding: theme.spacing(1, 1),
      width: '3.8rem',

      '&:hover': {
        border: `1px solid ${variables.color.primary.mainPurple}`,
      },
      '&:hover svg path': {
        fill: variables.color.primary.mainPurple,
      },
    },
  }),
);
