import { makeStyles, createStyles } from '@material-ui/core/styles';

import { variables } from '@styles';

const ROOT_TOP_PADDING = '2.7rem';
const HEADER_HEIGHT = '8.8rem';
const HEADER_MARGIN_BOTTOM = variables.gap.xl;
const RECOMMENDER_MARGIN_TOP = variables.gap.sm;
const SMALL_VIEW_WIDTH = '1530px';

const content = {
  height: `calc(100% - ${variables.topBar.height} - ${ROOT_TOP_PADDING} - ${HEADER_HEIGHT} - ${HEADER_MARGIN_BOTTOM} + ${RECOMMENDER_MARGIN_TOP})`,
  marginRight: variables.gap.sm,
};

export const useStyles = makeStyles(() =>
  createStyles({
    signalBuilder: {
      padding: `${ROOT_TOP_PADDING} 0 0`,
      minHeight: `calc(100vh - ${variables.topBar.height})`,
      display: 'flex',
      justifyContent: 'space-between',

      // '&[data-ui-type=treatment]': {
      //   [`@media (max-width: ${SMALL_VIEW_WIDTH})`]: {
      //     '& $content': {
      //       width: '20rem',
      //     },

      //     '& $contentLibrary': {
      //       width: 'calc(100% - 20rem)',
      //     },
      //   },
      // },
    },
    content: {
      marginRight: 0,
    },
    contentLibrary: {
      ...content,
      flex: 1,
      marginRight: 0,
    },
    title: {
      fontWeight: variables.font.weight.light,
    },
    searchField: {
      '& input': {
        fontSize: variables.font.size.sm,
        fontWeight: variables.font.weight.regular,
      },
      '& input::placeholder': {
        fontSize: variables.font.size.sm,
      },
    },
  }),
);
