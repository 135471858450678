import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

/**
 * DisclosureText component styles
 * @returns {Function}
 */

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    disclosureText: {
      textAlign: 'left',
      '& textarea': {
        height: '420px',
      },
    },

    editorWrapper: {
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },

    editorOverlay: {
      position: 'absolute',
      background: '#ffffff',
      opacity: '0.75',
      width: '100%',
      height: '100%',
      zIndex: 10,
    },

    joditEditor: {
      height: '400px',
      width: '236px',
      [theme.breakpoints.down(1581)]: {
        maxWidth: '236px !important',
      },
      [theme.breakpoints.up(1581)]: {
        maxWidth: '420px !important',
      },
    },
  }),
);
