import { useCallback, useState, ChangeEvent, useRef, useEffect, useMemo } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { Box, Button } from '@material-ui/core';

import { content, page } from '@content';
import { FullPageLoader, Icon, WarningMessage } from '@components';
import { useAppDispatch } from '@store';
import { Base } from '@layouts';
import { useHistory, useLoader, isRoleSystemAdmin, isRoleAdmin, isRoleEditor } from '@utils';
import {
  auth,
  businessUnitDeployments,
  businessUnitPrograms,
  deployment,
  template,
  enterprise,
  userClients,
} from '@modules';
import { variables } from '@styles';

import { SideBar, SideBarCallbacks } from './SideBar';
import { TopBar, TopBarProps, TopBarContext, TOP_BAR_CTX_VALUE } from './TopBar';
import { ViewportSizeContext, ViewportSize } from './Viewport';
import { NewDeployment, NewDeploymentProps } from './NewDeployment';
import { CreateProgram } from './CreateProgram';

import { usePagesAccess, useSideToggle } from './Private.hooks';
import { useStyles } from './Private.styles';
import { PrivateNavItemValue } from './Private.types';
import { PrivateProps } from './Private.props';
import { THUNK_BLOCKING_STATUSES } from './Private.const';
import { delay } from '@utils/func/func';

export const Private = ({ children, hideTopBar = false, ...route }: PrivateProps): JSX.Element => {
  const styles = useStyles();

  const history = useHistory();

  const { enterprise: enterpriseId, businessUnit: businessUnitId, deployment: deploymentId } = history.query;

  const dispatch = useAppDispatch();

  const { authenticating, authenticated, restricted, navItems } = usePagesAccess();

  const side = useSideToggle();

  const sideActive = useRef(false);

  const topBarCtx = useState(TOP_BAR_CTX_VALUE);

  const [, setTopBarCtxValue] = topBarCtx;

  const [newDep, setNewDep] = useState(false);

  const [newProg, setNewProg] = useState(false);

  const [showFtpWarning, setShowFtpWarning] = useState(false);

  const [viewportSize, setViewportSize] = useState<ViewportSize>(ViewportSize.Desktop);
  const [viewportIsEnabled, setViewportIsEnabled] = useState(false);

  const [warningDescription, setWarningDescription] = useState<string>('');

  const [actionLinks, setActionLinks] = useState<JSX.Element[] | null>(null);

  const enterprises = enterprise.useListData();

  const enterpriseListMeta = enterprise.useListMeta();

  const isAdmin = isRoleAdmin();

  const isSystemAdmin = isRoleSystemAdmin();

  const isEditor = isRoleEditor();

  const presetClient = auth.useLastClientUpdateData();

  const userClientsEntityMeta = userClients.useEntityMeta();
  const currentClientData = userClients.useEntityData();
  const [isCloneQuestionVisible, setIsCloneQuestionVisible] = useState(false);

  useLoader(enterpriseListMeta, userClientsEntityMeta, auth.useLastClientUpdateMeta());

  const updateLastUsedClient = useCallback(
    async (businessUnit: number) => {
      const result = await dispatch(auth.thunk.lastUsedClientUpdate({ businessUnit }));

      return auth.thunk.lastUsedClientUpdate.fulfilled.match(result);
    },
    [dispatch],
  );

  const handleTopBarDepChange = useCallback(
    (depId: number) => {
      setTopBarCtxValue((prev) => ({ ...prev, deploymentId: depId }));
      history.push('deployments', { deployment: depId });
    },
    [setTopBarCtxValue, history],
  );

  // TODO: remove after experiences functionality deletion
  // const handleTopBarExperienceChange = useCallback(
  //   (event: ChangeEvent<{ name?: string; value: unknown }>) => {
  //     const experienceId = Number(event.target.value);

  //     setTopBarCtxValue((prev) => ({ ...prev, experienceId }));

  //     history.push('experiences', { id: experienceId });
  //   },
  //   [setTopBarCtxValue, history],
  // );

  const handleTopBarBackClick = useCallback(() => {
    history.goBack();
  }, [history]);

  const handleTopBarClientChange = useCallback<TopBarProps['onClientChange']>(
    async (nextbusinessUnitId) => {
      setTopBarCtxValue((prev) => ({ ...prev, businessUnitId: nextbusinessUnitId }));

      if (await updateLastUsedClient(nextbusinessUnitId)) {
        history.push('', { businessUnit: nextbusinessUnitId });
      }
    },
    [setTopBarCtxValue, updateLastUsedClient, history],
  );

  const handleTopBarMailFileChange = useCallback(
    (event: ChangeEvent<{ name?: string; value: unknown }>) => {
      if (deploymentId) {
        const selectedMailFile = Number(event.target.value);

        dispatch(deployment.thunk.attachMailFile({ deploymentId, mailFileId: selectedMailFile }));

        setTopBarCtxValue((prev) => ({ ...prev, mailFileId: selectedMailFile }));

        history.push('deployments', { mailFile: selectedMailFile });
      }
    },
    [dispatch, setTopBarCtxValue, history, deploymentId],
  );

  const handleTopBarTemplateChange = useCallback(
    (event: ChangeEvent<{ name?: string; value: unknown }>) => {
      const selectedTemplate = Number(event.target.value);

      dispatch(template.thunk.selectTemplateToDeployment(selectedTemplate));

      setTopBarCtxValue((prev) => ({ ...prev, templateId: selectedTemplate }));

      history.push('deployments', { template: selectedTemplate });
    },
    [dispatch, setTopBarCtxValue, history],
  );

  const handleSideMouseLeave = useCallback(() => {
    if (!sideActive.current) {
      side.handleClose();
    }
  }, [side]);

  const historyPushPending = useRef({ businessUnit: businessUnitId, enterprise: enterpriseId });

  const handleSideBarClientChange = useCallback<SideBarCallbacks['onClientChange']>(
    async (nextEnterpriseId, nextbusinessUnitId) => {
      const { businessUnit: currbusinessUnitId, enterprise: currEnterpriseId } = historyPushPending.current;
      const enterpriseUpdated = nextEnterpriseId !== currEnterpriseId;
      const businessUnitUpdated = nextbusinessUnitId !== currbusinessUnitId;

      if (enterpriseUpdated || businessUnitUpdated) {
        historyPushPending.current = { businessUnit: nextbusinessUnitId, enterprise: nextEnterpriseId };
        const businessUnits = enterprises.find((item) => item.id === nextEnterpriseId)?.businessUnits || [];

        setTopBarCtxValue((prev) => ({
          ...prev,
          ...(prev.variant === 'picker' ? { businessUnits, businessUnitId: nextbusinessUnitId } : null),
        }));

        if (presetClient?.id === nextbusinessUnitId || (await updateLastUsedClient(nextbusinessUnitId))) {
          history.push('dashboard', {
            enterprise: nextEnterpriseId,
            businessUnit: nextbusinessUnitId,
            program: null,
            deployment: null,
            mailFile: null,
            module: null,
            template: null,
          });
        }
      }
    },
    [setTopBarCtxValue, updateLastUsedClient, presetClient, history, enterprises],
  );

  const handleSideBarEnterpriseToggle = useCallback<SideBarCallbacks['onEnterpriseToggle']>((open) => {
    sideActive.current = open;
  }, []);

  const handleSideBarItemClick = useCallback<SideBarCallbacks['onItemClick']>(
    (item) => {
      if (item.actn === 'logout') {
        localStorage.removeItem('sfConnectionInfo');

        dispatch(auth.thunk.logout());
      }
    },
    [dispatch],
  );

  const handleSideBarMenuClick = useCallback<SideBarCallbacks['onMenuClick']>(
    (item) => {
      let nextProgramId: string;

      if (item.value) {
        side.handleClose();
      }

      switch (item.value as PrivateNavItemValue) {
        case PrivateNavItemValue.newProgram:
          setNewProg(true);
          break;
        case PrivateNavItemValue.newDeployment:
          [nextProgramId] = (item.id as string).split('|');
          history.push('', { program: nextProgramId });
          setNewDep(true);

          (async () => {
            const businessUnitDeploymentsResp = await dispatch(businessUnitDeployments.thunk.getAll(businessUnitId!));
            const lastDeployment = [...(businessUnitDeploymentsResp.payload as any)]
              .filter((deploymentItem: any) => deploymentItem.program?.id === Number(nextProgramId))
              .reduce((curDeployment, curItem) => (curDeployment?.id > curItem?.id ? curDeployment : curItem), null);

            setIsCloneQuestionVisible(!!lastDeployment?.id);
          })();

          break;
        case PrivateNavItemValue.deployment:
          history.push('deployments', { deployment: item.id, template: null, mailFile: null });
          break;
      }
    },
    [setNewDep, setNewProg, setIsCloneQuestionVisible, history, side],
  );

  const handleSideBarMenuToggle = useCallback<SideBarCallbacks['onMenuToggle']>(
    (open) => {
      sideActive.current = open;

      if (open && businessUnitId) {
        dispatch(businessUnitPrograms.thunk.getAll(businessUnitId));
        dispatch(businessUnitDeployments.thunk.getAll(businessUnitId));
      }
    },
    [dispatch, businessUnitId],
  );

  const handleNewDeploymentCancel = useCallback(() => {
    setNewDep(false);
  }, [setNewDep]);

  const handleNewDeploymentSubmit: NewDeploymentProps['onSubmit'] = useCallback(
    async (payload) => {
      const { isCloning, uploadFile, mailFile, ...rest } = payload;
      let templateId = null;
      let curMailFile = mailFile;

      if (uploadFile) {
        const meta = { businessUnit: Number(businessUnitId) };
        const formData = new FormData();

        formData.append('mailFile', uploadFile! as Blob);

        const response = await dispatch(
          userClients.thunk.clientUploadMailFile({
            ...meta,
            body: formData,
          }),
        );

        curMailFile = { id: (response as any).payload.id };

        if (!userClients.thunk.clientUploadMailFile.fulfilled.match(response)) {
          return;
        }
      }

      const result = await dispatch(
        deployment.thunk.create({
          ...rest,
          mailFile: curMailFile,
          shouldCreateCopy: isCloning === 'true',
        }),
      );

      await delay(1500);

      if (result.payload && deployment.thunk.create.fulfilled.match(result)) {
        setNewDep(false);

        if (isCloning === 'true') {
          const businessUnitDeploymentsResp = await dispatch(businessUnitDeployments.thunk.getAll(businessUnitId!));
          const lastDeployment = [...(businessUnitDeploymentsResp.payload as any)]
            .filter((deploymentItem: any) => deploymentItem.program.id === payload.programId)
            .reduce((curDeployment, curItem) => (curDeployment.id > curItem.id ? curDeployment : curItem));
          const deploymentResp = await dispatch(deployment.thunk.getById(lastDeployment.id));
          const lastDeploymentInfo = deploymentResp.payload as any;

          templateId = lastDeploymentInfo?.currentEmailTemplate?.id;
        }

        await delay(2000);

        await dispatch(userClients.thunk.getById(businessUnitId!));

        history.push('deployments', {
          deployment: result.payload.id,
          mailFile: curMailFile?.id,
          template: templateId,
        });

        await delay(3000);

        dispatch(deployment.actions.changeDataMailLoadingStatus(true));
      }
    },
    [dispatch, setNewDep, history, businessUnitId],
  );

  const handleCreateProgramClose = useCallback(() => {
    setNewProg(false);
  }, [setNewProg]);

  useEffect(() => {
    let nextEnterpriseId = 0;
    let nextbusinessUnitId = 0;
    const isPresetClientActive =
      !!presetClient &&
      !!enterprises.length &&
      enterprises.some((ntps) => ntps.businessUnits.some((cli) => cli.id === presetClient.id && !cli.isArchived));

    if (enterprises.length && presetClient && isPresetClientActive) {
      const presetClientEnterprise = enterprises.find((ntps) =>
        ntps.businessUnits.find((cli) => cli.id === presetClient.id),
      );

      if (presetClientEnterprise) {
        nextEnterpriseId = presetClientEnterprise.id;
        nextbusinessUnitId = presetClient.id;
      }
    } else if (enterprises.length && !enterpriseId) {
      let firstEnterprise = enterprises.find((item) => item.businessUnits.length > 0);

      if (!firstEnterprise) {
        firstEnterprise = enterprises[0];
      }

      nextEnterpriseId = firstEnterprise.id;
    } else if (
      enterpriseId &&
      !businessUnitId &&
      enterprises.find((item) => item.id === enterpriseId)?.businessUnits?.length
    ) {
      nextEnterpriseId = enterpriseId;
    }

    if (nextEnterpriseId) {
      if (!nextbusinessUnitId) {
        const businessUnits = enterprises.find((item) => item.id === nextEnterpriseId)?.businessUnits || [];
        const [firstClient] = businessUnits;
        nextbusinessUnitId = firstClient?.id;
      }

      handleSideBarClientChange(nextEnterpriseId, nextbusinessUnitId);
    }
  }, [handleSideBarClientChange, presetClient, enterprises, enterpriseId, businessUnitId]);

  useEffect(() => {
    if (authenticated && !restricted) {
      if (!THUNK_BLOCKING_STATUSES.includes(enterpriseListMeta.status)) {
        dispatch(enterprise.thunk.getList());
      }

      const isClientNew = businessUnitId && currentClientData && currentClientData.id !== businessUnitId;
      const isClientNotFetched = businessUnitId && !currentClientData && userClientsEntityMeta.status !== 'loading';

      if (isClientNew || isClientNotFetched) {
        dispatch(userClients.thunk.getById(businessUnitId));
      }
    }

    return undefined;
  }, [
    dispatch,
    authenticated,
    restricted,
    businessUnitId,
    currentClientData,
    enterpriseListMeta.status,
    userClientsEntityMeta.status,
  ]);

  const handleCloseWarning = useCallback(() => {
    localStorage.setItem(
      'sfConnectionInfo',
      JSON.stringify({ businessUnitId: currentClientData?.id, warningClosed: true }),
    );

    setShowFtpWarning(false);
  }, [currentClientData]);

  const handleChangePageToSettings = useCallback(() => {
    const params = {
      enterprise: enterpriseId,
      businessUnit: businessUnitId,
      deployment: null,
      mailFile: null,
      template: null,
      program: null,
      id: businessUnitId,
    };

    handleCloseWarning();

    history.push('adminClients', params);
  }, [enterpriseId, businessUnitId, history, handleCloseWarning]);

  const getFtpWarning = useCallback(() => {
    setShowFtpWarning(true);

    if (isSystemAdmin || isAdmin) {
      setWarningDescription(content.updateFtpCredentialsAdmin);
      setActionLinks([
        <Button
          key="update"
          variant="contained"
          color="primary"
          className={styles.updateButton}
          endIcon={<Icon.ArrowRightOutline stroke={variables.color.primary.white} />}
          onClick={handleChangePageToSettings}
        >
          {content.update}
        </Button>,
        <Button key="remind" variant="outlined" onClick={handleCloseWarning}>
          {content.remindLater}
        </Button>,
      ]);
    } else if (isEditor) {
      setWarningDescription(content.updateFtpCredentialsEditor);
    }
  }, [handleCloseWarning, isSystemAdmin, isAdmin, isEditor, styles.updateButton]); // eslint-disable-line
  // react-hooks/exhaustive-deps

  useEffect(() => {
    if (currentClientData && !currentClientData?.isFTPCredentialsValid) {
      const infoFromLocalStorage = localStorage.getItem('sfConnectionInfo');

      if (infoFromLocalStorage) {
        const isPreviouslyClosed = JSON.parse(infoFromLocalStorage);

        if (
          isPreviouslyClosed.businessUnitId !== currentClientData.id ||
          (isPreviouslyClosed.businessUnitId === currentClientData.id && !isPreviouslyClosed.warningClosed)
        ) {
          getFtpWarning();
        }
      } else {
        getFtpWarning();
      }
    } else {
      setShowFtpWarning(false);
    }
  }, [currentClientData, getFtpWarning]);

  const viewportSizeContextValues = useMemo(
    () => ({
      viewportSize: viewportSize,
      setViewportSize: setViewportSize,
      isViewportSizeEnabled: viewportIsEnabled,
      setIsViewportSizeEnabled: setViewportIsEnabled,
    }),
    [viewportSize, viewportIsEnabled],
  );

  if (authenticating) {
    return <FullPageLoader blockRender />;
  }

  if (authenticated && restricted) {
    return <Redirect to={page.dashboard} />;
  }

  return authenticated && !restricted ? (
    <Route {...route}>
      <TopBarContext.Provider value={topBarCtx}>
        <Base className={styles.private}>
          {showFtpWarning && (
            <WarningMessage
              className={styles.warningMessage}
              message={content.salesforceFtpNotSet}
              description={warningDescription}
              onClose={isEditor ? handleCloseWarning : undefined}
              actionLinks={actionLinks}
            />
          )}
          {newDep && (
            <NewDeployment
              isCloneQuestionVisible={isCloneQuestionVisible}
              onCancel={handleNewDeploymentCancel}
              onSubmit={handleNewDeploymentSubmit}
            />
          )}
          {newProg && <CreateProgram onClose={handleCreateProgramClose} />}
          <Box className={styles.body}>
            <ViewportSizeContext.Provider value={viewportSizeContextValues}>
              <TopBar
                onBackClick={handleTopBarBackClick}
                onDeploymentChange={handleTopBarDepChange}
                // onExperienceChange={handleTopBarExperienceChange} // TODO: remove after experiences functionality deletion
                onClientChange={handleTopBarClientChange}
                onMailFileChange={handleTopBarMailFileChange}
                onTemplateChange={handleTopBarTemplateChange}
              />
              <Box data-hide-top-bar={hideTopBar} className={styles.content}>
                {children}
              </Box>
            </ViewportSizeContext.Provider>
          </Box>
          <Box className={styles.side} onMouseEnter={side.handleOpen} onMouseLeave={handleSideMouseLeave}>
            <Box className={styles.sidebar}>
              <SideBar
                expanded={side.open}
                items={navItems}
                enterprises={enterprises}
                enterpriseId={enterpriseId}
                businessUnitId={businessUnitId}
                onClientChange={handleSideBarClientChange}
                onEnterpriseToggle={handleSideBarEnterpriseToggle}
                onItemClick={handleSideBarItemClick}
                onMenuClick={handleSideBarMenuClick}
                onMenuToggle={handleSideBarMenuToggle}
              />
            </Box>
          </Box>
        </Base>
      </TopBarContext.Provider>
    </Route>
  ) : (
    <Redirect to={page.login} />
  );
};
