import { makeStyles, createStyles } from '@material-ui/core';

import { variables } from '@styles';

export const useStyles = makeStyles((theme) =>
  createStyles({
    moduleHeader: {
      position: 'sticky',
      display: 'flex',
      alignItems: 'center',
      zIndex: 2,
      top: 0,
      width: '100%',
      padding: theme.spacing(1, 2),
      background: '#f5f7f9',
    },

    save: {
      marginLeft: 'auto',
      '&:disabled': {
        backgroundColor: variables.color.primary.backgroundLighterGray,
        border: `0.1rem solid ${variables.color.primary.lightGrayDarker}`,
        color: variables.color.primary.lightGrayDarker,
      },
    },
  }),
);
