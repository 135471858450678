export enum ViewportSize {
  Desktop = 'desktop',
  Mobile = 'mobile',
}

export type ViewportSizeContextType = {
  viewportSize: ViewportSize;
  setViewportSize: (size: ViewportSize) => void;
  isViewportSizeEnabled: boolean;
  setIsViewportSizeEnabled: (isEnabled: boolean) => void;
};

export enum ViewportWidth {
  Desktop = '600px',
  Mobile = '375px',
}
