import { makeStyles, createStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) =>
  createStyles({
    loader: {
      width: '100%',
    },

    loaderImage: {
      position: 'relative',
      width: '100%',
      height: '164px',
      display: 'block',
      overflow: 'hidden',
      background: '#eee',
      borderRadius: '5px',
    },

    loaderData: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',

      '& span': {
        borderRadius: '3px',
        height: '20px',
        marginTop: '10px',
      },

      '& span:first-child': {
        borderRadius: '3px',
        height: '20px',
        marginTop: '16px',
      },
    },
    loaderElement: {
      position: 'relative',
      display: 'block',
      width: '100%',
      height: '100%',
      background: '#dadada',
      overflow: 'hidden',

      '&:after': {
        content: '""',
        position: 'absolute',
        width: '100%',
        height: '100%',
        background: 'linear-gradient(#dadada, #f2f2f2, #dadada)',
        transform: 'rotate(120deg)',
        animation: '$loading 2s infinite',
      },
    },

    '@keyframes loading': {
      '0%': {
        left: '-100%',
      },

      '100%': {
        left: '100%',
      },
    },
  }),
);
