import { makeStyles, createStyles } from '@material-ui/core/styles';
import { variables } from '@styles';

export const useToggleButtonStyles = makeStyles(() =>
  createStyles({
    root: {
      backgroundColor: variables.color.primary.backgroundLighterGray,
      borderColor: variables.color.primary.lightGrayDarker,
      color: variables.color.primary.white,
      padding: '.8rem .8rem',

      '& svg path': {
        fill: variables.color.primary.lightGrayDarker,
      },

      '&:hover': {
        backgroundColor: variables.color.primary.backgroundLighterGray,
        borderColor: variables.color.primary.mainPurple,

        '& svg path': {
          fill: variables.color.primary.mainPurple,
        },
      },
    },
    selected: {
      backgroundColor: variables.color.primary.mainPurple,
      borderColor: variables.color.primary.darkestGray,
      '& svg path': {
        fill: variables.color.primary.white,
      },
      '&:hover': {
        backgroundColor: variables.color.primary.white,
        '& svg path': {
          fill: variables.color.primary.white,
        },
      },
      '&.MuiToggleButton-root.Mui-selected': {
        backgroundColor: variables.color.primary.mainPurple,
      },
    },
    disabled: {
      backgroundColor: variables.color.primary.backgroundLighterGray,
      borderColor: variables.color.primary.lightGrayDarker,

      '&.MuiToggleButton-root.Mui-selected': {
        backgroundColor: variables.color.primary.halfMediumGray + ' !important',
      },
    },
  }),
);
