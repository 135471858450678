import { SVGProps } from 'react';

export const Upload = ({ width = 24, height = 24, fill = '#ffffff', className }: SVGProps<SVGElement>): JSX.Element => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 1200 1200"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="m121.42259,841.8017c0,-32.96497 -26.75263,-59.71875 -59.71878,-59.71875s-59.71883,26.75262 -59.71883,59.71875l0,179.09912c0,98.89618 80.20296,179.09918 179.09911,179.09918l835.8318,0c98.89612,0 179.09912,-80.203 179.09912,-179.09918l0,-179.09912c0,-32.96497 -26.75256,-59.71875 -59.71875,-59.71875s-59.71875,26.75262 -59.71875,59.71875l0,179.09912c0,32.9649 -26.69666,59.66254 -59.66284,59.66254l-835.83174,0c-32.965,0 -59.66271,-26.69641 -59.66271,-59.66254l0.00237,-179.09912z"
      fill={fill}
    />
    <path
      d="m999.39044,460.11783c-23.2829,23.33899 -61.11816,23.33899 -84.40106,0l-256.27893,-256.27892l0,631.99312c0,32.96497 -26.75262,59.66266 -59.71881,59.66266c-32.96497,0 -59.71881,-26.69653 -59.71881,-59.66266l0,-631.99312l-256.2789,256.27892c-23.2829,23.33899 -61.11818,23.33899 -84.40109,0c-23.33897,-23.28284 -23.33897,-61.11813 0,-84.40106l358.19836,-358.19825c5.82062,-5.87673 12.59296,-10.24221 19.8692,-13.15305c6.82825,-2.79848 14.32788,-4.3096 22.16296,-4.36547l0.16785,0c15.55884,0 29.71967,5.9886 40.35345,15.72729c0.67157,0.61565 1.39917,1.28724 2.0708,1.95886l357.97144,358.03107c23.33905,23.28293 23.33905,61.11823 0,84.40106l0.00354,-0.00046z"
      fill={fill}
    />
  </svg>
);
