/**
 SampleTemplate component variables
 @returns {variables}
 */

export const DEFAULT_HIGHLIGHTED_ELEMS = [
  {
    id: '',
    event: null,
  },
];

export const HIGHLIGHTED_ELEM_BORDER_WIDTH = '2';
