import { useCallback, useState, useEffect } from 'react';
import { Box, Button, IconButton } from '@material-ui/core';

import { variables } from '@styles';
import { Typography, Accordion, TextField, Icon, TextFieldCallbacks } from '@components';
import { content } from '@content';
import { Libraries } from '@routes';

import { ColorPicker, ColorPickerProps } from '../ColorPicker';

import { DivContainerProps, DivContainerState } from './DivContainer.props';
import { useStyles } from './DivContainer.styles';
import { EditMode } from '../EditMode';
import { rgbToHex } from '@utils';

/**
 DivContainer component.
 @returns {JSX.Element}
 */

export const DivContainer = ({
  uiType = 'primary',
  initialValues,
  chosenImage,
  isModuleChanged,
  setIsModuleChanged,
  onChange,
  setChosenLibrary,
}: DivContainerProps) => {
  const styles = useStyles();
  const [state, setState] = useState<DivContainerState>(null);
  const [expanded, setExpanded] = useState(false);
  const [showDelete, setShowDelete] = useState(false);

  useEffect(() => {
    let backgroundColor = initialValues?.backgroundColor ?? '';

    if (backgroundColor.includes('rgb')) {
      const numbers = backgroundColor
        .replace('rgb(', '')
        .replace(')', '')
        .split(', ')
        .map((number) => Number(number));

      backgroundColor = rgbToHex(numbers[0], numbers[1], numbers[2]);
    }
    if (initialValues) setState((prevState) => ({ ...prevState, ...initialValues, backgroundColor }));
  }, [JSON.stringify(initialValues)]);

  useEffect(() => onChange(state), [state, onChange]);

  const handleBackgroundColorChange = useCallback<ColorPickerProps['onColorChange']>(
    (backgroundColor) => {
      setState({ backgroundColor, imageSrc: 'none' });
      setIsModuleChanged(true);
    },
    [setState, setIsModuleChanged],
  );

  const handleSelectImage = useCallback(() => {
    setChosenLibrary(Libraries.IMAGES);
  }, [setChosenLibrary]);

  const handleImageChange = useCallback<TextFieldCallbacks['onChange']>(
    (imageSrc) => {
      setState({ imageSrc, backgroundColor: 'unset' });
      setIsModuleChanged(true);
    },
    [setState, setIsModuleChanged],
  );

  const handleDeleteImage = useCallback(() => {
    setState((prevState) => ({
      ...prevState,
      imageSrc: 'none',
    }));
    setIsModuleChanged(true);
  }, [setState, setIsModuleChanged]);

  const toggleAccordion = useCallback(() => setExpanded((prevState) => !prevState), [setExpanded]);

  useEffect(() => {
    setState((prevState) => ({ ...prevState, imageSrc: chosenImage.imageSrc || '' }));
  }, [chosenImage]);

  useEffect(() => {
    setShowDelete(!!state?.imageSrc && state?.imageSrc !== 'none');
  }, [state?.imageSrc, setShowDelete]);

  return (
    <Box className={styles.divContainer} data-ui-type={uiType}>
      <Box className={styles.titleWrapper}>
        <Typography.Title className={styles.title}>{content.divContainer}</Typography.Title>
        <EditMode />
      </Box>
      <Accordion expanded={expanded} onChange={toggleAccordion} title={content.styling} uiType="quaternary">
        <Box className={styles.topSection}>
          <Typography.Caption className={styles.caption}>{content.backgroundImage}</Typography.Caption>
          <Box className={styles.imageSrcWrapper}>
            <TextField
              className={styles.imageSrc}
              size="small"
              direction="vertical"
              placeholder={content.none}
              value={state?.imageSrc === 'none' ? '' : state?.imageSrc}
              onChange={handleImageChange}
            />
            <Button className={styles.selectBtn} variant="outlined" onClick={handleSelectImage}>
              {content[state?.imageSrc && state.imageSrc !== 'none' ? 'change' : 'select']}
            </Button>
            {showDelete && (
              <IconButton className={styles.deleteButton} onClick={handleDeleteImage}>
                <Icon.TrashOutline />
              </IconButton>
            )}
          </Box>
        </Box>
        <Box>
          <Typography.Caption className={styles.caption}>{content.backgroundColor}</Typography.Caption>
          <ColorPicker
            position="left"
            color={state?.backgroundColor ?? variables.color.primary.white}
            onColorChange={handleBackgroundColorChange}
          />
        </Box>
      </Accordion>
    </Box>
  );
};
