import { SVGProps } from 'react';

export const Desktop = ({
  width = 24,
  height = 24,
  fill = '#ffffff',
  className,
}: SVGProps<SVGElement>): JSX.Element => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 1400 1200"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      fill={fill}
      d="m1255.04834,968.66321l-1110.09668,0c-79.78116,0 -144.6311,-64.84998 -144.6311,-144.63116l0,-675.59813c0,-79.78114 64.84995,-144.63112 144.6311,-144.63112l1110.09668,0c79.78125,0 144.6311,64.84998 144.6311,144.63112l0,675.59813c0,79.78119 -64.84985,144.63116 -144.6311,144.63116zm-1110.09668,-893.11697c-40.1869,0 -72.87177,32.68486 -72.87177,72.87182l0,675.59811c0,40.18695 32.68486,72.8717 72.87177,72.8717l1110.09668,0c40.18713,0 72.87183,-32.68475 72.87183,-72.8717l0,-675.59811c0,-40.18696 -32.68469,-72.87182 -72.87183,-72.87182l-1110.09668,0z"
    />
    <path
      fill={fill}
      d="m1255.04834,968.66321l-1110.09668,0c-79.78116,0 -144.6311,-64.84998 -144.6311,-144.63116l0,-77.47858c0,-19.83441 16.04527,-35.8797 35.87967,-35.8797l1327.58383,0c19.83423,0 35.87952,16.04529 35.87952,35.8797l0,77.47858c0,79.78119 -64.84985,144.63116 -144.6311,144.63116l0.01587,0zm-1182.96208,-186.23645l0,41.59888c0,40.18683 32.68486,72.87177 72.87177,72.87177l1110.09679,0c40.18677,0 72.8717,-32.68494 72.8717,-72.87177l0,-41.59888l-1255.84026,0z"
    />
    <path
      fill={fill}
      d="m841.35712,1192.77441l-282.7934,0c-19.83435,0 -35.8797,-16.04553 -35.8797,-35.88l0,-224.11102c0,-19.83435 16.04535,-35.87964 35.8797,-35.87964l282.7934,0c19.83441,0 35.87964,16.04529 35.87964,35.87964l0,224.11102c0,19.83447 -16.04523,35.88 -35.87964,35.88zm-246.83612,-71.75781l210.97394,0l0,-152.35663l-210.97394,0l0,152.35663z"
    />
    <path
      fill={fill}
      d="m953.4444,1196.19727l-506.9046,0c-19.83429,0 -35.87964,-16.04541 -35.87964,-35.87964s16.04535,-35.87976 35.87964,-35.87976l506.9046,0c19.83435,0 35.87964,16.04529 35.87964,35.87976s-16.04529,35.87964 -35.87964,35.87964z"
    />
  </svg>
);
