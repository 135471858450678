import { useCallback, useEffect, useState, MouseEvent, useRef, useContext } from 'react';
import { Box, Button, ButtonGroup, FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
import { ToggleButtonGroup, ToggleButton } from '@material-ui/lab';
import isEqual from 'lodash.isequal';

import { TextField, Typography, Accordion, Select, Icon } from '@components';
import { content } from '@content';
import { textTemplate, format, rgbToHex } from '@utils';
import { variables } from '@styles';

import { ColorPicker } from '../ColorPicker';

import {
  UniversalContainerProps,
  UniversalContainerState,
  FontCaseOptions,
  FontFamilyOptions,
  FontStyles,
  ParamTypes,
  NumberFormatOptions,
  DateFormatOptions,
} from './UniversalContainer.props';
import { useStyles } from './UniversalContainer.styles';
import { getTitle, getOptionValueById, getOptionIdByValue } from './UniversalContainer.utils';
import {
  FONT_CASE_OPTIONS,
  FONT_FAMILY_OPTIONS,
  NUMBER_FORMAT_OPTIONS,
  DATE_FORMAT_OPTIONS,
  DEFAULT_VALUES,
} from './UniversalContainer.const';
import AddSignalDropdown from './AddSignalDropdown';
import { TreatmentBuilderContext } from '@routes';
import { EditMode } from '../EditMode';

/**
 UniversalContainer component.
 @returns {JSX.Element}
 */

export const UniversalContainer = ({
  containerType = 'text',
  uiType = 'primary',
  initialValues,
  onChange,
  isModuleChanged,
  setIsModuleChanged,
  tokenFormat,
  date,
  onLinkChange,
  onAltTextChange,
}: UniversalContainerProps) => {
  const styles = useStyles();

  const [state, setState] = useState<UniversalContainerState | undefined>({ ...DEFAULT_VALUES, ...initialValues });
  const [expanded, setExpanded] = useState<boolean[]>([]);
  const { elemInputActive } = useContext(TreatmentBuilderContext);

  const updateState = useCallback(
    (updates: UniversalContainerState | undefined) => {
      const nextState = updates ? { ...state, ...updates } : undefined;

      setState(nextState);

      if (nextState) {
        onChange(nextState);
      }

      setIsModuleChanged(true);
    },
    [onChange, setState, setIsModuleChanged, state],
  );

  useEffect(() => {
    if (containerType === 'dataAttribute') {
      setExpanded([false, false, false]);
    } else {
      setExpanded([false, false]);
    }
  }, [containerType]);

  const initialValuesPrev = useRef(initialValues);

  useEffect(() => {
    let buttonBgColor = initialValues?.buttonBgColor ?? '';
    let fontColor = initialValues?.fontColor ?? '';

    if (buttonBgColor.includes('rgb')) {
      const numbers = buttonBgColor
        .replace('rgb(', '')
        .replace(')', '')
        .split(', ')
        .map((number) => Number(number));

      buttonBgColor = rgbToHex(numbers[0], numbers[1], numbers[2]);
    }

    if (fontColor.includes('rgb')) {
      const numbers = fontColor
        .replace('rgb(', '')
        .replace(')', '')
        .split(', ')
        .map((number) => Number(number));

      fontColor = rgbToHex(numbers[0], numbers[1], numbers[2]);
    }

    if (
      (initialValues && !isEqual(initialValuesPrev.current, initialValues)) ||
      !initialValues?.buttonBgColor?.includes('#') ||
      !initialValues?.fontColor?.includes('#')
    ) {
      initialValuesPrev.current = initialValues;

      setState({ ...DEFAULT_VALUES, ...initialValues, buttonBgColor, fontColor });
    }
  }, [setState, initialValues]);

  const handleParamChange = useCallback(
    (event, param: ParamTypes) => {
      switch (param) {
        case 'increment':
        case 'decrement':
          if (state && state.fontSize !== undefined) {
            updateState({
              fontSize: param === 'increment' ? state.fontSize + 1 : state.fontSize - 1,
            });
          } else {
            updateState(undefined);
          }
          break;
        case 'fontFamily':
        case 'fontCase':
        case 'tokenNumberFormat':
        case 'tokenDateFormat': {
          let options: FontCaseOptions | FontFamilyOptions | NumberFormatOptions | DateFormatOptions =
            FONT_FAMILY_OPTIONS;
          if (param === 'fontCase') options = FONT_CASE_OPTIONS;
          if (param === 'tokenNumberFormat') options = NUMBER_FORMAT_OPTIONS;
          if (param === 'tokenDateFormat') options = DATE_FORMAT_OPTIONS;

          updateState({
            [param]: getOptionValueById(options, event),
          });
          break;
        }
        case 'fontColor':
        case 'buttonBgColor':
          updateState({ [param]: event });
          break;
        case 'textAlign':
          updateState({ [param]: event.currentTarget.value });
          break;
      }
    },
    [updateState, state],
  );

  const handleFontFormat = useCallback(
    (event: MouseEvent<HTMLElement>, newFormats: FontStyles) => {
      updateState({ fontStyle: newFormats });
    },
    [updateState],
  );

  const handleLinkChange = (value: string) => {
    if (onLinkChange) {
      onLinkChange(value);
    }

    setState((prevState) => ({ ...prevState, link: value }));
    setIsModuleChanged(true);
  };

  const handleAltTextChange = (value: string) => {
    if (onAltTextChange) {
      onAltTextChange(value);
    }

    setState((prevState) => ({ ...prevState, altText: value }));
    setIsModuleChanged(true);
  };

  const toggleAccordion = useCallback(
    (index: number) =>
      setExpanded((prevState) =>
        prevState.map((item, ind) => {
          if (ind === index) return !item;

          return item;
        }),
      ),
    [setExpanded],
  );

  return (
    <Box className={styles.universalContainer} data-ui-type={uiType}>
      <Box className={styles.titleWrapper}>
        <Typography.Title className={styles.title}>{getTitle(containerType)}</Typography.Title>
        <EditMode />
      </Box>
      <Accordion
        expanded={expanded[0] === true}
        onChange={() => toggleAccordion(0)}
        title={content.styling}
        uiType="quaternary"
      >
        <Box className={styles.section}>
          <Box className={styles.item}>
            <Typography.Caption className={styles.caption}>{content.fontFamily}</Typography.Caption>
            <Select
              options={FONT_FAMILY_OPTIONS}
              placeholder={textTemplate(content.selectValue, { value: content.fontFamily.toLowerCase() })}
              value={getOptionIdByValue(FONT_FAMILY_OPTIONS, state, 'fontFamily') || ''}
              onChange={(event: any) => handleParamChange(event, 'fontFamily')}
            />
          </Box>
          <Box className={styles.item}>
            <Typography.Caption className={styles.caption}>{content.size}</Typography.Caption>
            <ButtonGroup size="large" aria-label="large outlined button group">
              <Button
                className={styles.countButton}
                disabled={state?.fontSize !== undefined && state?.fontSize < 1}
                onClick={(event) => handleParamChange(event, 'decrement')}
              >
                -
              </Button>
              <Button className={styles.count} disabled>
                <Typography.Caption>{state?.fontSize}</Typography.Caption>
              </Button>
              <Button className={styles.countButton} onClick={(event) => handleParamChange(event, 'increment')}>
                +
              </Button>
            </ButtonGroup>
          </Box>
          <Box className={styles.item}>
            <Typography.Caption className={styles.caption}>{content.color}</Typography.Caption>
            <ColorPicker
              position={'right'}
              color={state?.fontColor ? state.fontColor : variables.color.primary.white}
              onColorChange={(newColor) => handleParamChange(newColor, 'fontColor')}
            />
          </Box>
          <Box className={styles.item}>
            <Typography.Caption className={styles.caption}>{content.style}</Typography.Caption>
            <ToggleButtonGroup value={state?.fontStyle || ''} onChange={handleFontFormat} aria-label="text formatting">
              <ToggleButton className={styles.toggleButton} value="bold" aria-label="bold">
                <Icon.BoldV2 />
              </ToggleButton>
              <ToggleButton className={styles.toggleButton} value="italic" aria-label="italic">
                <Icon.ItalicV2 />
              </ToggleButton>
              <ToggleButton className={styles.toggleButton} value="underlined" aria-label="underlined">
                <Icon.Underline />
              </ToggleButton>
            </ToggleButtonGroup>
          </Box>
          <Box className={styles.item}>
            <Typography.Caption className={styles.caption}>{content.align}</Typography.Caption>
            <ToggleButtonGroup
              value={state && state.textAlign ? state.textAlign : 'left'}
              exclusive
              onChange={(event) => handleParamChange(event, 'textAlign')}
              aria-label="text alignment"
            >
              <ToggleButton className={styles.toggleButton} value="left" aria-label="left aligned">
                <Icon.AlignLeft />
              </ToggleButton>
              <ToggleButton className={styles.toggleButton} value="center" aria-label="centered">
                <Icon.AlignCenter />
              </ToggleButton>
              <ToggleButton className={styles.toggleButton} value="right" aria-label="right aligned">
                <Icon.AlignRight />
              </ToggleButton>
            </ToggleButtonGroup>
          </Box>
          <Box className={styles.item}>
            <Typography.Caption className={styles.caption}>{content.case}</Typography.Caption>
            <Select
              options={FONT_CASE_OPTIONS}
              placeholder={textTemplate(content.selectValue, { value: content.case.toLowerCase() })}
              value={getOptionIdByValue(FONT_CASE_OPTIONS, state, 'fontCase') || ''}
              onChange={(event: any) => handleParamChange(event, 'fontCase')}
            />
          </Box>
          {containerType === 'button' && (
            <Box className={styles.item}>
              <Typography.Caption className={styles.caption}>{content.backgroundColor}</Typography.Caption>
              <ColorPicker
                color={state?.buttonBgColor ? state.buttonBgColor : variables.color.primary.white}
                onColorChange={(newColor) => handleParamChange(newColor, 'buttonBgColor')}
              />
            </Box>
          )}
        </Box>
      </Accordion>
      {elemInputActive === 'enabled' ? (
        <Accordion
          expanded={!expanded[3]}
          onChange={() => toggleAccordion(3)}
          title={content.personalizationTokens}
          uiType="quaternary"
        >
          <AddSignalDropdown />
        </Accordion>
      ) : (
        <Accordion
          expanded={expanded[1] === true}
          onChange={() => toggleAccordion(1)}
          title={content.linkSettings}
          uiType="quaternary"
        >
          <Box className={styles.section}>
            <Box className={styles.itemSecond}>
              <Typography.Caption className={styles.caption}>{content.linkUrl}</Typography.Caption>
              <TextField
                size="small"
                direction="vertical"
                placeholder={textTemplate(content.enterValue, { value: content.linkAddress.toLowerCase() })}
                value={state?.link && state.link}
                onChange={handleLinkChange}
              />
            </Box>
            <Box className={styles.itemSecond}>
              <Typography.Caption className={styles.caption}>{content.altText}</Typography.Caption>
              <TextField
                size="small"
                direction="vertical"
                placeholder={textTemplate(content.enterValue, { value: content.altText.toLowerCase() })}
                value={state?.altText && state.altText}
                onChange={handleAltTextChange}
              />
            </Box>
          </Box>
        </Accordion>
      )}
      {containerType === 'dataAttribute' &&
        (tokenFormat === 'numberFormat' || (tokenFormat === 'dateFormat' && date)) && (
          <Accordion
            expanded={expanded[2] === true}
            onChange={() => toggleAccordion(2)}
            title={content.dataFormatting}
            uiType="quaternary"
          >
            <Box className={styles.section}>
              {tokenFormat === 'dateFormat' && date && (
                <Box className={styles.itemSecond}>
                  <Typography.Caption className={styles.caption}>{content.dateFormat}</Typography.Caption>
                  <Select
                    placeholder={textTemplate(content.selectValue, { value: content.dateFormat.toLowerCase() })}
                    options={DATE_FORMAT_OPTIONS.map((option) => {
                      if (date) {
                        return {
                          ...option,
                          label: format.date(date, option.value),
                        };
                      }

                      return option;
                    })}
                    value={getOptionIdByValue(DATE_FORMAT_OPTIONS, state, 'tokenDateFormat') || ''}
                    onChange={(event: any) => handleParamChange(event, 'tokenDateFormat')}
                  />
                </Box>
              )}
              {tokenFormat === 'numberFormat' && (
                <Box className={styles.itemSecond}>
                  <Typography.Caption className={styles.caption}>{content.numberFormat}</Typography.Caption>
                  <Select
                    options={NUMBER_FORMAT_OPTIONS}
                    value={
                      getOptionIdByValue(NUMBER_FORMAT_OPTIONS, state, 'tokenNumberFormat') ||
                      NUMBER_FORMAT_OPTIONS[0].id
                    }
                    onChange={(event: any) => handleParamChange(event, 'tokenNumberFormat')}
                  />
                </Box>
              )}
            </Box>
          </Accordion>
        )}
    </Box>
  );
};
