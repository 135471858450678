import { createStyles, makeStyles } from '@material-ui/core/styles';

import { variables } from '@styles';

export const useStyles = makeStyles((theme) =>
  createStyles({
    moduleCard: {
      padding: theme.spacing(2),

      '&[data-variant=draggableV2]': {
        padding: 0,
        border: `${variables.borderWidth.md} solid ${variables.color.primary.lightGray}`,
        borderRadius: variables.borderRadius.sm,
        cursor: 'grab',

        '& $coverImage': {
          borderBottom: `${variables.borderWidth.md} solid ${variables.color.primary.lightGray}`,
          borderRadius: 'unset',
          padding: 0,
        },

        '&.dragging-source': {
          //'& $coverImage, & $info, & $action': {
          '& $coverImage, & $action': {
            opacity: 0.5,
          },
        },

        '&.dragging': {
          borderColor: variables.color.primary.mainPurple,
          borderWidth: variables.borderWidth.lg,
        },
      },
    },

    coverImageWrapper: {
      position: 'relative',
      // height: '19.5rem',
      overflow: 'hidden',
      borderRadius: variables.borderRadius.sm,
      // background: variables.color.primary.backgroundLightGray,
    },

    coverImage: {
      width: '100%',
      height: 'auto',
      // position: 'absolute',
    },

    content: {
      padding: theme.spacing(1, 2, 2, 2),
    },

    name: {
      marginBottom: theme.spacing(0.5),
    },

    contentValue: {
      fontWeight: 'bold',
      color: variables.color.primary.black,
    },

    deploymentName: {
      width: '40%',
      textAlign: 'right',
    },

    moduleRow: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },

    index: {
      '&&': {
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        background: variables.color.primary.lightGray,
        borderRadius: '2px',
        width: 'fit-content',
        padding: '2px 5px',
        fontWeight: '700',
        fontSize: '9px',
        cursor: 'pointer',
      },
    },

    dark: {
      color: variables.color.primary.darkestGray,
    },

    tag: {
      display: 'inline-block',
      verticalAlign: 'middle',
      marginRight: theme.spacing(1),
      maxWidth: '7.6rem',
    },

    action: {
      display: 'flex',
      alignItems: 'center',
      flex: '0 0 auto',
    },

    actionBtnDrag: {
      padding: theme.spacing(1),
    },

    actionBtnDragV2: {
      padding: theme.spacing(1),
      cursor: 'grab',
    },

    actionBtnResize: {
      padding: theme.spacing(0.5, 1),
    },
  }),
);
