import { makeStyles, createStyles } from '@material-ui/core/styles';

import { variables } from '@styles';

/**
 * ModuleLibrary component styles
 * @returns {Function}
 */

export const useStyles = makeStyles((theme) =>
  createStyles({
    moduleLibrary: {
      backgroundColor: variables.color.primary.white,
    },

    tabsWrapper: {
      position: 'relative',
      background: variables.color.primary.backgroundLightestGray,

      '&:before': {
        content: '""',
        position: 'absolute',
        zIndex: 0,
        width: '100%',
        height: variables.borderWidth.md,
        background: variables.color.primary.lightGray,
        left: 0,
        bottom: 0,
      },
    },

    tabs: {
      display: 'inline-flex',
      minHeight: 'unset',
    },

    tab: {
      minHeight: 'unset',
      textTransform: 'none',
      fontSize: variables.font.size.xs,
      fontWeight: variables.font.weight.medium,
      lineHeight: variables.lineHeight.md,
      color: variables.color.primary.mediumGray,
      borderRight: `${variables.borderWidth.md} solid ${variables.color.primary.lightGray}`,
      padding: '11px 10px',
    },

    selectedTab: {
      overflow: 'visible',
      background: variables.color.primary.white,
      textTransform: 'none',
    },

    tabWrapper: {
      flexDirection: 'row',

      '&&': {
        '& > *:first-child': {
          marginRight: theme.spacing(1),
          marginBottom: '0',
        },
      },
    },

    content: {
      padding: theme.spacing(1, 1.5, 2, 2),
      height: '100%', //'calc(100% - 9.3rem)',
    },
  }),
);
