import { SVGProps } from 'react';

export const Add = ({ width = 24, height = 24, fill = '#ffffff', className }: SVGProps<SVGElement>): JSX.Element => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 1200 1200"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="m600 0c-331.2 0-600 268.8-600 600s268.8 600 600 600 600-268.8 600-600-268.8-600-600-600zm0 1104c-278.4 0-504-225.6-504-504s225.6-504 504-504 504 225.6 504 504-225.6 504-504 504z"
      fill={fill}
    />
    <path
      d="m840 552h-192v-192c0-26.398-21.602-48-48-48s-48 21.602-48 48v192h-192c-26.398 0-48 21.602-48 48s21.602 48 48 48h192v192c0 26.398 21.602 48 48 48s48-21.602 48-48v-192h192c26.398 0 48-21.602 48-48s-21.602-48-48-48z"
      fill={fill}
    />
  </svg>
);
