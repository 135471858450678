import { Box } from '@material-ui/core';

import { ContentModuleCard, Loader, Panel } from '@components';
import { dataVisualization, imageLibrary, modules } from '@modules';
import { useScrollableContainer } from '@utils';

import { content } from '@content';
import { DVEditor } from '@routes/ContentCenter/DVEditor';
import { ImageEditor } from '@routes/ContentCenter/ImageEditor';
import { TemplateEditor } from '@routes/ContentCenter/TemplateEditor';
import { useEffect, useState, useCallback, Fragment } from 'react';
import { ContentLibraryProps } from './ContentLibrary.props';
import { useStyles } from './ContentLibrary.styles';
import LoadingBlockPlaceholder from '@components/LoadingBlockPlaceholder/LoadingBlockPlaceholder';

/**
 ContentLibrary view component.
 @returns {JSX.Element}
 */

export const ContentLibrary = ({
  type = 'fourGrid',
  activeModuleId,
  modulesList,
  currentPage,
  pageCount,
  chosenImage,
  isImageEditorVisible,
  isTemplateEditorVisible,
  isDVEditorVisible,
  curModule,
  query,
  chosenDV,
  setChosenDV,
  onTemplateView,
  onPageChange,
  setChosenImage,
  setIsImageEditorVisible,
  setIsTemplateEditorVisible,
  setIsDVEditorVisible,
}: ContentLibraryProps): JSX.Element => {
  const styles = useStyles();
  const refElement = useScrollableContainer(null);
  const modulesLibraryMeta = modules.useLibraryMeta();
  const imageLibraryMeta = imageLibrary.useAllMeta();
  const DVMeta = dataVisualization.useDataVisualizationListMeta();

  const [loaderRowCount, setLoaderRowCount] = useState(1);

  useEffect(() => {
    const curElem = refElement.current;
    curElem?.addEventListener('scroll', (e) => {
      if (
        localStorage.getItem('isPageChangeCalled') !== 'true' &&
        curElem.offsetHeight + Math.round(curElem.scrollTop) + 1 >= curElem.scrollHeight &&
        curElem.offsetHeight !== curElem.scrollHeight
      ) {
        localStorage.setItem('isPageChangeCalled', 'true');
        setTimeout(() => {
          localStorage.setItem('isPageChangeCalled', 'false');
        }, 1000);
        onPageChange({} as any);
      }
    });
  }, [onPageChange, refElement]);

  useEffect(() => {
    if (refElement.current) {
      const containerHeight = refElement.current?.offsetHeight;
      const LOADER_HEIGHT_WITH_MARGIN = 255;
      setLoaderRowCount(() => Math.ceil(containerHeight / LOADER_HEIGHT_WITH_MARGIN));
    }
  }, [refElement]);

  const renderContentBody = useCallback(() => {
    if (isImageEditorVisible) {
      return (
        <ImageEditor
          chosenImage={chosenImage}
          query={query}
          setChosenImage={setChosenImage}
          setIsImageEditorVisible={setIsImageEditorVisible}
        />
      );
    } else if (isTemplateEditorVisible) {
      return <TemplateEditor module={curModule!} setIsTemplateEditorVisible={setIsTemplateEditorVisible} />;
    } else if (isDVEditorVisible) {
      return (
        <DVEditor
          chosenDV={chosenDV}
          query={query}
          setChosenDV={setChosenDV}
          setIsDVEditorVisible={setIsDVEditorVisible}
        />
      );
    } else {
      return (
        <>
          {modulesList?.length > 0 && (
            <Panel className={`${styles.panel} ${type === 'twoGrid' ? 'two-grid' : ''}`}>
              {(modulesList as any[])
                .filter((module) => module)
                .map((module: any) => {
                  const moduleType =
                    (Number.isInteger(module?.id) && module.deployment) ?? module.moduleTemplateName
                      ? 'instance'
                      : Number.isInteger(module?.deploymentsCount)
                      ? 'template'
                      : module.thumbnailPath
                      ? 'dataVisual'
                      : 'image';

                  return (
                    <ContentModuleCard
                      key={module.id}
                      id={module.id}
                      name={module.templateName ?? module.moduleTemplateName ? module.formattedName : module.name}
                      index={module.tag?.index ?? `${Math.round(module.width)}x${Math.round(module.height)}`}
                      deploymentName={module.deployment?.name ?? content.notInUse}
                      instances={module.instanceCount}
                      ctir={module.statistics?.ctir ? Math.round(module.statistics?.ctir * 10) / 10 : '--'}
                      coverImage={module.coverImage || module.path || module.thumbnailPath}
                      onClick={() => {}}
                      onDoubleClick={() => onTemplateView(module, moduleType)}
                      isActive={module.id === activeModuleId || chosenImage.imageSrc === module.path}
                      type={moduleType}
                    />
                  );
                })}
            </Panel>
          )}

          {(modulesLibraryMeta.status === 'loading' ||
            imageLibraryMeta.status === 'loading' ||
            DVMeta.status === 'loading') && (
            <Panel className={`${styles.panel} ${type === 'twoGrid' ? 'two-grid' : ''}`}>
              {[...Array(loaderRowCount)].map((_, index) => (
                <Fragment key={`loader-${index}`}>
                  <LoadingBlockPlaceholder />
                  <LoadingBlockPlaceholder />
                  <LoadingBlockPlaceholder />
                  <LoadingBlockPlaceholder />
                </Fragment>
              ))}
            </Panel>
          )}
        </>
      );
    }
  }, [
    DVMeta.status,
    activeModuleId,
    chosenDV,
    chosenImage,
    curModule,
    imageLibraryMeta.status,
    isDVEditorVisible,
    isImageEditorVisible,
    isTemplateEditorVisible,
    loaderRowCount,
    modulesLibraryMeta.status,
    modulesList,
    onTemplateView,
    query,
    setChosenDV,
    setChosenImage,
    setIsDVEditorVisible,
    setIsImageEditorVisible,
    setIsTemplateEditorVisible,
    styles.panel,
    type,
  ]);

  return (
    <Box {...{ ref: refElement }} className={styles.contentLibrary}>
      {renderContentBody()}
    </Box>
  );
};
