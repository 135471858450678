import { useCallback, useState, MouseEvent, SyntheticEvent } from 'react';
import { Box, ButtonBase, Typography, Menu as MuiMenu, MenuItem } from '@material-ui/core';

import { func, parse } from '@utils';
import { Icon } from '@components';
import { content } from '@content';
import { variables } from '@styles';
import { Enterprise } from '@modules';

import { useStyles } from './EnterpriseMenu.styles';
import { EnterpriseMenuProps } from './EnterpriseMenu.props';

/**
 * EnterpriseMenu component
 * @returns {JSX.Element}
 */
export const EnterpriseMenu = ({
  expanded = false,
  enterprises = [],
  enterpriseId = 0,
  businessUnitId = 0,
  onEnterpriseToggle = func.nop,
  onClientChange = func.nop,
}: EnterpriseMenuProps): JSX.Element => {
  const styles = useStyles();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [showClientsEl, setShowClientsEl] = useState<null | Record<string, boolean>>(null);

  const currentEnterprise = enterprises.filter((item) => item.id === enterpriseId)[0];
  const enterpriseName = currentEnterprise?.name;
  const businessUnitName = currentEnterprise?.businessUnits.find(
    (businessUnit) => businessUnit.id === businessUnitId,
  )?.name;

  const handleEnterpriseMenuClick = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
      onEnterpriseToggle(!!event.currentTarget);
    },
    [onEnterpriseToggle, setAnchorEl],
  );

  const handleEnterpriseMenuClose = useCallback(() => {
    setAnchorEl(null);
    setShowClientsEl(null);
    onEnterpriseToggle(false);
  }, [onEnterpriseToggle, setAnchorEl, setShowClientsEl]);

  const handleEnterpriseItemClick = (event: SyntheticEvent<HTMLLIElement>) => {
    const { dataset } = event.currentTarget;
    const { enterpriseId: nextEnterpriseId } = dataset;

    if (nextEnterpriseId) {
      setShowClientsEl({ [nextEnterpriseId]: true });
    }
  };

  const handleClientsItemClick = (event: SyntheticEvent<HTMLLIElement>) => {
    event.stopPropagation();
    const { dataset } = event.currentTarget;
    const { enterpriseId: nextEnterpriseId, businessunitId: nextBusinessUnitId } = dataset;

    if (nextEnterpriseId && nextBusinessUnitId) {
      onClientChange(parse.integer(nextEnterpriseId), parse.integer(nextBusinessUnitId));
      handleEnterpriseMenuClose();
    }
  };

  const BusinessUnitMenu = (item: Enterprise) => [
    <MenuItem
      key="businessUnit"
      disableGutters
      classes={{ root: styles.menuTitle, selected: styles.selected }}
      onClick={(event) => event.preventDefault()}
    >
      <Typography noWrap classes={{ root: styles.menuItemTitle }}>
        {content.businessUnit}
      </Typography>
    </MenuItem>,
    item.businessUnits.map((businessUnit) => (
      <MenuItem
        disableGutters
        key={businessUnit.id}
        classes={{ root: styles.enterpriseMenuItem, selected: styles.selected }}
        data-enterprise-id={item.id}
        data-business-unit-id={businessUnit.id}
        onClick={handleClientsItemClick}
        selected={businessUnit.id === businessUnitId}
      >
        <Typography noWrap classes={{ root: styles.enterpriseMenuItemText }}>
          {businessUnit.name}
        </Typography>
        <Box className={styles.businessUnitMenuItemIcon}>
          <Icon.Checkmark stroke={variables.color.primary.white} />
        </Box>
      </MenuItem>
    )),
  ];

  const EnterprisesSecondMenu = (item: Enterprise) => [
    <Box key="enterpriseMenuItemIcon" className={styles.enterpriseMenuItemIcon}>
      <Icon.ChevronRight />
    </Box>,
    <MuiMenu
      key="businessUnitsMenu"
      anchorEl={anchorEl}
      open={Boolean(showClientsEl && showClientsEl[item.id])}
      onClose={handleEnterpriseMenuClose}
      keepMounted
      data-enterprise-id={item.id}
      getContentAnchorEl={null}
      classes={{ paper: `${styles.enterpriseMenuPaper} ${styles.businessUnitsMenuPaper}` }}
      onClick={(event) => event.stopPropagation()}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
    >
      {BusinessUnitMenu(item)}
    </MuiMenu>,
  ];

  const EnterprisesFirstMenu = () => [
    <MenuItem
      key="enterpriseTitle"
      disableGutters
      classes={{ root: styles.menuTitle, selected: styles.selected }}
      onClick={(event) => event.preventDefault()}
    >
      <Typography noWrap classes={{ root: styles.menuItemTitle }}>
        {content.enterprise}
      </Typography>
    </MenuItem>,
    enterprises.map((item) => (
      <MenuItem
        disableGutters
        key={item.id}
        classes={{ root: styles.enterpriseMenuItem, selected: styles.selected }}
        data-enterprise-id={item.id}
        onClick={handleEnterpriseItemClick}
        selected={item.id === enterpriseId}
        disabled={item.businessUnits.length === 0}
      >
        <Typography noWrap classes={{ root: styles.enterpriseMenuItemText }}>
          {item.name}
        </Typography>
        {item.businessUnits.length > 0 && EnterprisesSecondMenu(item)}
      </MenuItem>
    )),
  ];

  return (
    <>
      {[
        <ButtonBase
          key="menuIcon"
          classes={{ root: `${styles.enterprise} ${expanded ? styles.expanded : ''}` }}
          onClick={handleEnterpriseMenuClick}
        >
          <Box className={styles.enterpriseIcon}>
            <Icon.BusinessOutline color="" />
          </Box>
          <Box className={styles.enterpriseText}>
            <Typography classes={{ root: styles.enterpriseLabel }}>{enterpriseName}</Typography>
            <Typography classes={{ root: styles.enterpriseName }}>{businessUnitName}</Typography>
          </Box>
          <Box className={styles.enterpriseArrow}>
            <Icon.ChevronDown stroke="" />
          </Box>
        </ButtonBase>,
        <MuiMenu
          key="enterpriseMenu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleEnterpriseMenuClose}
          getContentAnchorEl={null}
          classes={{ paper: `${styles.enterpriseMenuPaper} ${showClientsEl ? 'active' : ''}` }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          {EnterprisesFirstMenu()}
        </MuiMenu>,
      ]}
    </>
  );
};
