import { createStyles, makeStyles } from '@material-ui/core';

import { variables } from '@styles';

/**
 * TopBar component styles
 * @returns {Function}
 */

const smallViewWidth = '1250px';

export const useStyles = makeStyles((theme) =>
  createStyles({
    topBar: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'right', // 'space-between',
      padding: theme.spacing(0, 1.5),
      background: variables.color.primary.white,
      borderBottom: `${variables.borderWidth.md} solid ${variables.color.primary.lightGray}`,
      minHeight: '6.3rem',

      [`@media (max-width: ${smallViewWidth})`]: {
        flexWrap: 'wrap',
      },
    },

    sectionName: {
      fontSize: variables.font.size.xxl,
      fontWeight: variables.font.weight.extraBold,
      lineHeight: variables.lineHeight.xxxl,
      color: variables.color.primary.darkestGray,
    },

    crumbsWrapper: {
      display: 'flex',
      alignItems: 'center',
    },

    edit: {
      '&&': {
        flexDirection: 'row',
        alignItems: 'center',
      },
    },

    crumbs: {
      marginLeft: theme.spacing(2),

      '&:first-child': {
        marginLeft: 0,
      },
    },

    backBtn: {
      stroke: variables.color.primary.mediumGray,

      '&:hover': {
        stroke: variables.color.primary.darkestGray,
      },
    },

    crumbsLink: {
      display: 'block',
      textDecoration: 'none',
      color: variables.color.primary.mediumGray,

      '&:hover': {
        color: variables.color.primary.mainPurple,
      },
    },

    crumbsActive: {
      color: variables.color.primary.darkestGray,
    },

    crumbsLabel: {
      color: variables.color.primary.darkGray,
      fontSize: variables.font.size.xs,
      fontWeight: variables.font.weight.bold,
      lineHeight: variables.lineHeight.xs,
    },

    crumbsValue: {
      fontSize: variables.font.size.xs,
      fontWeight: variables.font.weight.extraBold,
      lineHeight: variables.lineHeight.xl,
    },

    editableCrumbsLabel: {
      fontSize: variables.font.size.md,
      fontWeight: variables.font.weight.semiBold,
      lineHeight: variables.lineHeight.lg,
    },

    last: {
      color: variables.color.primary.darkestGray,
      fontWeight: variables.font.weight.bold,
    },

    menu: {
      padding: theme.spacing(1, 0),
      width: '4.2rem',
    },

    actions: {
      display: 'flex',
      gap: '10px',
      justifyContent: 'right',
      // width: '100%',
    },

    pickerWrapper: {
      flex: '1 1 auto',
      marginLeft: theme.spacing(6),
      display: 'flex',

      [`@media (max-width: ${smallViewWidth})`]: {
        marginLeft: theme.spacing(2),
      },
    },

    experiencePicker: {
      maxWidth: '22rem',
    },

    businessUnitPicker: {
      maxWidth: '22rem',
    },

    picker: {
      padding: theme.spacing(1, 0),
      margin: theme.spacing(0, 1.5),
      maxWidth: `calc(25% - ${theme.spacing(1)}px)`,

      [`@media (max-width: ${smallViewWidth})`]: {
        margin: theme.spacing(0, 1),
      },
    },

    saveButton: {
      '&:disabled': {
        backgroundColor: variables.color.primary.backgroundLighterGray,
        border: `0.1rem solid ${variables.color.primary.lightGrayDarker}`,
        color: variables.color.primary.lightGrayDarker,
      },
    },
  }),
);
