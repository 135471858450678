import { Box } from '@material-ui/core';
import { Typography, Icon } from '@components';
import { variables } from '@styles';

import { useStyles } from './EmptyPlaceholder.styles';
import { EmptyPlaceholderProps } from './EmptyPlaceholder.types';

/**
 EmptyPlaceholder component.
 @returns {JSX.Element}
 */
export const EmptyPlaceholder = ({ className = '', title, description, icon }: EmptyPlaceholderProps): JSX.Element => {
  const styles = useStyles();

  return (
    <Box className={className}>
      {icon && icon}
      <Typography.Display className={styles.title}>{title}</Typography.Display>
      {description && <Typography.Caption>{description}</Typography.Caption>}
    </Box>
  );
};
