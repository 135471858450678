import { makeStyles, createStyles } from '@material-ui/core/styles';

import { variables } from '@styles';

/**
 * ContentCenterFilters component styles
 * @returns {Function}
 */

export const useStyles = makeStyles((theme) =>
  createStyles({
    libraryFilters: {
      padding: theme.spacing(3, 3, 1.5, 3),
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },

    tab: {
      fontSize: variables.font.size.md,
      fontWeight: variables.font.weight.bold,
      lineHeight: variables.lineHeight.lg,
      color: variables.color.primary.darkestGray,
      padding: theme.spacing(0.75, 2.8),
      textTransform: 'capitalize',
      border: `solid ${variables.borderWidth.sm} ${variables.color.primary.lightGray}`,
      borderRadius: variables.borderRadius.sm,
      width: '170px',

      '&[data-left-tab]': {
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
      },

      '&[data-right-tab]': {
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
      },

      '&.Mui-selected': {
        backgroundColor: variables.color.primary.mainPurple,
        color: variables.color.primary.white,
        borderColor: variables.color.primary.mainPurple,
      },

      '&.Mui-selected:hover': {
        background: `linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1)), ${variables.color.primary.mainPurple}`,
      },
    },

    rightElements: {
      display: 'flex',
      alignItems: 'center',
    },

    search: {
      height: '3.8rem',
      marginLeft: theme.spacing(1),
      width: '250px',
      '& input': {
        height: '3.6rem',
      },
    },

    uploadModule: {
      border: `1px solid ${variables.color.primary.lightGrayDarker}`,
      marginLeft: theme.spacing(1),
      minWidth: '3.8rem',
      padding: '0.8rem 0.8rem',
      width: '3.8rem',
      '&:hover': {
        backgroundColor: variables.color.primary.backgroundLighterGray,
        border: `1px solid ${variables.color.primary.mainPurple}`,
      },
      '&:hover svg path': {
        fill: variables.color.primary.mainPurple,
      },
    },

    menu: {
      marginLeft: theme.spacing(1),
      '& button': {
        border: `1px solid ${variables.color.primary.lightGrayDarker}`,
        minWidth: '3.8rem',
        padding: theme.spacing(1, 1),
        width: '3.8rem',

        '&:hover': {
          border: `1px solid ${variables.color.primary.mainPurple}`,
        },
        '&:hover svg path': {
          fill: variables.color.primary.mainPurple,
        },
      },
    },
  }),
);
