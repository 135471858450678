import { useStyles } from './LoadingBlockPlaceholder.styles';

function LoadingBlockPlaceholder(): JSX.Element {
  const styles = useStyles();

  return (
    <div className={styles.loader}>
      <div className={styles.loaderImage}>
        <span className={styles.loaderElement}></span>
      </div>
      <div className={styles.loaderData}>
        <span className={styles.loaderElement}></span>
        <span className={styles.loaderElement}></span>
      </div>
    </div>
  );
}

export default LoadingBlockPlaceholder;
