import { makeStyles, createStyles } from '@material-ui/core/styles';
import { theme, variables } from '@styles';

/**
 * TemplateList component styles
 * @returns {Function}
 */
export const useStyles = makeStyles(() =>
  createStyles({
    templateList: {
      height: '85vh',
      overflowY: 'scroll',
      backgroundColor: variables.color.primary.white,
      padding: theme.spacing(3),
      borderRight: `${variables.borderWidth.md} solid ${variables.color.primary.lightGray}`,
    },
    header: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginBottom: theme.spacing(2),
    },
    row: {
      display: 'flex',
      alignItems: 'center',
    },
    title: {
      marginRight: theme.spacing(1),
    },
    emptyContainer: {
      display: 'flex',
      flex: '1 1',
      justifyContent: 'center',
      alignItems: 'center',
      height: '70vh',
    },
    emptyPlaceholder: {
      width: '55%',
      textAlign: 'center',
    },
    selectTemplate: {
      display: 'flex',
      flex: '1 1',
      justifyContent: 'center',
      alignItems: 'center',
      // height: '20rem',
    },
    uploadButton: {
      border: `1px solid ${variables.color.primary.lightGrayDarker}`,
      minWidth: '3.8rem',
      padding: '0.8rem 0.8rem',
      width: '3.8rem',

      '&:hover': {
        border: `1px solid ${variables.color.primary.mainPurple}`,
      },
      '&:hover svg path': {
        fill: variables.color.primary.mainPurple,
      },
    },
  }),
);
