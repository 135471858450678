import { SVGProps } from 'react';

export const Mobile = ({ width = 24, height = 24, fill = '#ffffff', className }: SVGProps<SVGElement>): JSX.Element => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 1200 1200"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="m822.13403,2.8479l-444.26334,0c-84.41959,0 -152.8887,68.46432 -152.8887,152.8887l0,888.52682c0,84.41956 68.46426,152.88867 152.8887,152.88867l444.26334,0c40.5307,0 79.43719,-16.12231 108.09937,-44.78467s44.78461,-67.5686 44.78461,-108.09924l0,-888.52679c0,-84.4196 -68.46436,-152.88871 -152.88873,-152.88871l0.00476,-0.00478zm69.2478,1041.41553c0,38.23523 -31.01361,69.24768 -69.2478,69.24768l-444.26334,0c-38.23532,0 -69.24777,-31.01355 -69.24777,-69.24768l0,-888.52682c0,-38.23528 31.01361,-69.24773 69.24777,-69.24773l444.26334,0c38.23529,0 69.2478,31.01363 69.2478,69.24773l0,888.52682zm-194.03088,-888.52682c0,23.06352 -18.75342,41.8181 -41.81812,41.8181l-111.0658,0c-23.06354,0 -41.81805,-18.7534 -41.81805,-41.8181s18.75336,-41.81805 41.81805,-41.81805l111.0658,0c11.08423,0 21.72119,4.42245 29.55792,12.26021s12.26019,18.47397 12.26019,29.55785z"
      fill={fill}
    />
  </svg>
);
