import { useEffect } from 'react';
import { Box, Button } from '@material-ui/core';

import { content } from '@content';
import { useHistory } from '@utils';
import { useAppDispatch } from '@store';
import { template, deployment } from '@modules';
import { Typography, Icon, EmptyPlaceholder } from '@components';

import { TemplateItem } from './TemplateItem';

import { useStyles } from './TemplateList.styles';
import { TemplateListProps } from './TemplateList.props';
import { variables } from '@styles';

/**
 TemplateList component.
 @returns {JSX.Element}
 */
export const TemplateList = ({
  items,
  selected,
  showSelectTemplate = false,
  onSelect,
  onUpload,
}: TemplateListProps): JSX.Element => {
  const styles = useStyles();

  return (
    <Box className={styles.templateList}>
      <Box className={styles.header}>
        <Box className={styles.row}>
          <Typography.Title className={styles.title}>{content.templates}</Typography.Title>
          {/* <Typography.Caption>{items.length}</Typography.Caption> */}
        </Box>
        <Button className={styles.uploadButton} variant="outlined" color="primary" onClick={onUpload}>
          <Icon.Upload height="20" width="20" fill={variables.color.primary.darkestGray} />
        </Button>
      </Box>
      {items.length > 0 && (
        <>
          {showSelectTemplate && (
            <Box className={styles.selectTemplate}>
              <EmptyPlaceholder title={content.selectATemplate} className={styles.emptyPlaceholder} />
            </Box>
          )}

          {items.map((item) => (
            <TemplateItem key={item.id} item={item} selected={selected === item.id} onClick={onSelect} />
          ))}
        </>
      )}
      {items.length === 0 && showSelectTemplate && (
        <Box className={styles.emptyContainer}>
          <EmptyPlaceholder
            title={content.uploadATemplate}
            className={styles.emptyPlaceholder}
            icon={<Icon.Add fill={variables.color.primary.darkestGray} />}
          />
        </Box>
      )}
    </Box>
  );
};
