import { Box, Button, LinearProgress } from '@material-ui/core';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { Accordion, HtmlEditor, Icon, Loader, Select, TextField, Typography } from '@components';
import { content } from '@content';

import { dataVisualization } from '@modules';
import { ChangeDefaultImageModal, ChangeDefaultImageModalProps } from '@routes';
import { useAppDispatch } from '@store';
import { clipboard, format, useHistory } from '@utils';
import { convertEstTZ } from '@utils/format/format';
import { DVContainerProps, TGenerationHistory } from './DVContainer.props';
import { useStyles } from './DVContainer.styles';
import { variables } from '@styles';

/**
 DVContainer component.
 @returns {JSX.Element}
 */

export const DVContainer = ({
  chosenDV,
  uiType = 'primary',
  dirty = false,
  curDeployment,
  query,
  curSegment,
  setCurSegment,
  setChosenDV,
  setCurDeployment,
  setIsModuleChanged,
  onClose,
  onSave,
}: DVContainerProps) => {
  const styles = useStyles();
  const { width, height, name, id } = chosenDV;
  const dispatch = useAppDispatch();
  const [expanded, setExpanded] = useState<'config' | 'applet' | undefined>(undefined);
  const [code, setCode] = useState('');
  const { businessUnit } = useHistory().query;
  const [changeDefaultImageOpen, setChangeDefaultImageOpen] = useState(false);
  const DVDeployments = dataVisualization.useDataVisualizationDeploymentsData();
  const DVData = dataVisualization.useDVData();
  const segmentsSectionRef = useRef(null);
  const [isDeploymentVisible, setIsDeploymentVisible] = useState(true);
  const [segmentsSectionHeight, setSegmentsSectionHeight] = useState('');
  const chosenSegmentItem = useMemo(
    () =>
      Object.values(DVDeployments)
        .reduce<any>((accum, segments: any) => {
          return accum.concat(segments);
        }, [])
        .filter((segment: any) => segment.deployment.id === curDeployment)
        .find((segment: any) => segment.id === curSegment),
    [DVDeployments, curDeployment, curSegment],
  );

  const [DVSegmentItems, setDVSegmentItems] = useState<
    {
      id: number;
      name: string;
      dataVisualizationGenerationHistory: TGenerationHistory[];
      isImageGenerationRunning: boolean;
      imageTransferRunning: boolean;
      channelOpened: boolean;
      transferChannelOpened: boolean;
      handled: number;
      total: number;
      progress: number;
    }[]
  >([]);
  const deploymentOptions = Object.entries(DVDeployments).map(([key, value]) => ({
    id: (value as any)[0]?.deployment.id,
    label: key,
    value: (value as any)[0]?.deployment.id,
  }));

  const handleCopy = (value: string) => () => {
    clipboard.copy(value);
  };

  const handleSSEImagesTransferConnect = (segmentItems: any[]) => {
    segmentItems.forEach((segmentItem) => {
      if (!segmentItem.imageTransferRunning || segmentItem.transferChannelOpened) {
        return;
      }

      const imagesTransferchannel = new EventSource(`${process.env.REACT_APP_SSE_S3_TRANSFER_URL}${segmentItem.id}`);

      imagesTransferchannel.onmessage = (e: any) => {
        try {
          const data = JSON.parse(e.data);
          const deployment = deploymentOptions.find((option) => option.id === curDeployment);
          const parsedSegmentItems = JSON.parse(localStorage.getItem('DVSegmentItems')!);
          const segment = parsedSegmentItems.find((item: any) => item.id === segmentItem.id);

          if (segment) {
            segment.imageTransferRunning = !data;
            segment.transferChannelOpened = true;

            setTimeout(() => {
              dispatch(
                dataVisualization.actions.setDVIsImageTransferRunning({
                  deployment,
                  segment,
                  isImagetransferRunning: false,
                }),
              );
            }, 100);

            if (!segment.imageTransferRunning) {
              imagesTransferchannel.close();
              segment.transferChannelOpened = false;
            }
          }

          setDVSegmentItems(parsedSegmentItems);
        } catch (err) {
          console.log(err);
        }
      };
    });
  };

  const handleSSEImagesGenConnect = (segmentItems: any[]) => {
    segmentItems.forEach((segmentItem) => {
      if (!segmentItem.isImageGenerationRunning || segmentItem.channelOpened || segmentItem.transferChannelOpened) {
        return;
      }

      const imagesGenchannel = new EventSource(`${process.env.REACT_APP_SSE_DV_IMAGE_GENERATION_URL}${segmentItem.id}`);

      imagesGenchannel.onmessage = (e: any) => {
        try {
          const data = JSON.parse(e.data);
          const deployment = deploymentOptions.find((option) => option.id === curDeployment);
          const parsedSegmentItems = JSON.parse(localStorage.getItem('DVSegmentItems')!);
          const segment = parsedSegmentItems.find((item: any) => item.id === segmentItem.id);

          (async () => {
            if (segment) {
              segment.channelOpened = true;
              segment.total = data.dvImageGenerationProgress.total;
              segment.handled = data.dvImageGenerationProgress.handled;
              segment.progress = Math.round((segment.handled / segment.total) * 100);
              segment.isImageGenerationRunning = segment.total > segment.handled;

              if (!segment.isImageGenerationRunning) {
                segment.channelOpened = false;
                segment.imageTransferRunning = true;

                await dispatch(
                  dataVisualization.actions.setDVIsImageGenerationRunning({
                    deployment,
                    segment,
                    isImageGenRunning: false,
                  }),
                );
                await dispatch(
                  dataVisualization.actions.setDVIsImageTransferRunning({
                    deployment,
                    segment,
                    shouldShiftGenHistory: true,
                    isImagetransferRunning: true,
                  }),
                );
                handleSSEImagesTransferConnect(parsedSegmentItems);
                setTimeout(() => {
                  imagesGenchannel.close();
                }, 5000);
              }
            }
            setDVSegmentItems(parsedSegmentItems);
          })();
        } catch (err) {
          console.log(err);
        }
      };
    });
  };

  const handleGenerateImages = async () => {
    const deployment = deploymentOptions.find((option) => option.id === curDeployment);

    await dispatch(
      dataVisualization.thunk.generateDeploymentDataVisualizationImages({
        dataVisualization: id,
        deployment: deployment?.value ?? 0,
      }),
    );
    await dispatch(
      dataVisualization.actions.setDVIsImageGenerationRunning({
        deployment,
        segment: undefined,
        isImageGenRunning: true,
      }),
    );

    const segmentItems = [...DVSegmentItems].map((segmentItem) => ({
      ...segmentItem,
      isImageGenerationRunning: true,
      imageTransferRunning: false,
      total: segmentItem.total ?? 0,
      progress: 0,
      handled: 0,
    }));

    setDVSegmentItems(segmentItems);
    handleSSEImagesGenConnect(segmentItems);
  };

  const handleDeploymentChange = (e: any) => {
    setCurDeployment(e.target.value);
    setCurSegment(undefined);
    dispatch(dataVisualization.actions.resetDataVisualizationImages());
  };

  const toggleAccordion = useCallback(
    (item: 'config' | 'applet') => () =>
      setExpanded((prevState) => (!prevState ? item : prevState === 'applet' ? 'config' : 'applet')),
    [setExpanded],
  );

  const handleChangeDefaultImageOpen = useCallback(() => {
    setChangeDefaultImageOpen(true);
  }, [setChangeDefaultImageOpen]);

  const handleChangeDefaultImageClose = useCallback(() => {
    setChangeDefaultImageOpen(false);
  }, [setChangeDefaultImageOpen]);

  const handleChangeDefaultImageSubmit: ChangeDefaultImageModalProps['onSubmit'] = useCallback(
    async (values) => {
      const { defaultImagePath, thumbnailPath, ...rest } = chosenDV;
      const valuesWithClient = { ...rest, ...values, 'businessUnit[id]': businessUnit };
      const formData = new FormData();

      Object.keys(valuesWithClient).forEach((key) => {
        formData.append(key, valuesWithClient[key as keyof typeof valuesWithClient] as string | Blob);
      });

      const result = await dispatch(dataVisualization.thunk.update({ dataVisualization: chosenDV.id, body: formData }));

      if (dataVisualization.thunk.update.fulfilled.match(result)) {
        setChosenDV({ ...chosenDV, defaultImagePath: '' });
        setTimeout(() => {
          setChosenDV((prev) => ({
            ...prev,
            ...(result.payload as any),
          }));
        }, 100);
        setTimeout(() => {
          dispatch(
            dataVisualization.thunk.getListDataVisualization({
              ...query,
              businessUnit: query.businessUnitId,
            }),
          );
        }, 1000);
        handleChangeDefaultImageClose();
      }
    },
    [businessUnit, chosenDV, dispatch, handleChangeDefaultImageClose],
  );

  const handleDownloadJSCode = () => {
    const element = document.createElement('a');
    element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(DVData.data?.codeBlock ?? ''));
    element.setAttribute('download', `${DVData.data?.name ?? ''}.js`);

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  };

  const handleCodeChanged = (value: string) => {
    setCode(value);
    setIsModuleChanged(true);
  };

  useEffect(() => {
    dispatch(
      dataVisualization.thunk.getDataVisualizationDeployments({
        dataVisualization: id,
      }),
    );

    if (id) {
      dispatch(
        dataVisualization.thunk.getDataVisualization({
          dataVisualization: id,
        }),
      );
    }
  }, [dispatch, id]);

  useEffect(() => {
    setCurDeployment(0);
    setIsDeploymentVisible(false);
    setTimeout(() => setIsDeploymentVisible(true));
  }, [chosenDV, setCurDeployment, setIsDeploymentVisible]);

  useEffect(() => {
    setCode(DVData.data?.codeBlock ?? '');
  }, [DVData]);

  useEffect(() => {
    const segmentItems = Object.values(DVDeployments)
      .reduce<any>((accum, segments: any) => {
        return accum.concat(segments);
      }, [])
      .filter((segment: any) => segment.deployment.id === curDeployment)
      .map((segment: any) => ({
        id: segment.id,
        name: segment.name,
        isImageGenerationRunning: segment.isImageGenerationRunning,
        imageTransferRunning: segment.imageTransferRunning,
        channelOpened: segment.channelOpened,
        transferChannelOpened: segment.transferChannelOpened,
        dataVisualizationGenerationHistory: segment.dataVisualizationGenerationHistory,
        imageGenerationFinishedAt: segment.imageGenerationFinishedAt,
        progress: 0,
        handled: 0,
        total: segment.imagesGeneratedCount,
      }));

    handleSSEImagesGenConnect(segmentItems);
    handleSSEImagesTransferConnect(segmentItems);
    setDVSegmentItems(segmentItems);
  }, [DVDeployments, curDeployment, setDVSegmentItems]);

  useEffect(() => {
    localStorage.setItem('DVSegmentItems', JSON.stringify(DVSegmentItems));
  }, [DVSegmentItems]);

  useEffect(() => {
    setTimeout(() => {
      if (segmentsSectionRef.current) {
        setSegmentsSectionHeight(
          `calc(100vh - 445px - ${(segmentsSectionRef.current as any).getBoundingClientRect().height}px)`,
        );
      }
    }, 200);
  }, [DVSegmentItems, segmentsSectionRef, setSegmentsSectionHeight]);

  return (
    <Box className={styles.DVContainer} data-ui-type={uiType}>
      <Box className={styles.titleWrapper}>
        <Typography.Title className={styles.title}>
          {name}
          <Button className={styles.closeBtn} variant="outlined" color="primary" onClick={onClose}>
            {content.close}
          </Button>
        </Typography.Title>
      </Box>
      <Box className={styles.actions}>
        <Box className={styles.deployment}>
          {isDeploymentVisible && (
            <Select
              options={deploymentOptions}
              placeholder={content.selectDeployment}
              onChange={handleDeploymentChange}
              value={curDeployment}
            />
          )}
        </Box>
        <Button className={styles.generateBtn} variant="contained" color="primary" onClick={handleGenerateImages}>
          {content.generateImages}
        </Button>
      </Box>
      <div ref={segmentsSectionRef} className={styles.content}>
        <Box className={styles.generateImagesWrapper}>
          <Box>
            {!!curDeployment && (
              <Typography.Label className={styles.label}>{content.generationHistory}</Typography.Label>
            )}
            {DVSegmentItems.map((DVSegmentItem, index) => (
              <Box key={`dv-segment-item-${index}`} className={styles.DVSegmentItem}>
                <Box className={styles.DVSegmentItemName}>
                  <span className={styles.DVSegmentItemLabel}>Segment: </span>
                  <span>{DVSegmentItem.name}</span>
                </Box>
                {DVSegmentItem.dataVisualizationGenerationHistory.length
                  ? DVSegmentItem.dataVisualizationGenerationHistory.map(
                      (historyItem, i) =>
                        !!historyItem.imageGenerationFinishedAt && (
                          <Box key={`gen-history-${i}`}>
                            {`${convertEstTZ((historyItem.imageGenerationFinishedAt as string) ?? '')} (${format.number(
                              historyItem.generatedImagesCount,
                            )})`}
                          </Box>
                        ),
                    )
                  : '-'}
                {(DVSegmentItem.isImageGenerationRunning || DVSegmentItem.imageTransferRunning) && (
                  <>
                    <Box className={styles.progressItem}>
                      {!DVSegmentItem.isImageGenerationRunning ? (
                        <>
                          <Box className={styles.infoItemLabel}>Generation status:</Box>
                          <Box>
                            {format.number(
                              DVSegmentItem.dataVisualizationGenerationHistory[0]?.generatedImagesCount ??
                                DVSegmentItem.total,
                              '0',
                            )}{' '}
                            Images created
                          </Box>
                        </>
                      ) : (
                        <>
                          <Box className={styles.genStatusLabel}>
                            Generation in Progress:
                            <Box className={styles.genResult}>
                              {format.number(DVSegmentItem.handled, '0')} of {format.number(DVSegmentItem.total, '0')}
                            </Box>
                          </Box>
                          <LinearProgress
                            className={styles.imageProgress}
                            variant="determinate"
                            value={DVSegmentItem.progress}
                          ></LinearProgress>
                        </>
                      )}
                    </Box>
                    {!DVSegmentItem.imageTransferRunning ? (
                      <Box className={styles.infoItem}>
                        <Box className={styles.infoItemLabel}>Image transfer:</Box>
                        <Box className={styles.grayText}>Waiting for Generation to Complete</Box>
                      </Box>
                    ) : (
                      <Box className={styles.infoItem}>
                        <Box className={styles.infoItemLabel}>Image transfer in progress:</Box>
                        <Box className={styles.loading}>
                          <Loader width={40} height={40} />
                        </Box>
                      </Box>
                    )}
                  </>
                )}
              </Box>
            ))}
          </Box>
          {/* <Box>
            <Typography.Label className={styles.label}>
              {content.numberOfDVCreated}
              <Box className={styles.itemValue}>{format.number(generatedImagesNum, '0')}</Box>
            </Typography.Label>
          </Box> */}
        </Box>
      </div>
      <Accordion
        expanded={expanded === 'config'}
        onChange={toggleAccordion('config')}
        title={content.visualizationConfig}
        uiType="quaternary"
      >
        <Box className={styles.uploadDefaultImage}>
          <Button
            variant="outlined"
            color="primary"
            onClick={handleChangeDefaultImageOpen}
            startIcon={<Icon.Upload fill={variables.color.primary.darkestGray} height="16" width="16" />}
          >
            {content.defaultImage}
          </Button>
          <Box>
            ({width}x{height})
          </Box>
        </Box>
        <Box className={styles.featureSection} style={{ paddingBottom: 0 }}>
          <Box className={styles.featureTop}>
            <Typography.Caption className={styles.featureCaption}>{content.DVImagePath}</Typography.Caption>
            <Button
              className={styles.selectBtn}
              variant="outlined"
              color="primary"
              disabled={!chosenSegmentItem?.dataVisualizationImagesPath}
              onClick={handleCopy(chosenSegmentItem?.dataVisualizationImagesPath)}
            >
              {content.copy}
            </Button>
          </Box>
          <TextField
            className={styles.featureInput}
            readonly={true}
            placeholder={content.selectDeploymentAndSegment}
            value={chosenSegmentItem?.dataVisualizationImagesPath}
            size="small"
            direction="vertical"
          />
        </Box>
      </Accordion>
      <Accordion
        expanded={expanded === 'applet'}
        onChange={toggleAccordion('applet')}
        title={content.visualizationApplet}
        uiType="quaternary"
      >
        <Box className={styles.sourceCodeSection}>
          <Button
            variant="outlined"
            color="primary"
            onClick={handleDownloadJSCode}
            startIcon={<Icon.Upload fill={variables.color.primary.darkestGray} height="16" width="16" />}
          >
            {content.downloadCode}
          </Button>
        </Box>
        <HtmlEditor
          className={styles.codeEditor}
          value={code}
          onUpdate={handleCodeChanged}
          language="js"
          style={{ maxHeight: segmentsSectionHeight }}
        />
        <Button
          className={styles.save}
          variant="contained"
          color="primary"
          size="medium"
          disabled={!dirty}
          onClick={dirty && onSave ? onSave(code) : undefined}
        >
          {content.saveChanges}
        </Button>
      </Accordion>
      {changeDefaultImageOpen && (
        <ChangeDefaultImageModal onSubmit={handleChangeDefaultImageSubmit} onCancel={handleChangeDefaultImageClose} />
      )}
    </Box>
  );
};
