import { Box } from '@material-ui/core';
import { useCallback, useContext, useEffect, useState } from 'react';

import { Accordion, TextField, Typography, Wysiwyg } from '@components';
import { content } from '@content';

import { ModulesInfoData, SignalsSearchItem, attributeLibrary, modules, signalLibrary } from '@modules';
import { TreatmentBuilderContext } from '@routes';
import { useAppDispatch } from '@store';
import { EditMode } from '../EditMode';
import { DVAttributeDropdown } from './DVAttributeDropdown';
import { DVItem } from './DVItem';
import { DVPanelProps } from './DVPanel.props';
import { useStyles } from './DVPanel.styles';
import { TDVItem } from './types';
import { searchQuery } from '@utils';

/**
 DVPanel component.
 @returns {JSX.Element}
 */

export const DVPanel = ({
  chosenDV,
  uiType = 'primary',
  isModuleChanged,
  dirty = false,
  setIsModuleChanged,
  setChosenLibrary,
  onImageChange,
  onDimensionsChange,
}: DVPanelProps) => {
  const dispatch = useAppDispatch();
  const styles = useStyles();
  const [expanded, setExpanded] = useState<boolean>(true);
  const { items: attributes } = attributeLibrary.useListData();
  const attributesMeta = attributeLibrary.useListMeta();
  const filteredAttributes = attributes.filter((attribute) => ['textual', 'numeric'].includes(attribute.type));
  const { chosenModuleId, DVItems, setDVItems, moduleDataVisualizationStation, setModuleDataVisualizationStation } =
    useContext(TreatmentBuilderContext);
  const [maxScale, setMaxScale] = useState<number>(
    moduleDataVisualizationStation && moduleDataVisualizationStation[chosenModuleId!]
      ? Number(moduleDataVisualizationStation[chosenModuleId!]?.maxScale)
      : 0,
  );
  const moduleInfoMeta = modules.useModuleInfoMeta();
  const [businessUnit] = searchQuery.useMutualParams('businessUnit');

  const toggleAccordion = useCallback(() => setExpanded((prevState) => !prevState), [setExpanded]);

  const handleColorChange = (index: number) => (color: string) => {
    const items = [...DVItems];
    const curDVItems = [...DVItems];
    const curModuleDataVisualizationStation = {
      ...moduleDataVisualizationStation,
    };

    curDVItems[index].color = color;

    if (chosenModuleId && curModuleDataVisualizationStation[chosenModuleId]) {
      curModuleDataVisualizationStation[chosenModuleId]!.settings = curDVItems.map((item) => ({
        id: item.id,
        label: item.label,
        color: item.color,
        signal: { id: filteredAttributes.find((attr) => attr.name === item.attribute)?.id ?? 0 },
      }));
    }

    setModuleDataVisualizationStation(curModuleDataVisualizationStation);
    setDVItems(items);
    setIsModuleChanged(true);
  };

  const handleLabelChange = (index: number) => (label: string) => {
    const curDVItems = [...DVItems];
    const curModuleDataVisualizationStation = {
      ...moduleDataVisualizationStation,
    };

    curDVItems[index].label = label;

    if (chosenModuleId && curModuleDataVisualizationStation[chosenModuleId]) {
      curModuleDataVisualizationStation[chosenModuleId]!.settings = curDVItems.map((item) => ({
        id: item.id,
        label: item.label,
        color: item.color,
        signal: { id: filteredAttributes.find((attr) => attr.name === item.attribute)?.id ?? 0 },
      }));
    }

    setModuleDataVisualizationStation(curModuleDataVisualizationStation);
    setDVItems(curDVItems);
    setIsModuleChanged(true);
  };

  const handleDVItemAdd = (values: TDVItem) => {
    const curDVItems = [...DVItems, values];
    const curModuleDataVisualizationStation = {
      ...moduleDataVisualizationStation,
    };

    if (chosenModuleId && curModuleDataVisualizationStation[chosenModuleId]) {
      curModuleDataVisualizationStation[chosenModuleId]!.settings = curDVItems.map((item) => ({
        id: item.id,
        label: item.label,
        color: item.color,
        signal: { id: filteredAttributes.find((attr) => attr.name === item.attribute)?.id ?? 0 },
      }));
    }

    setModuleDataVisualizationStation(curModuleDataVisualizationStation);
    setDVItems(curDVItems);
    setIsModuleChanged(true);
  };

  const handleDVItemRemove = (index: number | undefined) => () => {
    const curDVItems = [...DVItems].filter((item, position) => index !== position);
    const curModuleDataVisualizationStation = {
      ...moduleDataVisualizationStation,
    };

    if (chosenModuleId && curModuleDataVisualizationStation[chosenModuleId]) {
      curModuleDataVisualizationStation[chosenModuleId]!.settings = curDVItems.map((item) => ({
        id: item.id,
        label: item.label,
        color: item.color,
        signal: { id: filteredAttributes.find((attr) => attr.name === item.attribute)?.id ?? 0 },
      }));
    }

    setModuleDataVisualizationStation(curModuleDataVisualizationStation);
    setDVItems(curDVItems);
    setIsModuleChanged(true);
  };

  const handleMaxScaleChange = (value: string) => {
    const curModuleDataVisualizationStation = {
      ...moduleDataVisualizationStation,
    };

    if (chosenModuleId && curModuleDataVisualizationStation[chosenModuleId]) {
      curModuleDataVisualizationStation[chosenModuleId]!.maxScale = Number(value);
    }

    setModuleDataVisualizationStation(curModuleDataVisualizationStation);
    setIsModuleChanged(true);
    setMaxScale(Number(value));
  };

  const handleAttributeChange = (item: SignalsSearchItem) => {
    handleDVItemAdd({ color: '', attribute: item.name, label: item.label });
  };

  useEffect(() => {
    dispatch(modules.actions.resetModuleInfo());
    dispatch(attributeLibrary.thunk.search({ keyword: '', businessUnit }));
  }, [businessUnit]);

  useEffect(() => {
    (async () => {
      if (
        !(
          moduleDataVisualizationStation &&
          moduleDataVisualizationStation[chosenModuleId!] &&
          moduleDataVisualizationStation[chosenModuleId!]?.settings
        ) &&
        moduleInfoMeta.status === 'idle' &&
        attributesMeta.status === 'success'
      ) {
        const curModuleResp = await dispatch(modules.thunk.getModuleInfo({ module: chosenModuleId! }));
        const curModule = curModuleResp.payload as unknown as ModulesInfoData;

        const settings =
          curModule?.moduleDataVisualizationStation?.settings.map((item) => ({
            id: item.id,
            color: item.color,
            label: item.label,
            signal: { id: filteredAttributes.find((attr) => attr.id == item.signal.id)?.id ?? 0 },
          })) ?? [];

        setModuleDataVisualizationStation((prev) => ({
          ...prev,
          [chosenModuleId!]: {
            id: curModule?.moduleDataVisualizationStation?.id,
            embedCode: chosenDV?.embedCode,
            maxScale: curModule?.moduleDataVisualizationStation?.maxScale,
            settings,
            dataVisualization: {
              id: chosenDV?.id,
            },
          },
        }));
        setMaxScale(curModule?.moduleDataVisualizationStation?.maxScale ?? 0);
        setDVItems(
          curModule?.moduleDataVisualizationStation?.settings.map((item) => ({
            id: item.id,
            color: item.color,
            label: item.label,
            attribute: filteredAttributes.find((attr) => attr.id == item.signal.id)?.name ?? item.label,
          })) ?? [],
        );
      } else if (moduleDataVisualizationStation[chosenModuleId!]?.dataVisualization.id !== chosenDV?.id) {
        setMaxScale(moduleDataVisualizationStation[chosenModuleId!]?.maxScale ?? 0);
        setModuleDataVisualizationStation((prev) => ({
          ...prev,
          [chosenModuleId!]: {
            ...prev[chosenModuleId!],
            embedCode: chosenDV?.embedCode,
            dataVisualization: {
              id: chosenDV?.id,
            },
          } as any,
        }));
      }
    })();
  }, [
    chosenModuleId,
    moduleDataVisualizationStation,
    filteredAttributes,
    chosenDV,
    setModuleDataVisualizationStation,
    moduleInfoMeta,
    attributesMeta,
  ]);

  return (
    <Box className={styles.imageContainer} data-ui-type={uiType} style={{ paddingTop: '0' }}>
      <Box className={styles.titleWrapper}>
        <Typography.Title className={styles.title} style={{ padding: '0' }}>
          {content.dataVisualization}
        </Typography.Title>
        <EditMode />
      </Box>
      <Accordion expanded={expanded} onChange={toggleAccordion} title={content.settings} uiType="quaternary">
        <Wysiwyg.ImageContainer
          isImageEditor={true}
          isDVEditor={true}
          isModuleChanged={isModuleChanged}
          setIsModuleChanged={setIsModuleChanged}
          setChosenLibrary={setChosenLibrary}
          chosenImage={(chosenDV ?? {}) as any}
          onChange={onImageChange}
          onDimensionsChange={onDimensionsChange}
        />
        {chosenDV?.isMaxScaleEnabled && (
          <Box className={styles.maxScale}>
            <Typography.Caption className={styles.caption}>{content.maxScale}</Typography.Caption>
            <TextField
              size="small"
              direction="vertical"
              placeholder={content.maxScale}
              value={maxScale.toString()}
              minValue={'0'}
              type={'number'}
              onChange={handleMaxScaleChange}
            />
          </Box>
        )}
        <DVAttributeDropdown onChange={handleAttributeChange} />
        <Box className={styles.header}>
          <Typography.Caption className={styles.headerItem}>{content.color}</Typography.Caption>
          <Typography.Caption className={styles.headerItem}>{content.attribute}</Typography.Caption>
          {/* <Typography.Caption className={styles.headerItem}>{content.label}</Typography.Caption> */}
        </Box>
        {DVItems.map((item, index) => (
          <DVItem
            key={`DV-item-${index}`}
            index={index}
            color={item.color}
            label={item.label}
            attribute={item.attribute}
            onColorChange={handleColorChange(index)}
            onLabelChange={handleLabelChange(index)}
            onRemove={handleDVItemRemove}
          />
        ))}
      </Accordion>
    </Box>
  );
};
