import { makeStyles, createStyles } from '@material-ui/core/styles';

import { variables } from '@styles';

/**
 * ModuleModal component styles
 * @returns {Function}
 */

export const useStyles = makeStyles(() =>
  createStyles({
    imageModalWrapper: {
      display: 'flex',
      justifyContent: 'center',
      width: '100%',
      position: 'absolute',
      backgroundColor: variables.color.primary.backgroundLighterGray,
      height: 'calc(150vh + 24.2rem)',
      top: 0,
      zIndex: 1,
      padding: '25rem 0 0',
    },

    moduleContent: {
      position: 'relative',
    },

    imageWrapper: {
      display: 'flex',
      width: '100%',
      border: `3px solid ${variables.color.primary.mainPurple}`,
      borderRadius: '3px',
    },

    closeBtn: {
      padding: '3px 5px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'absolute',
      background: variables.color.primary.mainPurple,
      top: '0rem',
      right: '0rem',
      cursor: 'pointer',
      borderRadius: '0 2px 2px 0',

      '&:hover': {
        background: variables.color.primary.mainPurple,
      },
    },

    removeBtn: {
      padding: '3px 5px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'absolute',
      background: variables.color.primary.mainPurple,
      top: '3rem',
      right: '0rem',
      cursor: 'pointer',
      borderRadius: '0 2px 2px 0',

      '&:hover': {
        background: variables.color.primary.mainPurple,
      },
    },
  }),
);
