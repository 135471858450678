import { Icon, TextField, Typography } from '@components';
import { content } from '@content';
import '@glidejs/glide/dist/css/glide.core.min.css';
import { Box, IconButton, debounce } from '@material-ui/core';
import { DataVisualizationListItemSchema, dataVisualization } from '@modules';
import { RUN_SEARCH_DELAY } from '@routes/TemplateView/LibrariesPanel/ImageLibraryPanel/ImageLibraryPanel.const';
import { SearchFormValues } from '@routes/TemplateView/LibrariesPanel/ImageLibraryPanel/ImageLibraryPanel.types';
import { useAppDispatch } from '@store';
import { useHistory, useLoader } from '@utils';
import { useCallback, useEffect, useMemo, useState, useRef } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { DataVisualizationLibraryProps } from './DataVisualizationLibrary.props';
import { useStyles } from './DataVisualizationLibrary.styles';
import { UploadVisualizationModal, UploadVisualizationModalProps } from '@routes';
import { roundByRank } from '@utils/format/format';
import { variables } from '@styles';
import LoadingBlockPlaceholder from '@components/LoadingBlockPlaceholder/LoadingBlockPlaceholder';

export const DataVisualizationLibrary = ({
  chosenDV,
  isDVLibrary = false,
  onDVSelect,
  setChosenDV,
}: DataVisualizationLibraryProps): JSX.Element => {
  const styles = useStyles();
  const dispatch = useAppDispatch();

  const contentContainer = useRef<null | HTMLDivElement>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [loaderRowCount, setLoaderRowCount] = useState(1);

  const { businessUnit } = useHistory().query;
  const [DVItems, setDVItems] = useState<DataVisualizationListItemSchema[]>([]);
  const [chosenDVId, setChosenDVId] = useState<number>();
  const { control, setValue } = useForm<SearchFormValues>({
    defaultValues: { keyword: '' },
  });
  const [uploadVisualOpen, setUploadVisualOpen] = useState(false);
  const DVListData = dataVisualization.useDataVisualizationListData();

  useLoader(dataVisualization.useDataVisualizationListMeta(), dataVisualization.useEntityMeta());

  const getDVs = useCallback(
    (keyword: string) => {
      if (businessUnit) {
        setValue('keyword', keyword);
        setIsLoading(true);

        return dispatch(dataVisualization.thunk.getListDataVisualization({ keyword, businessUnit }));
      }
    },
    [dispatch, setValue, businessUnit],
  );

  const runSearch = debounce(getDVs, RUN_SEARCH_DELAY);

  const handleKeywordChange = useCallback(
    (keyword: string) => {
      dispatch(dataVisualization.actions.resetDataVisualizationList());
      setValue('keyword', keyword);

      runSearch(keyword);
    },
    [setValue, runSearch],
  );

  const handleUploadDVModalOpen = useCallback(() => {
    setUploadVisualOpen(true);
  }, [setUploadVisualOpen]);

  const handleChooseDV = (item: DataVisualizationListItemSchema) => () => {
    setChosenDVId(item.id);
    const imageRatio = roundByRank(Number(item.width) / Number(item.height), 10000);
    const width = item.width > 300 ? 300 : item.width;
    const curItem = { ...item, width, height: roundByRank(Number(width) / imageRatio, 1) };

    setChosenDV(curItem);
    onDVSelect(curItem);
  };

  useEffect(() => {
    const curDV = DVListData.items?.find((dv) => dv.id === chosenDV?.id);

    setChosenDVId(curDV?.id ?? 0);
  }, [chosenDV, DVListData.items, setChosenDVId]);

  useEffect(() => {
    setDVItems([]);
    getDVs('');

    return () => {
      setDVItems([]);
    };
  }, [dispatch, getDVs, setDVItems]);

  useEffect(() => {
    setDVItems([]);
  }, [DVListData.items]);

  useEffect(() => {
    const DVs = JSON.parse(JSON.stringify(DVListData.items ?? [])) as DataVisualizationListItemSchema[];

    if (DVListData.items) {
      setIsLoading(false);
    }

    DVs.forEach((dataVisual) => {
      if (dataVisual.thumbnailPath) {
        dataVisual.thumbnailPath = `${dataVisual.thumbnailPath}?${new Date().getTime()}`;

        const image = new Image();

        image.onload = () => {
          dataVisual.width = image.width;
          dataVisual.height = image.height;

          setDVItems((prev) => [...prev, dataVisual]);
        };
        image.onerror = () => {
          setDVItems((prev) => [...prev, dataVisual]);
        };
        image.src = dataVisual.thumbnailPath;
      }
    });
  }, [DVListData.items]);

  const handleUploadVisualModalClose = useCallback(() => {
    setUploadVisualOpen(false);
  }, [setUploadVisualOpen]);

  const handleFormVisualSubmit: UploadVisualizationModalProps['onSubmit'] = useCallback(
    async (values) => {
      const valuesWithClient = { ...values, 'businessUnit[id]': businessUnit };
      valuesWithClient.isMaxScaleEnabled = (valuesWithClient.isMaxScaleEnabled ? 1 : 0) as any;

      const formData = new FormData();

      Object.keys(valuesWithClient).forEach((key) => {
        formData.append(key, valuesWithClient[key as keyof typeof valuesWithClient] as string | Blob);
      });

      const result = await dispatch(dataVisualization.thunk.create(formData));

      if (dataVisualization.thunk.create.fulfilled.match(result)) {
        setTimeout(() => {
          dispatch(
            dataVisualization.thunk.getListDataVisualization({
              keyword: '',
              businessUnit: businessUnit!,
            }),
          );
        }, 100);
        handleUploadVisualModalClose();
      }
    },
    [businessUnit, dispatch, handleUploadVisualModalClose],
  );

  useEffect(() => {
    if (contentContainer.current) {
      const containerHeight = contentContainer.current?.offsetHeight;
      const LOADER_HEIGHT_WITH_MARGIN = 255;
      setLoaderRowCount(() => Math.ceil(containerHeight / LOADER_HEIGHT_WITH_MARGIN));
    }
  }, [contentContainer]);

  return (
    <Box className={styles.DVLibraryPanel} {...{ ref: contentContainer }}>
      {!isDVLibrary && (
        <Box className={styles.header}>
          <Controller
            name="keyword"
            control={control}
            render={({ field: { ref, ...field } }) => (
              <TextField
                {...field}
                className={styles.search}
                size="small"
                inputRef={ref}
                placeholder={content.search}
                onChange={handleKeywordChange}
                icon="SearchOutline"
              />
            )}
          />
          <IconButton className={styles.uploadBtn} onClick={handleUploadDVModalOpen}>
            <Icon.Upload fill={variables.color.primary.darkestGray} height="20" width="20" />
          </IconButton>
          {uploadVisualOpen && (
            <UploadVisualizationModal onSubmit={handleFormVisualSubmit} onCancel={handleUploadVisualModalClose} />
          )}
        </Box>
      )}
      <Box className={styles.content}>
        {isLoading &&
          DVItems.length === 0 &&
          [...Array(loaderRowCount)].map((_: number, index: number) => (
            <Box key={`loaderRow-${index}`} className={styles.loaderBlock}>
              <LoadingBlockPlaceholder />
            </Box>
          ))}
        {DVItems.map((item, index) => (
          <Box key={`${item.name} ${index}`} data-chosen={chosenDVId! === item.id} className={styles.imageWrapper}>
            <div
              id={String(item.id)}
              className={styles.image}
              onClick={handleChooseDV(item)}
              style={{ backgroundImage: `url(${item.thumbnailPath})` }}
            />
            <Box className={styles.imageContent}>
              <Box className={styles.moduleRow}>
                <Typography.Headline className={styles.name}>{item.name}</Typography.Headline>
              </Box>
              <Box className={styles.moduleRow}>
                <Typography.SmallCaption className={styles.index}>
                  {`${Math.round(item.width)}x${Math.round(item.height)}`}
                </Typography.SmallCaption>
              </Box>
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  );
};
